/* eslint-disable react/require-default-props */
import React from 'react'
import { Box, Tooltip, Typography, Skeleton } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { PorfolioEfficiency } from 'src/graphql/models/Portfolio'
import { LineChart } from 'src/components/Charts'
import { dynamicColors } from 'src/utils/common'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { Colors, Icon } from 'everchain-uilibrary'

interface PortfolioEfficiencyGraphicProps {
  dataChat?: PorfolioEfficiency[]
  loading: boolean
  onRequestCategoryChange: (selectedCategories: string[]) => void
  requestCategorySelected: string[]
  efficiencyScore?: number
}

const PortfolioEfficiencyGraphicComponent = ({
  dataChat,
  loading,
  onRequestCategoryChange,
  requestCategorySelected,
  efficiencyScore,
}: PortfolioEfficiencyGraphicProps) => {
  const portfolioEfficientyDataReduce = (data: PorfolioEfficiency[] = []) => {
    const defaultData: any = {
      labels: [],
      datasets: [],
    }

    if (data && data.length) {
      return data.reduce((acc: any, curr: any, index) => {
        const datasets = [...acc.datasets]
        const labels = [...acc.labels]

        curr.data.forEach((item: any) => {
          if (!labels.includes(item.key.split(';')[0])) {
            labels.push(item.key.split(';')[0])
          }
        })

        datasets[index] = {
          ...datasets[index],
          data: curr.data.map((item: any) => {
            return item.value
          }),
          label: curr.title,
          fill: false,
          borderColor: getColorBorder(curr.title),
          lineTension: 0.1,
          trendlineLinear: {
            lineStyle: 'dotted',
            width: 2,
          },
        }
        return { labels, datasets }
      }, defaultData)
    }
    return defaultData
  }

  const getColorBorder = (item: any) => {
    if (item === 'All (Info, Buyback, Direct-Pay)') return Colors.blue
    if (item === 'Buyback') {
      return Colors.error
    }
    if (item === 'Direct-Pay') return Colors.primary

    if (item === 'Info') return Colors.success
    return dynamicColors({
      min: 0,
      max: 245,
      opacity: 0.8,
    })
  }
  const lineOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems: any[]) => {
            const xAxisLabel = tooltipItems[0]?.label?.split(';')[0]
            return xAxisLabel ? `${xAxisLabel}` : ''
          },
          label: (tooltipItem: any) => {
            // @ts-ignore
            const label = tooltipItem?.formattedValue || ''
            return `${parseFloat(label).toFixed(2)}`
          },
        },
      },
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    scales: {
      xAxes2: {
        type: 'category',
        grid: {
          drawOnChartArea: false,
          display: false,
          drawBorder: false,
        },

        title: {
          display: true,
          text: 'PID in Chronological Order',
        },
      },
      yAxes: {
        beginAtZero: true,
        min: 0,
        ticks: {
          callback(value: any) {
            return `${value}%`
          },
        },
        title: {
          display: true,
          text: 'Percentage Requests',
        },
      },
    },
  }

  if (loading) {
    return (
      <Box>
        <Box p={2}>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Box>
      </Box>
    )
  }
  const renderTypeRequestSelect = () => {
    const requestCategorySelect = ['All', 'Direct-Pay', 'Buyback', 'Info']

    const handleChangeRequestCategory = (selectedCategories: string[]) => {
      onRequestCategoryChange(selectedCategories)
    }
    return (
      <MultipleSelectionDropDown
        label="Request Category"
        disable={false}
        data={requestCategorySelect}
        handleSelectionChange={handleChangeRequestCategory}
        selectionState={requestCategorySelected}
      />
    )
  }
  return (
    <Box>
      <Box p={3}>
        <Typography>
          Post-Sale Efficiency
          <Tooltip
            placement="bottom"
            title="This metric measures the number of post-sale requests generated per portfolio. A lower number indicates fewer requests, which signifies a smoother and more efficient partnership"
          >
            <Icon
              name="InfoOutlined"
              style={{ fontSize: 'medium' }}
              color="black"
            />
          </Tooltip>
        </Typography>
      </Box>
      {/* <Box p={1}>
        <DTSummaryGridContent>
          <DTSummaryBlocks>
            <DTSummaryBlockItem>
              <DTSummaryBlockItemValue>
                {efficiencyScore}
              </DTSummaryBlockItemValue>
              <DTSummaryBlockItemHeader>
                Efficiency Score
              </DTSummaryBlockItemHeader>
            </DTSummaryBlockItem>
          </DTSummaryBlocks>
        </DTSummaryGridContent>
      </Box> */}
      <Box p={3}>{renderTypeRequestSelect()}</Box>

      <Box height={400} position="relative" width="100%" component="div" p={3}>
        <LineChart
          data={portfolioEfficientyDataReduce(dataChat)}
          options={lineOptions}
        />
      </Box>
    </Box>
  )
}

export default PortfolioEfficiencyGraphicComponent
