import React, { useContext } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  Grid,
  Tooltip,
  Skeleton,
} from '@mui/material'
import { PortfolioPurchaseInformation } from 'src/graphql/models/Portfolio'
import DTAccordion from 'src/components/Accordion'
import { PortfolioStatusType, PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { TableCellFundingCustom, PoundCircleFilledStyled } from '../styles'
import BillOfSale from './BillOfSale'
import { Colors, Icon, textPrimary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioPurchaseInfo } from 'src/data/features/get/portfolio/portfolio'

interface FundingInformationProps {
  portfolioId: string | undefined
  portfolioStatus: string
  buyerName: string | undefined
  sellerName: string | undefined
  isPortfolioSeller: Boolean
  isPortfolioBuyer: Boolean
  hasBOS: boolean
  hasFinalBOS: boolean
  portfolioHasBOSReleased: boolean
  portfolioHasBOSConfirmed: boolean
  isInternal?: boolean
  portfolioCountry: string | undefined
  eSignatureEnabledBOS: boolean
  isBosESignatureSent: boolean
  bosSignerName: string | undefined
  bosSignerTitle: string | undefined
  bosSignerEmail: string | undefined
  sellerUploadTemplateId: number | undefined
}

const FundingInformation: React.FC<FundingInformationProps> = ({
  portfolioId,
  portfolioStatus,
  isPortfolioSeller,
  isPortfolioBuyer,
  buyerName,
  sellerName,
  hasBOS,
  hasFinalBOS,
  portfolioHasBOSReleased,
  portfolioHasBOSConfirmed,
  isInternal,
  portfolioCountry,
  eSignatureEnabledBOS,
  isBosESignatureSent,
  bosSignerName,
  bosSignerTitle,
  bosSignerEmail,
  sellerUploadTemplateId,
}: FundingInformationProps) => {
  const { data: purchaseInfo, isFetching: loadingPortfolioPurchaseInfo } =
    useCustomQuery<PortfolioPurchaseInformation>(
      ['getPortfolioPurchaseInfo', portfolioId],
      async () => getPortfolioPurchaseInfo(portfolioId),
      { enabled: portfolioId != null, cacheTime: 0 }
    )

  // eslint-disable-next-line no-nested-ternary
  const title = isPortfolioBuyer
    ? 'Buyer Funding Information'
    : isPortfolioSeller
    ? 'Seller Funding Information'
    : 'Funding Information'

  const ability = useContext(AbilityContext)
  const { userPermissions } = useContext(AuthContext)
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const gradientBackgroundColor = '#F2F2F2'

  const showBOSComponent =
    (isPortfolioSeller || isInternal) &&
    (portfolioStatus === PortfolioStatusType.AwaitingSellerFee ||
      portfolioStatus === PortfolioStatusType.PendingFinalConfirmation ||
      portfolioStatus === PortfolioStatusType.Funded) &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Workflows_CompleteBOSWorkflow,
      'any'
    ) &&
    !portfolioHasBOSConfirmed

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  return (
    <>
      {!loadingPortfolioPurchaseInfo ? (
        purchaseInfo && (
          <DTAccordion
            id="funding-information-accordion"
            title={title}
            icon={
              isPortfolioUk ? (
                <PoundCircleFilledStyled />
              ) : (
                <Icon name="MonetizationOn" />
              )
            }
            expanded={true}
          >
            <Grid container spacing={4}>
              {[
                PortfolioStatusType.PendingFinalConfirmation.toString(),
                PortfolioStatusType.AwaitingSellerFee.toString(),
              ].includes(portfolioStatus) &&
                isPortfolioSeller &&
                hasBOS &&
                hasFinalBOS && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color={textPrimary.color}>
                      <strong>Next Step: </strong>Waiting for service fee
                      confirmation.
                    </Typography>
                  </Grid>
                )}
              {showBOSComponent && (
                <Grid item xs={12}>
                  <BillOfSale
                    portfolioId={portfolioId}
                    portfolioHasBOS={hasBOS}
                    portfolioHasFinalBOS={hasFinalBOS}
                    portfolioHasBOSReleased={portfolioHasBOSReleased}
                    portfolioHasBOSConfirmed={portfolioHasBOSConfirmed}
                    isPortfolioSeller={Boolean(isPortfolioSeller)}
                    eSignatureEnabledBOS={Boolean(eSignatureEnabledBOS)}
                    isBosESignatureSent={Boolean(isBosESignatureSent)}
                    bosSignerName={bosSignerName}
                    bosSignerTitle={bosSignerTitle}
                    bosSignerEmail={bosSignerEmail}
                    sellerUploadTemplateId={sellerUploadTemplateId}
                  />
                </Grid>
              )}
              {portfolioStatus === PortfolioStatusType.PendingSellerFunding &&
                isPortfolioBuyer && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color={textPrimary.color}>
                      <strong>Next Step: </strong>Waiting for seller funding
                      confirmation.
                    </Typography>
                  </Grid>
                )}
              {portfolioStatus ===
                PortfolioStatusType.PendingFinalConfirmation &&
                isPortfolioBuyer && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color={textPrimary.color}>
                      <strong>Next Step: </strong>Waiting for final funding
                      confirmation.
                    </Typography>
                  </Grid>
                )}
              {/* <Grid item xs={12}>
                    <BillOfSale
                      portfolioId={portfolioId}
                      portfolioHasBOS={hasBOS}
                      portfolioHasFinalBOS={hasFinalBOS}
                      isPortfolioSeller={false}
                    />
                  </Grid> */}
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellFundingCustom
                          style={{ color: Colors.primary }}
                          component="th"
                          scope="row"
                        >
                          Face Value
                        </TableCellFundingCustom>
                        <TableCellFundingCustom
                          style={{ color: Colors.primary }}
                          align="right"
                        >
                          {purchaseInfo.faceValue}
                        </TableCellFundingCustom>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {!isPortfolioSeller && (
                <Grid item xs={12}>
                  {!isPortfolioBuyer && (
                    <Typography variant="h6" id="tableTitle" component="div">
                      Buyer Funding
                    </Typography>
                  )}
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {!isPortfolioBuyer && (
                          <TableRow>
                            <TableCellFundingCustom component="th" scope="row">
                              Buyer Name
                            </TableCellFundingCustom>
                            <TableCellFundingCustom align="right">
                              {buyerName}
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Purchase Price ({purchaseInfo.purchasePricePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {purchaseInfo.purchasePriceAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                        {purchaseInfo.isBk && (
                          <TableRow>
                            <TableCellFundingCustom component="th" scope="row">
                              Total TOC ({purchaseInfo.totalPocAccounts} x{' '}
                              {purchaseInfo.pocValue})
                            </TableCellFundingCustom>
                            <TableCellFundingCustom
                              style={{ color: 'red' }}
                              align="right"
                            >
                              - {purchaseInfo.totalTocAmount}
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                        {purchaseInfo.isBk && (
                          <TableRow
                            style={{ backgroundColor: gradientBackgroundColor }}
                          >
                            <TableCellFundingCustom component="th" scope="row">
                              Final Purchase Price (
                              {purchaseInfo.finalPurchasePricePercent})
                            </TableCellFundingCustom>
                            <TableCellFundingCustom align="right">
                              {purchaseInfo.finalPurchasePriceAmountDisplay}
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCellFundingCustom component="th" scope="row">
                            {`Sales Commission (${purchaseInfo.buyerFeeDisplay})`}
                            <Tooltip
                              title="
                  As compensation for the services
                  performed by EverChain, Buyer shall pay
                  EverChain a sales commission. This
                  sales commission is a percentage of the
                  total sale price for each executed sale
                  of a debt portfolio made with a Seller
                  via EverChain as displayed on the
                  portfolio summary page within the
                  EverChain platform"
                            >
                              <Icon
                                name="HelpOutline"
                                fontSize="inherit"
                                style={{ paddingLeft: '2px' }}
                              />
                            </Tooltip>
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            + {purchaseInfo.buyerFeeAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                        {isPortfolioUk && purchaseInfo.buyerVATFeeDisplay && (
                          <TableRow
                            style={{ backgroundColor: gradientBackgroundColor }}
                          >
                            <TableCellFundingCustom component="th" scope="row">
                              VAT Fee ({purchaseInfo.buyerVATFeePercent})
                            </TableCellFundingCustom>
                            <TableCellFundingCustom align="right">
                              + {purchaseInfo.buyerVATFeeDisplay}
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                        {purchaseInfo.buyerPurchaseAmountDisplay && (
                          <TableRow>
                            <TableCellFundingCustom
                              style={{ borderTop: '1px solid' }}
                              component="th"
                              scope="row"
                            >
                              <strong> Total Purchase Amount</strong>
                            </TableCellFundingCustom>
                            <TableCellFundingCustom
                              style={{ borderTop: '1px solid' }}
                              align="right"
                            >
                              <strong>
                                {' '}
                                {purchaseInfo.buyerPurchaseAmountDisplay}
                              </strong>
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              {!isPortfolioBuyer && (
                <Grid item xs={12}>
                  {!isPortfolioSeller && (
                    <Typography variant="h6" id="tableTitle" component="div">
                      Seller Funding
                    </Typography>
                  )}
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {!isPortfolioSeller && (
                          <TableRow>
                            <TableCellFundingCustom component="th" scope="row">
                              Seller Name
                            </TableCellFundingCustom>
                            <TableCellFundingCustom align="right">
                              {sellerName}
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                        <TableRow
                          style={{ backgroundColor: gradientBackgroundColor }}
                        >
                          <TableCellFundingCustom component="th" scope="row">
                            Purchase Price ({purchaseInfo.purchasePricePercent})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom align="right">
                            {purchaseInfo.purchasePriceAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                        {purchaseInfo.isBk && (
                          <TableRow>
                            <TableCellFundingCustom component="th" scope="row">
                              Total TOC ({purchaseInfo.totalPocAccounts} x{' '}
                              {purchaseInfo.pocValue})
                            </TableCellFundingCustom>
                            <TableCellFundingCustom
                              style={{ color: 'red' }}
                              align="right"
                            >
                              - {purchaseInfo.totalTocAmount}
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                        {purchaseInfo.isBk && (
                          <TableRow>
                            <TableCellFundingCustom component="th" scope="row">
                              Final Purchase Price (
                              {purchaseInfo.finalPurchasePricePercent})
                            </TableCellFundingCustom>
                            <TableCellFundingCustom align="right">
                              {purchaseInfo.finalPurchasePriceAmountDisplay}
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCellFundingCustom component="th" scope="row">
                            Service Fee To EverChain (
                            {purchaseInfo.sellerFeeDisplay})
                          </TableCellFundingCustom>
                          <TableCellFundingCustom
                            style={{ color: 'red' }}
                            align="right"
                          >
                            - {purchaseInfo.sellerFeeAmountDisplay}
                          </TableCellFundingCustom>
                        </TableRow>
                        {isPortfolioUk && (
                          <TableRow
                            style={{ backgroundColor: gradientBackgroundColor }}
                          >
                            <TableCellFundingCustom component="th" scope="row">
                              VAT Fee ({purchaseInfo.sellerVATFeePercent})
                            </TableCellFundingCustom>
                            <TableCellFundingCustom
                              style={{ color: 'red' }}
                              align="right"
                            >
                              - {purchaseInfo.sellerVATFeeDisplay}
                            </TableCellFundingCustom>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCellFundingCustom
                            style={{ borderTop: '1px solid' }}
                            component="th"
                            scope="row"
                          >
                            <strong>
                              {' '}
                              {isSeller
                                ? 'Net Proceeds To You'
                                : 'Net To Seller'}{' '}
                              ({purchaseInfo.netToSellerPercentDisplay})
                            </strong>
                          </TableCellFundingCustom>
                          <TableCellFundingCustom
                            style={{ borderTop: '1px solid' }}
                            align="right"
                          >
                            <strong> {purchaseInfo.netToSellerDisplay}</strong>
                          </TableCellFundingCustom>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </DTAccordion>
        )
      ) : (
        <Skeleton variant="rectangular" width="100%" height={50} />
      )}
    </>
  )
}
FundingInformation.defaultProps = {
  isInternal: false,
}
export default FundingInformation
