import { Box, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { numberCurrencyDollar, numberToPercentage } from 'src/utils/numbers'
import { TypographyWhite } from 'src/styles/layout'
import { CircularProgress, Colors, Flex, Icon } from 'everchain-uilibrary'
import { BuyerBidSimulateValuesItemResponse } from 'src/data/features/get/financial/types'

interface BuyerPlaceBidBkSummaryProps {
  buyerBidSimulateValues?: BuyerBidSimulateValuesItemResponse
  portfolioCountry: string | undefined
  isLoading?: boolean
}

const BuyerPlaceBidBkSummary: React.FC<BuyerPlaceBidBkSummaryProps> = ({
  buyerBidSimulateValues,
  portfolioCountry,
  isLoading,
}: BuyerPlaceBidBkSummaryProps) => {
  const CircularProgressStandard = () => {
    return (
      <Flex w="100%" justifyContent="center" pt={2}>
        <CircularProgress width={25} color={Colors.secondary} />
      </Flex>
    )
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
              minWidth: '130px',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2" align="center">
              Purchase Proceeds
            </Typography>
            {isLoading ? (
              <CircularProgressStandard />
            ) : (
              <>
                <Typography
                  style={{ color: 'white' }}
                  color="inherit"
                  variant="subtitle2"
                  align="center"
                >
                  {numberToPercentage(
                    buyerBidSimulateValues?.purchaseProceedsPercent,
                    '',
                    3
                  )}
                </Typography>
                <TypographyWhite variant="h6" align="center">
                  {buyerBidSimulateValues?.purchaseProceedsAmount &&
                  buyerBidSimulateValues.purchaseProceedsAmount >= 0.001
                    ? numberCurrencyDollar(
                        buyerBidSimulateValues.purchaseProceedsAmount,
                        portfolioCountry
                      )
                    : 'N/A'}
                </TypographyWhite>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs
          style={{
            display: 'flex',
            flexGrow: 0,
            alignItems: 'center',
            minWidth: '30px',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h1">-</Typography>
        </Grid>
        <Grid item xs>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
              minWidth: '130px',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2" align="center">
              TOC Amount
            </Typography>
            {isLoading ? (
              <CircularProgressStandard />
            ) : (
              <TypographyWhite variant="h6" align="center">
                {buyerBidSimulateValues?.tocAmount
                  ? numberCurrencyDollar(
                      buyerBidSimulateValues.tocAmount,
                      portfolioCountry
                    )
                  : 'N/A'}
              </TypographyWhite>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs
          style={{
            display: 'flex',
            flexGrow: 0,
            alignItems: 'center',
            minWidth: '30px',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h1">=</Typography>
        </Grid>
        <Grid item xs>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
              minWidth: '130px',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2" align="center">
              Final Purchase Proceeds
            </Typography>
            {isLoading ? (
              <CircularProgressStandard />
            ) : (
              <TypographyWhite variant="h6" align="center">
                {buyerBidSimulateValues?.finalPurchaseProceedsAmount &&
                buyerBidSimulateValues?.finalPurchaseProceedsAmount > 0
                  ? numberCurrencyDollar(
                      buyerBidSimulateValues.finalPurchaseProceedsAmount ?? 0,
                      portfolioCountry
                    )
                  : ''}
              </TypographyWhite>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
              minWidth: '130px',
            }}
            p={2}
            pb={4}
          >
            <Typography color="inherit" variant="subtitle2" align="center">
              Final Purchase Proceeds
            </Typography>
            {isLoading ? (
              <CircularProgressStandard />
            ) : (
              <>
                <Typography color="inherit" variant="subtitle2" align="center">
                  {numberToPercentage(
                    buyerBidSimulateValues?.finalPurchaseProceedsPercent,
                    '',
                    3
                  )}
                </Typography>
                <TypographyWhite variant="h6" align="center">
                  {numberCurrencyDollar(
                    buyerBidSimulateValues?.finalPurchaseProceedsAmount ?? 0,
                    portfolioCountry
                  )}
                </TypographyWhite>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs
          style={{
            display: 'flex',
            flexGrow: 0,
            alignItems: 'center',
            minWidth: '30px',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h1">+</Typography>
        </Grid>
        <Grid item xs>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
              minWidth: '130px',
            }}
            p={2}
            pb={4}
          >
            <Typography color="inherit" variant="subtitle2" align="center">
              Sales Commission at{' '}
            </Typography>
            {isLoading ? (
              <CircularProgressStandard />
            ) : (
              <>
                <Typography color="inherit" variant="subtitle2" align="center">
                  {numberToPercentage(
                    buyerBidSimulateValues?.salesCommissionPercent,
                    '',
                    3
                  )}
                  <Tooltip
                    title="
                  As compensation for the services
                  performed by EverChain, Buyer shall pay
                  EverChain a sales commission. This
                  sales commission is a percentage of the
                  total sale price for each executed sale
                  of a debt portfolio made with a Seller
                  via EverChain as displayed on the
                  portfolio summary page within the
                  EverChain platform"
                  >
                    <Icon name="HelpOutline" fontSize="inherit" />
                  </Tooltip>
                </Typography>
                <TypographyWhite variant="h6" align="center">
                  {buyerBidSimulateValues?.salesCommissionAmount &&
                  buyerBidSimulateValues.salesCommissionAmount > 0
                    ? numberCurrencyDollar(
                        buyerBidSimulateValues.salesCommissionAmount,
                        portfolioCountry
                      )
                    : 'N/A'}
                </TypographyWhite>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs
          style={{
            display: 'flex',
            flexGrow: 0,
            alignItems: 'center',
            minWidth: '30px',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h1">=</Typography>
        </Grid>
        <Grid item xs>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
              minWidth: '130px',
            }}
            p={2}
            pb={4}
          >
            <Typography color="inherit" variant="subtitle2" align="center">
              Total Price
            </Typography>
            {isLoading ? (
              <CircularProgressStandard />
            ) : (
              <>
                <Typography color="inherit" variant="subtitle2" align="center">
                  {numberToPercentage(
                    buyerBidSimulateValues?.totalPricePercent,
                    '',
                    3
                  )}
                </Typography>
                <TypographyWhite variant="h6" align="center">
                  {buyerBidSimulateValues?.totalPriceAmount
                    ? numberCurrencyDollar(
                        buyerBidSimulateValues.totalPriceAmount,
                        portfolioCountry
                      )
                    : 'N/A'}
                </TypographyWhite>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default BuyerPlaceBidBkSummary
