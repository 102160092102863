import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Slider,
  Tooltip,
  Skeleton,
  Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { ScoringRequestResponse } from 'src/graphql/models/PortfolioDetail'
import {
  GET_BUYERS_ALLOWED_TUSCORING,
  GET_SCORING_DATA_INFO,
} from 'src/graphql/operations/queries/portfolio'
import { useQuery } from '@apollo/client'
import { BusinessSelector } from 'src/components/BusinessSelector'
import { formatDateAndTime } from 'src/utils/date'
import { BuyerInfoQuery } from 'src/graphql/models/Portfolio'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import DTAccordion from 'src/components/Accordion'
import { PortfolioBid } from 'src/data/features/get/portfolio/types'
import { Colors, Icon } from 'everchain-uilibrary'
import { useMutation } from '@tanstack/react-query'
import { RequestScoring } from 'src/data/features/post/portfolio/portfolio'

interface ScoringRequestProps {
  portfolioNumber: string | undefined
  totalNumberOfAccounts: number
  buyerBidsData: PortfolioBid[] | undefined
}

const useStylesTooltip = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: 'rgba(112,112,112,0.95)',
    color: Colors.black,
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(10),
  },
}))

const HtmlTooltip = ({
  children,
  title,
  placement,
}: {
  children: React.ReactElement<any, any>
  title: React.ReactNode
  placement?: 'left-start'
}) => {
  const classes = useStylesTooltip()
  return (
    <Tooltip title={title} className={classes.tooltip} placement={placement}>
      {children}
    </Tooltip>
  )
}

const notifySuccess = notistackOptions('success')
const marks = [
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.75,
    label: '75%',
  },
]

const ScoringRequest: React.FC<ScoringRequestProps> = ({
  portfolioNumber,
  totalNumberOfAccounts,
  buyerBidsData,
}: ScoringRequestProps) => {
  const ability = useContext(AbilityContext)

  const [selectedBusiness, setSelectedBusiness] = useState<any>(undefined)
  const [disableUI, setDisableUI] = useState<boolean>(true)
  const { enqueueSnackbar } = useSnackbar()

  const ScoringPermission =
    ability.can(PermissionCodeAccess.MarketPlace_Scoring, 'any') &&
    ability.can(PermissionCodeAccess.MarketPlace_Scoring_RequestScoring, 'any')

  const { data: scoringInfo, loading: loadingScoringInfo } =
    useQuery<ScoringRequestResponse>(GET_SCORING_DATA_INFO, {
      variables: {
        scoringRequest: {
          portfolioNumber,
        },
      },
    })

  const { data: buyersTuScoring, loading: loadingBuyersAllowedTuScoring } =
    useQuery<BuyerInfoQuery>(GET_BUYERS_ALLOWED_TUSCORING, {
      variables: {
        portfolioNumber,
      },
    })

  useEffect(() => {
    const len = buyersTuScoring?.buyersData?.length
    if (len !== undefined && len >= 1) {
      setSelectedBusiness(buyersTuScoring?.buyersData[0])
      setDisableUI(false)
    }
  }, [buyersTuScoring])

  const requestPortfolioScoring = useMutation({
    mutationFn: (variables: any) => {
      return RequestScoring(variables)
    },
    onSuccess: (scoringResponse: any) => {
      if (scoringResponse) {
        enqueueSnackbar(
          'Portfolio has been submitted for scoring',
          notifySuccess
        )
      }
    },
  })

  const [numberOfAccountsPercent, setNumberOfAccountsPercent] =
    useState<number>(0.1)
  const [randomizationOption, setRandomizationOption] = useState(
    'sort by states / top balances'
  )

  const onSubmitScoring = () => {
    setDisableUI(true)

    requestPortfolioScoring.mutate({
      scoringRequest: {
        portfolioNumber,
        randomization: randomizationOption,
        numberOfAccounts: Math.round(
          numberOfAccountsPercent * totalNumberOfAccounts
        ),
        buyerId: selectedBusiness?.id,
      },
    })
  }

  const handleBusinessSelected = (business: any) => {
    setSelectedBusiness(business)
    setDisableUI(false)
  }

  if (loadingScoringInfo || loadingBuyersAllowedTuScoring)
    return <Skeleton variant="rectangular" width="100%" height={50} />

  return (
    <DTAccordion
      id="scoring-accordion"
      title="Scoring"
      icon={<Icon name="Score" />}
    >
      <Box
        display="flex"
        flexDirection="column"
        pl={4}
        className="fillContentScore"
      >
        {scoringInfo?.getScoringInfo ? (
          <>
            {buyersTuScoring && buyersTuScoring.buyersData?.length > 1 && (
              <Box fontSize="0.9rem">
                Buyer Name: {scoringInfo?.getScoringInfo?.buyerName}
              </Box>
            )}
            <Box fontSize="0.9rem">
              Requested by: {scoringInfo?.getScoringInfo?.requestedByUserName}
            </Box>
            <Box fontSize="0.9rem">
              Requested on:{' '}
              {formatDateAndTime(scoringInfo?.getScoringInfo?.requestedUtc)}
            </Box>
            <Box fontSize="0.9rem" display="flex" alignItems="center">
              <span>
                {scoringInfo?.getScoringInfo?.submittedUtc
                  ? 'Submitted'
                  : 'Pending submission'}
              </span>
              {scoringInfo?.getScoringInfo?.submittedUtc && (
                <HtmlTooltip title="Your request has been submitted. Transunion will contact you directly.">
                  <Icon name="HelpOutline" fontSize="small" />
                </HtmlTooltip>
              )}
            </Box>
          </>
        ) : (
          <>
            {buyersTuScoring?.buyersData &&
              buyersTuScoring.buyersData.length > 1 && (
                <Box mb={6}>
                  <BusinessSelector
                    data={buyersTuScoring?.buyersData}
                    disabled={selectedBusiness && disableUI}
                    label="Buyer"
                    noDataText="This user doesn't belong to any buyer"
                    selectionText="Select a buyer"
                    value={selectedBusiness || undefined}
                    onSelectedBusiness={(business: any) => {
                      handleBusinessSelected(business)
                    }}
                  />
                </Box>
              )}
            <Box display="flex" flexDirection="row">
              <Box component="b" mr={10} width="60%">
                <Typography component="div">
                  <Box fontWeight={500} fontSize="0.9rem">
                    {Math.round(
                      numberOfAccountsPercent * totalNumberOfAccounts
                    )}{' '}
                    accounts from a total of {totalNumberOfAccounts}
                  </Box>
                </Typography>
                <Slider
                  defaultValue={0.1}
                  aria-labelledby="numberOfAccountsLabel"
                  step={0.05}
                  marks={marks}
                  min={0.05}
                  max={1}
                  valueLabelFormat={(v) => `${Math.round(v * 100)}%`}
                  valueLabelDisplay="auto"
                  onChange={(e, val) => {
                    if (typeof val == 'number') setNumberOfAccountsPercent(val)
                  }}
                  disabled={disableUI}
                />
              </Box>
              <Box component="b" width="40%">
                <Box display="flex" flexDirection="row">
                  <Typography component="div">
                    <Box fontWeight={500} fontSize="0.9rem">
                      Randomization Method
                    </Box>
                  </Typography>
                  <Box ml={2}>
                    <HtmlTooltip
                      placement="left-start"
                      title={
                        <Box>
                          <Typography>
                            <Box
                              color="white"
                              fontSize="1.4rem"
                              fontWeight="500"
                            >
                              Randomization options
                            </Box>
                          </Typography>

                          <Typography>
                            <Box
                              color="white"
                              fontSize="1.2rem"
                              fontWeight="500"
                              mt={6}
                            >
                              Fully randomized
                            </Box>
                          </Typography>
                          <Typography>
                            <Box color="white" fontSize="0.8rem">
                              Pick a number of valid accounts completely at
                              random from the original file
                            </Box>
                          </Typography>

                          <Typography>
                            <Box
                              color="white"
                              fontSize="1.2rem"
                              fontWeight="500"
                              mt={6}
                            >
                              Sort by states / random balances
                            </Box>
                          </Typography>
                          <Typography>
                            <Box color="white" fontSize="0.8rem">
                              Pick a number of valid accounts per state in the
                              same proportion as they exist in the original
                              file, then from each state pick accounts at
                              random.
                            </Box>
                          </Typography>

                          <Typography>
                            <Box
                              color="white"
                              fontSize="1.2rem"
                              fontWeight="500"
                              mt={6}
                            >
                              Sort by states / top balances
                            </Box>
                          </Typography>
                          <Typography>
                            <Box color="white" fontSize="0.8rem">
                              Pick a number of valid accounts per state in the
                              same proportion as they exist in the original
                              file, then from each state pick those accounts
                              with highest balance.
                            </Box>
                          </Typography>

                          <Typography>
                            <Box
                              color="white"
                              fontSize="1.2rem"
                              fontWeight="500"
                              mt={6}
                            >
                              Sort by states / median balances
                            </Box>
                          </Typography>
                          <Typography>
                            <Box color="white" fontSize="0.8rem">
                              Pick a number of valid accounts per state in the
                              same proportion as they exist in the original
                              file, then from each state pick those accounts
                              with median balance.
                            </Box>
                          </Typography>

                          <Typography>
                            <Box color="white" fontSize="0.8rem" mt={4}>
                              * The total number of accounts when sorting by
                              states is approximate considering that there may
                              be rounding errors while retrieving the stratified
                              sample of accounts.
                            </Box>
                          </Typography>
                        </Box>
                      }
                    >
                      <Icon name="HelpOutline" fontSize="small" />
                    </HtmlTooltip>
                  </Box>
                </Box>

                <Box fontSize="1rem">
                  <Select
                    labelId="label"
                    id="select"
                    value={randomizationOption}
                    onChange={(e) => {
                      setRandomizationOption(String(e.target.value))
                    }}
                    disabled={disableUI}
                  >
                    <MenuItem value="fully randomized">
                      fully randomized
                    </MenuItem>
                    <MenuItem value="sort by states / random balances">
                      sort by states / random balances
                    </MenuItem>
                    <MenuItem value="sort by states / top balances">
                      sort by states / top balances
                    </MenuItem>
                    <MenuItem value="sort by states / median balances">
                      sort by states / median balances
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>

            <Box mt={2} display="flex" flexDirection="row">
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(): void => onSubmitScoring()}
                  disabled={
                    disableUI ||
                    !ScoringPermission ||
                    (buyerBidsData && buyerBidsData.length > 0)
                  }
                >
                  Request TransUnion Scoring
                </Button>
              </Box>
              <Box mr={2}>
                {(disableUI || !ScoringPermission) && (
                  <Typography component="div">
                    <Box fontWeight={500} fontSize="0.9rem">
                      Contact Recovery Success to find out how to integrate with
                      TransUnion.
                    </Box>
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </DTAccordion>
  )
}

export default ScoringRequest
