import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Skeleton } from '@mui/material'
import numeral from 'numeral'
import CardInfo from 'src/components/CardInfo'
import { ComplianceCardUrl, PermissionCodeAccess } from 'src/utils/constants'
import { COMPLIANCE_LIST } from 'src/routes'
import { AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ComplianceListCardSection } from '../styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getComplaintCard } from 'src/data/features/get/complaint/complaint'
import { ComplaintItemCardResponse } from 'src/data/features/get/complaint/types'
import { getStandardUri } from 'src/utils/common'

interface SectionCardsProps {
  onSelect: (type: string) => void
  selected: string
  setCanAccessBco: any
  setAccessDenyBco: any
  // eslint-disable-next-line react/no-unused-prop-types
  filters: {}
}

interface DisplayValueParams {
  urlType: string
  value: number
}

const cardRouteMapper = ['action-items', 'open', 'closed']
const displayValueFormat = ({ value, urlType }: DisplayValueParams): string => {
  if (
    urlType === 'action-items' ||
    urlType === 'open' ||
    urlType === 'closed' ||
    urlType === 'bco' ||
    urlType === 'overdue'
  ) {
    return value.toString()
  }
  if (value <= 0) {
    return 'N/A'
  }
  return numeral(value).format('0.00a')
}

const SectionCards: React.FC<SectionCardsProps> = ({
  onSelect,
  selected,
  setCanAccessBco,
  setAccessDenyBco,
}) => {
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const ability = useContext(AbilityContext)
  const history = useHistory()

  const { data: complaintCard, isFetching: loadingComplaintCard } =
    useCustomQuery<ComplaintItemCardResponse[]>(
      ['getComplaintCard'],
      async () =>
        getComplaintCard().then((res) => {
          setCanAccessBco(res.find((x) => x.url === 'bco'))
          return res
        }),
      {
        cacheTime: 0,
      }
    )

  const handleflowCard = (urlType: string): void => {
    let url = ''
    switch (urlType) {
      case ComplianceCardUrl.ACTIVE_ITEMS:
        url = ComplianceCardUrl.ACTIVE_ITEMS
        break
      case ComplianceCardUrl.OPEN:
        url = ComplianceCardUrl.OPEN
        break
      case ComplianceCardUrl.CLOSED:
        url = ComplianceCardUrl.CLOSED
        break
      case ComplianceCardUrl.OVERDUE:
        url = ComplianceCardUrl.OVERDUE
        break

      default:
        url = ComplianceCardUrl.ACTIVE_ITEMS
        break
    }
    history.push(getStandardUri(`${COMPLIANCE_LIST}/${url}`))
  }

  useEffect(() => {
    const bcoDeny = isInternal
      ? !ability.can(
          PermissionCodeAccess.MarketPlace_BuyerComplianceOverview,
          'any'
        )
      : !ability.can(
          // eslint-disable-next-line max-len
          PermissionCodeAccess.MarketPlace_BuyerComplianceOverview_OpenBuyerComplianceOverview,
          'any'
        )
    setAccessDenyBco(bcoDeny)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complaintCard, setAccessDenyBco])

  if (loadingComplaintCard && !complaintCard)
    return (
      <ComplianceListCardSection>
        <Grid container spacing={6}>
          {[0, 1, 2, 3, 4].map((card) => (
            <Grid key={card} item xs={6} sm={6} md={4} lg={2.4}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
          ))}
        </Grid>
      </ComplianceListCardSection>
    )
  return complaintCard?.length ? (
    <ComplianceListCardSection>
      <Grid container spacing={6} alignItems="stretch">
        {complaintCard.map((card) => {
          const bcoDeny = isInternal
            ? !ability.can(
                PermissionCodeAccess.MarketPlace_BuyerComplianceOverview,
                'any'
              )
            : !ability.can(
                // eslint-disable-next-line max-len
                PermissionCodeAccess.MarketPlace_BuyerComplianceOverview_OpenBuyerComplianceOverview,
                'any'
              )

          if ((card.url === 'my-bcr' || card.url === 'bco') && bcoDeny) {
            return <></>
          }
          return (
            <Grid key={card.value} item xs={6} sm={6} md={4} lg={2.4}>
              <CardInfo
                textContent={displayValueFormat({
                  value: card.value,
                  urlType: card.url,
                })}
                cardType="secondary"
                cursor
                hover
                square={false}
                elevation={1}
                selected={Boolean(card.url === selected)}
                textFooter={card.displayName}
                onClick={(): void => {
                  if (!cardRouteMapper.includes(card.url)) {
                    onSelect(card.url)
                  } else {
                    handleflowCard(card.url)
                  }
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    </ComplianceListCardSection>
  ) : null
}

export default SectionCards
