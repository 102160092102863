import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import {
  PostSaleAcceptDataRequest,
  PostSaleAddRequestComment,
  PostSaleAssignRequestToUser,
  PostSaleCloseRequest,
  PostSaleConfirmBuyerFundingNotificationURL,
  PostSaleCreatePostSaleRequest,
  PostSaleGetBulkRequestInfoSelectedAccounts,
  PostSaleGetZipFileBlobUri,
  PostSalerequestedDataSubmittedRequestURL,
  PostSaleSaveBulkRequestFilesAttachment,
  PostSaleSaveRequestAttachment,
  PostSaleSubmitAccountsRequestedData,
  PostSaleWithdrawRequest,
} from './urls'
import { SaveRequestAttachmentRequest } from '../../get/postSale/types'
import {
  AssignToUserRequest,
  BulkAttachmentRequest,
  WithdrawRequest,
} from './types'

export const addRequestComment = async (
  requestId: number,
  comment: string
): Promise<any> => {
  const response = await httpClient.post(PostSaleAddRequestComment, {
    requestId,
    comment,
  })
  return response?.data
}

export const saveRequestAttachment = async (
  request: SaveRequestAttachmentRequest
): Promise<any> => {
  const form = new FormData()
  form.append('requestId', request.requestId.toString())
  form.append('description', request.description)
  form.append('file', request.file)
  const response = await httpClient.post(PostSaleSaveRequestAttachment, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}

export const assignRequestToUser = async (
  request: AssignToUserRequest[]
): Promise<any> => {
  const response = await httpClient.post(PostSaleAssignRequestToUser, request)

  return response?.data
}

export const saveBulkRequestFilesAttachment = async (
  request: BulkAttachmentRequest
): Promise<any> => {
  const form = new FormData()
  form.append('requestId', request.requestId.toString())
  request.requestFileAttachments.forEach((attachment, index) => {
    form.append(
      `requestFileAttachments[${index}].description`,
      attachment?.description ?? ''
    )
    form.append(`requestFileAttachments[${index}].file`, attachment.file)
  })

  const response = await httpClient.post(
    PostSaleSaveBulkRequestFilesAttachment,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return response?.data
}

export const uploadWithdrawRequest = async (
  request: WithdrawRequest[]
): Promise<any> => {
  const response = await httpClient.post(PostSaleWithdrawRequest, request)
  return response?.data
}

export const createPostSaleRequest = async (request: any): Promise<any> => {
  const response = await httpClient.post(PostSaleCreatePostSaleRequest, request)
  return response?.data
}

export const closeRequest = async (requestId: number): Promise<any> => {
  const response = await httpClient.post(PostSaleCloseRequest, requestId)
  return response?.data
}

export const acceptDataRequest = async (requestId: number): Promise<any> => {
  const response = await httpClient.post(PostSaleAcceptDataRequest, requestId)
  return response?.data
}

export const submitAccountsRequestedData = async (
  requestIds: number[]
): Promise<any> => {
  const response = await httpClient.post(
    PostSaleSubmitAccountsRequestedData,
    requestIds
  )
  return response?.data
}

export const requestedDataSubmittedRequest = async (
  requestId: number
): Promise<any> => {
  const response = await httpClient.post(
    PostSalerequestedDataSubmittedRequestURL,
    requestId
  )
  return response?.data
}

export const confirmBuyerFundingNotificationRequest = async (
  fundingNotificationId: number
): Promise<any> => {
  const response = await httpClient.post(
    PostSaleConfirmBuyerFundingNotificationURL,
    fundingNotificationId
  )
  return response?.data
}

export const getBulkRequestInfoSelectedAccounts = async (
  requestIds: number[]
): Promise<any> => {
  const response = await httpClient.post(
    PostSaleGetBulkRequestInfoSelectedAccounts,
    requestIds
  )
  return response?.data
}

export const getZipFileBlobUri = async (
  requestsIds: number[]
): Promise<any> => {
  const response = await httpClient.post(PostSaleGetZipFileBlobUri, requestsIds)
  return response?.data
}
