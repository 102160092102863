import { GlobalSearchOption } from 'src/context/Interfaces'

export enum GlobalSearchFilterTypeEnum {
  ALL = 'all',
  PID = 'portfolio',
  ACCOUNT_ID = 'portfoliorow',
  POST_SALE_ID = 'postsale',
  COMPLAINT_ID = 'complaint',
  FORWARD_FLOW_ID = 'forwardflow',
  POST_SALE_FUNDING_ID = 'postsalefunding',
  SELLER_NAME = 'seller',
  BUYER_NAME = 'buyer',
  SELLER_UPLOAD_TEMPLATE = 'selleruploadtemplate',
}

export interface GlobalSearch {
  defaultOption?: GlobalSearchOption
  options: GlobalSearchOption[]
}
