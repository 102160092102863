/* eslint-disable react/require-default-props */
import React from 'react'
import { Box, Skeleton } from '@mui/material'
import { COMPLIANCE_INFO, SELLER_DETAIL, BUYER_DETAIL } from 'src/routes'
import { getStandardUri } from 'src/utils/common'
import { AccountComplaint } from 'src/data/features/get/account/types'
import { renderClickableNumberLink } from 'src/utils/formatKendoColumns'
import { DataTable } from 'everchain-uilibrary'

interface ComplaintProps {
  getAccountComplaint?: AccountComplaint[]
  loading: boolean
}

const Complaint = ({ getAccountComplaint, loading }: ComplaintProps) => {
  if (loading) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  const dataColumns = [
    {
      field: 'id',
      title: 'ID',
      show: true,
      render: (props: any) =>
        renderClickableNumberLink(
          props,
          getStandardUri(`${COMPLIANCE_INFO}/${props.dataItem['id']}`),
          null,
          'left'
        ),
    },
    {
      field: 'status',
      title: 'Status',
      show: true,
    },
    {
      field: 'outcome',
      title: 'Final Outcome',
      show: true,
    },
    {
      field: 'loanId',
      title: 'Loan ID',
      show: true,
    },
    {
      title: 'Seller',
      field: 'seller',
      show: true,
      render: (props: any) =>
        renderClickableNumberLink(
          props,
          getStandardUri(`${SELLER_DETAIL}/${props.dataItem['sellerId']}`),
          null,
          'left'
        ),
    },
    {
      title: 'Buyer',
      field: 'buyer',
      show: true,
      render: (props: any) =>
        renderClickableNumberLink(
          props,
          getStandardUri(`${BUYER_DETAIL}/${props.dataItem['buyerId']}`),
          null,
          'left'
        ),
    },
  ]

  return (
    <Box p={2}>
      <DataTable data={getAccountComplaint} gridColumns={dataColumns} />
    </Box>
  )
}

export default Complaint
