import { GlobalSearch, GlobalSearchFilterTypeEnum } from './types'
import { GlobalSearchOption, UserPermissions } from 'src/context/Interfaces'
import {
  ACCOUNT_DETAIL,
  BUYER_DETAIL,
  COMPLIANCE,
  DASHBOARD,
  FORWARD_FLOW,
  FUNDING,
  LOAD_PORTFOLIO,
  PORTFOLIO,
  POSTSALE,
  SEARCH,
  SELLER_DETAIL,
} from 'src/routes'
import { getStandardUri, isMarketplaceRoute } from 'src/utils/common'

export const pathToSearchFilterMap = [
  { path: DASHBOARD, type: GlobalSearchFilterTypeEnum.ALL },
  { path: `${PORTFOLIO}/`, type: GlobalSearchFilterTypeEnum.PID },
  { path: `${POSTSALE}/`, type: GlobalSearchFilterTypeEnum.POST_SALE_ID },
  { path: `${COMPLIANCE}/`, type: GlobalSearchFilterTypeEnum.COMPLAINT_ID },
  {
    path: `${FORWARD_FLOW}/`,
    type: GlobalSearchFilterTypeEnum.FORWARD_FLOW_ID,
  },
  {
    path: `${FUNDING}/`,
    type: GlobalSearchFilterTypeEnum.POST_SALE_FUNDING_ID,
  },
  { path: `${ACCOUNT_DETAIL}/`, type: GlobalSearchFilterTypeEnum.ACCOUNT_ID },
  {
    path: LOAD_PORTFOLIO,
    type: GlobalSearchFilterTypeEnum.SELLER_UPLOAD_TEMPLATE,
  },
  { path: `${SELLER_DETAIL}/`, type: GlobalSearchFilterTypeEnum.SELLER_NAME },
  { path: `${BUYER_DETAIL}/`, type: GlobalSearchFilterTypeEnum.BUYER_NAME },
]

export const globalSearchRouteOption = (
  location: any,
  globalSearchOption?: GlobalSearchOption[]
) => {
  const getPathType = () => {
    const pathName = location.pathname

    if (isMarketplaceRoute(pathName)) {
      for (const mapping of pathToSearchFilterMap) {
        if (pathName.indexOf(mapping.path) !== -1) {
          return globalSearchOption?.find((x) => x.id === mapping.type)
        }
      }
      return globalSearchOption?.find(
        (x) => x.id === GlobalSearchFilterTypeEnum.PID
      )
    }
  }

  return getPathType()
}

export const globalSearchOptions = (
  userPermissions: UserPermissions
): GlobalSearch => {
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const options: GlobalSearchOption[] = []

  options.push(
    { id: GlobalSearchFilterTypeEnum.ALL, label: 'All' },
    { id: GlobalSearchFilterTypeEnum.PID, label: 'PID' },
    { id: GlobalSearchFilterTypeEnum.ACCOUNT_ID, label: 'Account ID' },
    { id: GlobalSearchFilterTypeEnum.POST_SALE_ID, label: 'Post-Sale ID' },
    { id: GlobalSearchFilterTypeEnum.COMPLAINT_ID, label: 'Complaint ID' },
    {
      id: GlobalSearchFilterTypeEnum.FORWARD_FLOW_ID,
      label: 'Forward Flow ID',
    },
    {
      id: GlobalSearchFilterTypeEnum.POST_SALE_FUNDING_ID,
      label: 'Post-Sale Funding ID',
    },
    { id: GlobalSearchFilterTypeEnum.SELLER_NAME, label: 'Seller name' },
    { id: GlobalSearchFilterTypeEnum.BUYER_NAME, label: 'Buyer name' }
  )

  if (isInternal) {
    options.push({
      id: GlobalSearchFilterTypeEnum.SELLER_UPLOAD_TEMPLATE,
      label: 'Seller Upload Template',
    })
  }

  return {
    options,
    defaultOption: options.find((x) => x.id === GlobalSearchFilterTypeEnum.PID),
  }
}

export const globalSearchOnSearch = (search: any, history: any) => {
  history.push(
    getStandardUri(
      `${SEARCH}/${encodeURIComponent(search.query)}/${search.filter.id}/${
        search.numberOfRecords
      }/0`
    )
  )
}
