/* eslint-disable react/require-default-props */
import React from 'react'
import { Box, Tooltip, Typography, Skeleton } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { PortfolioUrgency } from 'src/graphql/models/Portfolio'
import { dynamicColors } from 'src/utils/common'
import { LineChart } from 'src/components/Charts'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { Colors, Icon } from 'everchain-uilibrary'

interface PortfolioUrgencyGraphicProps {
  dataChat?: PortfolioUrgency[]
  loading: boolean
  onRequestCategoryChange: (selectedCategories: string[]) => void
  requestCategorySelected: string[]
  urgencyScore?: number
}

const PortfolioUrgencyGraphicComponent = ({
  dataChat,
  loading,
  onRequestCategoryChange,
  requestCategorySelected,
  urgencyScore,
}: PortfolioUrgencyGraphicProps) => {
  const portfolioUrgencyDataReduce = (data: PortfolioUrgency[] = []) => {
    const defaultData: any = {
      labels: [],
      datasets: [],
    }

    if (data && data.length) {
      return data.reduce((acc: any, curr: any, index) => {
        const datasets = [...acc.datasets]
        const labels = [...acc.labels]

        curr.data.forEach((item: any) => {
          if (!labels.includes(item.key.split(';')[0])) {
            labels.push(item.key.split(';')[0])
          }
        })

        datasets[index] = {
          ...datasets[index],
          data: curr.data.map((item: any) => {
            return item.value
          }),
          label: curr.title,
          fill: false,
          pointStyle: 'circle',
          backgroundColor: getColorBorder(curr.title),
          borderColor: getColorBorder(curr.title),
          trendlineLinear: {
            style: getColorBorder(curr.title),
            lineStyle: 'dotted',
            width: 2,
          },
        }
        return { labels, datasets }
      }, defaultData)
    }
    return defaultData
  }
  const getColorBorder = (item: any) => {
    if (item === 'All (Info, Buyback, Direct-Pay)') return Colors.blue
    if (item === 'Buyback') {
      return Colors.error
    }
    if (item === 'Direct-Pay') return Colors.primary

    if (item === 'Info') return Colors.success
    return dynamicColors({
      min: 0,
      max: 245,
      opacity: 0.8,
    })
  }
  const lineOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems: any[]) => {
            const xAxisLabel = tooltipItems[0]?.label?.split(';')[0]
            return xAxisLabel ? `${xAxisLabel}` : ''
          },
          label: (tooltipItem: any) => {
            // @ts-ignore
            const label = tooltipItem?.formattedValue || ''
            return `${label}`
          },
        },
      },
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    scales: {
      xAxes2: {
        type: 'category',
        offset: true,
        grid: {
          drawOnChartArea: false,
          display: false,
          drawBorder: false,
        },
        title: {
          display: true,
          text: 'PID in Chronological Order',
        },
      },
      yAxes: {
        beginAtZero: true,
        min: 0,
        title: {
          display: true,
          text: 'Days',
        },
      },
    },
  }
  const renderTypeRequestSelect = () => {
    const requestCategorySelect = ['All', 'Direct-Pay', 'Buyback', 'Info']

    const handleChangeRequestCategory = (selectedCategories: string[]) => {
      onRequestCategoryChange(selectedCategories)
    }
    return (
      <MultipleSelectionDropDown
        label="Request Category"
        disable={false}
        data={requestCategorySelect}
        handleSelectionChange={handleChangeRequestCategory}
        selectionState={requestCategorySelected}
      />
    )
  }
  if (loading) {
    return (
      <Box>
        <Box p={2}>
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      <Box p={3}>
        <Typography>
          Post-Sale Urgency
          <Tooltip
            placement="bottom"
            title="This metric evaluates the speed at which a creditor responds to post-sale requests. A faster response time indicates that the creditor is proactive in addressing issues and maintaining a strong partnership."
          >
            <Icon
              name="InfoOutlined"
              style={{ fontSize: 'medium' }}
              color="black"
            />
          </Tooltip>
        </Typography>
      </Box>
      {/* <Box p={1}>
        <DTSummaryGridContent>
          <DTSummaryBlocks>
            <DTSummaryBlockItem>
              <DTSummaryBlockItemValue>{urgencyScore}</DTSummaryBlockItemValue>
              <DTSummaryBlockItemHeader>Urgency Score</DTSummaryBlockItemHeader>
            </DTSummaryBlockItem>
          </DTSummaryBlocks>
        </DTSummaryGridContent>
      </Box> */}
      <Box p={3}>{renderTypeRequestSelect()}</Box>
      <Box height={450} position="relative" width="100%" component="div" p={3}>
        <LineChart
          data={portfolioUrgencyDataReduce(dataChat)}
          options={lineOptions}
        />
      </Box>
    </Box>
  )
}

export default PortfolioUrgencyGraphicComponent
