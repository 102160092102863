/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  Menu,
  Dialog,
  DialogContent,
  IconButton,
  Autocomplete,
} from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  GET_BULK_RESPONSE_ACCOUNTS,
  GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID,
  GET_PORTFOLIO_BUYERS,
} from 'src/graphql/operations/queries/postSale'
import {
  BulkResponseQuery,
  PortfolioNumberResponse,
  ZipFilesBlobUrisResponse,
} from 'src/graphql/models/PostSale'
import {
  ConvertDateToUTC,
  formatDate,
  formatDateAndTimeCountry,
  MomentDateFormat,
} from 'src/utils/date'
import { BusinessInfo, BusinessInfoResponse } from 'src/graphql/models/Business'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useFormik } from 'formik'
import { saveAs } from 'file-saver'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom'
import { ConfirmDialog } from 'src/components/Dialogs'
import { capitalize } from 'src/utils/text'
import { ACCOUNT_DETAIL, PORTFOLIO_DETAIL, POST_SALE_DETAIL } from 'src/routes'
import { Cookies } from 'react-cookie'
import {
  filesAccepted,
  formatDataToExport,
  getStandardUri,
} from 'src/utils/common'
import { TdNoEllipsis } from 'src/styles/layout'

import moment from 'moment'
import { renderNumber } from 'src/utils/formatKendoColumns'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { BulkUploadSection, Content, Header, ModalHeader, Tr } from './styles'
import { RequestType, RequestTypeEnum, RequestTypeList } from '../RequestType'
import { ActivePostSaleStatusList, PostSaleStatusList } from '../PostSaleStatus'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getMyPortfoliosSellers } from 'src/data/features/get/portfolio/portfolio'
import {
  getZipFileBlobUri,
  saveBulkRequestFilesAttachment,
} from 'src/data/features/post/postSale/postSale'
import {
  DataTable,
  DataTableState,
  ExportExcelButton,
  Icon,
} from 'everchain-uilibrary'
import { useMutation } from '@tanstack/react-query'

interface FilesToUpload {
  id: number
  files: File[]
}

interface DescriptionToUpload {
  [id: number]: {
    text: string | null
  }
}

const descriptionDropdownOptions = [
  'Transaction History',
  'Loan Agreement',
  'Loan Application',
  'Title/Lien',
]

const cookies = new Cookies()
const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')
const MAX_SIZE_FILE = 104857600
const BatchAttachmentMain: React.FC = () => {
  const [error, setError] = useState<any>()
  const [initialValues] = useState<any>({
    statuses: ActivePostSaleStatusList,
  })
  const { enqueueSnackbar } = useSnackbar()
  const [accountsSelected, setAccountsSelected] = useState<number[]>([])
  const [totalDataSize, setTotalDataSize] = useState<number>(25)
  const [requestsData, setRequestsData] = useState<any[]>([])
  const [descriptionSelected, setDescriptionSelected] = useState<string>('')
  const [files, setFiles] = useState<FilesToUpload[] | null>(null)
  const [description, setDescription] = useState<
    DescriptionToUpload | undefined
  >(undefined)
  const [createAttachmentLoading, setCreateAttachmentLoading] =
    useState<boolean>(false)
  const { userPermissions, profileBusiness, profileClient } =
    useContext(AuthContext)
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showModal, setShowModal] = useState(false)
  const [zipBlobList, setZipBlobList] = useState<ZipFilesBlobUrisResponse[]>()
  const [loadingZipFilesBlob, setLoadingZipFilesBlob] = useState<boolean>(false)

  const isUkCountry = () => {
    const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
    return country.toUpperCase() === 'UK'
  }
  const isInternal = userPermissions.type === 'internal'
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'

  const { data: sellerList, isFetching: loadingSellerList } = useCustomQuery<
    BusinessInfo[]
  >(['getMyPortfoliosSellers'], async () => getMyPortfoliosSellers(), {
    enabled: true,
    cacheTime: 0,
  })

  const [getBuyers, { data: buyersList, loading: loadingBuyerList }] =
    useLazyQuery<BusinessInfoResponse>(GET_PORTFOLIO_BUYERS)

  const [
    getPortfolios,
    { data: portfolioList, loading: loadingPortfolioList },
  ] = useLazyQuery<PortfolioNumberResponse>(
    GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID
  )

  const validate = (values: any) => {
    const errors: any = {}
    if (isSeller && !values.sellerSelected) {
      errors.sellerSelected = 'Required'
    }
    if (isBuyer && !values.buyerSelected) {
      errors.buyerSelected = 'Required'
    }
    if (!values.requestTypeSelected) {
      errors.requestTypeSelected = 'Required'
    }
    return errors
  }

  const formCollection = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      handleFilterClick()
      setSubmitting(false)
    },
  })

  useEffect(() => {
    setError(formCollection.errors)
  }, [error, formCollection.errors])

  const [getRequests, { data: requestsQuery, loading: loadingAccounts }] =
    useLazyQuery<BulkResponseQuery>(GET_BULK_RESPONSE_ACCOUNTS, {
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
        kendoPagination: JSON.stringify(gridState),
      },
      fetchPolicy: 'no-cache',
    })

  const getZipFileBlobUris = useMutation({
    mutationFn: (requestsIds: number[]) => {
      setLoadingZipFilesBlob(true)
      return getZipFileBlobUri(requestsIds)
    },
    onSuccess: (data) => {
      setLoadingZipFilesBlob(false)
      setZipBlobList(data)
      if (data !== undefined) {
        if (data?.length === 1) {
          saveFiles(data)
        } else if (data?.length > 1) {
          setShowModal(!showModal)
        } else {
          enqueueSnackbar(
            'There was no file found to be downloaded with the selected requests',
            notifyError
          )
        }
      }
    },
    onError: () => {
      setLoadingZipFilesBlob(false)
    },
  })

  useEffect(() => {
    formCollection.validateForm()
  }, [])

  useEffect(() => {
    getBuyers({
      variables: {
        sellerId: '',
      },
    })
  }, [getBuyers])

  useEffect(() => {
    if (
      buyersList?.businesses &&
      buyersList?.businesses?.length > 0 &&
      isBuyer
    ) {
      const profile = buyersList.businesses.filter((x) =>
        profileBusiness.filter((y) => String(y.Id) === x.id)
      )
      if (profile.length > 0) handleBuyerSelected(profile[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyersList, formCollection.values.requestTypeSelected])

  useEffect(() => {
    if (sellerList && sellerList?.length > 0 && isSeller) {
      const profile = sellerList.filter((x) =>
        profileBusiness.filter((y) => String(y.Id) === x.id)
      )
      if (profile.length > 0) handleSellerSelected(profile[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerList, formCollection.values.requestTypeSelected])

  useEffect(() => {
    setRequestsData(requestsQuery?.requests?.requestDetailsResponse || [])
  }, [requestsQuery])

  useEffect(() => {
    setTotalDataSize(requestsQuery?.requests?.total ?? 25)
  }, [requestsQuery])

  const handleUploadFileRemove = (index: number, id: number) => {
    if (files) {
      const existFiles = [...files]

      existFiles.find((c) => c.id === id)?.files.splice(index, 1)

      if (existFiles.find((c) => c.id === id)?.files.length === 0) {
        const indexItem = existFiles.findIndex((c) => c.id === id)
        existFiles.splice(indexItem, 1)
      }

      setFiles(existFiles)
    }
  }

  const handleFilterClick = () => {
    getRequests({
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: formCollection.values.sellerSelected?.id,
        buyerId: formCollection.values.buyerSelected?.id,
        pId: formCollection.values.pidSelected,
        last4SSN: formCollection.values.lastSSN,
        batchAttachment: true,
        accountIds: formCollection.values.accountIds,
        statuses: formCollection.values.statuses,
      },
    })
  }

  const handleChecked = (props: any) => {
    if (
      accountsSelected.find((id) => id === props.dataItem['id']) !== undefined
    )
      return true

    return false
  }

  const momentFormat = MomentDateFormat(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notifyError)
  }
  const now = new Date()
  const handleDroppedFiles = (acceptedFiles: File[], id: number) => {
    const filesToBeUploaded = {
      id,
      files: acceptedFiles,
    }
    if (files === null) {
      setFiles([filesToBeUploaded])
    } else if (files.findIndex((i) => i.id === id) === -1) {
      setFiles([...files, filesToBeUploaded])
    } else {
      const existFiles = files

      if (existFiles) {
        acceptedFiles.map((f) => {
          return existFiles.find((c) => c.id === id)?.files.push(f)
        })
      }

      setFiles([...existFiles])
    }
  }

  const handleDescriptionChange = (value: string | null, id: number) => {
    setDescription((prevDescriptions) => {
      if (!prevDescriptions) {
        prevDescriptions = {}
      }
      if (value === null || value === undefined || value === '') {
        // eslint-disable-next-line no-unused-vars
        const { [id]: removedItem, ...rest } = prevDescriptions
        return Object.keys(rest).length > 0 ? rest : undefined
      }
      if (prevDescriptions && prevDescriptions[id]?.text === value) {
        return prevDescriptions
      }

      return {
        ...prevDescriptions,
        [id]: { text: value },
      }
    })
  }

  const GridColumns: any[] = [
    {
      title: '#',
      width: 55,
      show: true,
      notFilterable: true,
      render: (props: any) => {
        return (
          <TdNoEllipsis>
            <Checkbox
              value={props.dataItem['id']}
              onChange={(event) => {
                if (event.target.checked) {
                  setAccountsSelected([
                    ...accountsSelected,
                    props.dataItem['id'],
                  ])
                } else {
                  setAccountsSelected(
                    accountsSelected.filter((id) => id !== props.dataItem['id'])
                  )
                }
              }}
              checked={handleChecked(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'id',
      title: 'ID',
      render: (props: any) => {
        return (
          <td>
            <Link
              onClick={() => {
                cookies.set('postsaleformCollection', formCollection)
                cookies.set('postsaleSellectAccounts', accountsSelected)
              }}
              to={getStandardUri(`${POST_SALE_DETAIL}/${props.dataItem['id']}`)}
            >
              {props.dataItem['id']}
            </Link>
          </td>
        )
      },
      width: 70,
      show: true,
      filter: 'numeric',
      editable: false,
    },
    {
      field: 'lender',
      title: 'Lender',
      filter: 'text',
      show: true,
      width: 100,
      editable: false,
      render: (props: any) => {
        return (
          <td title={props.dataItem['lender']}>{props.dataItem['lender']}</td>
        )
      },
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      show: true,
      editable: false,
      filter: 'text',
      width: 120,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'status',
      title: 'Status',
      filter: 'text',
      show: true,
      editable: false,
      width: 110,
      render: (props: any) => {
        return (
          <td title={capitalize(props.dataItem['status'])}>
            {capitalize(props.dataItem['status'])}
          </td>
        )
      },
    },
    {
      field: 'lastUploaded',
      title: 'Last Uploaded',
      show: true,
      filter: 'date',
      editable: false,
      width: 100,
      render: (props: any) => {
        return (
          <>
            {props.dataItem['lastUploaded'] !== null ? (
              <td>
                {moment(
                  new Date(ConvertDateToUTC(props.dataItem['lastUploaded']))
                )?.format(momentFormat)}
              </td>
            ) : (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <td />
            )}
          </>
        )
      },
    },
    {
      field: 'numberOfFiles',
      title: 'No. of Files',
      filter: 'numeric',
      show: true,
      render: renderNumber,
      editable: false,
      width: 100,
    },
    {
      field: 'lastName',
      title: 'Last Name',
      filter: 'text',
      show: !isInternal,
      editable: false,
      width: 110,
    },
    {
      field: 'firstName',
      title: 'First Name',
      filter: 'text',
      show: !isInternal,
      editable: false,
      width: 110,
    },
  ]

  const descriptionColumn = (): any => {
    return {
      field: 'uploadDescription',
      title: 'Description',
      width: 180,
      show: true,
      render: (props: any) => {
        if (
          props.dataItem.status &&
          props.dataItem.status.toLowerCase() !== 'closed'
        ) {
          return (
            <td>
              <Autocomplete
                style={{ zIndex: 3 }}
                freeSolo
                fullWidth
                onChange={(event: any) => {
                  const selectedValue =
                    event.target.textContent || event.target.Value
                  handleDescriptionChange(selectedValue, props.dataItem['id'])
                }}
                options={descriptionDropdownOptions}
                value={description?.[props.dataItem['id']]?.text ?? ''}
                onClose={(event: any) => {
                  const selectedValue =
                    event.target.textContent || event.target.value
                  handleDescriptionChange(selectedValue, props.dataItem['id'])
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Select or enter description"
                    error={
                      !!files?.find((f) => f.id === props.dataItem['id']) &&
                      !description?.[props.dataItem['id']]?.text
                    }
                    helperText={
                      !!files?.find((f) => f.id === props.dataItem['id']) &&
                      !description?.[props.dataItem['id']]?.text
                        ? 'Required'
                        : ''
                    }
                  />
                )}
              />
            </td>
          )
        }
        return <></>
      },
    }
  }

  const uploadColumn = (smallSize?: boolean): any => {
    return {
      field: 'uploadedOnUtc',
      title: 'Upload',
      width: 130,
      show: true,
      render: (props: any) => {
        const field = props.field == null ? '' : props.field || ''
        if (
          props.dataItem.status &&
          props.dataItem.status.toLowerCase() !== 'closed'
        ) {
          return (
            <td>
              {props.dataItem[field] == null ? (
                <UploadDragDrop
                  size={smallSize ? 'small' : 'large'}
                  linkText="Upload"
                  text=""
                  accept={filesAccepted}
                  multiple={true}
                  onDropRejected={handleFileRejected}
                  maxSize={MAX_SIZE_FILE}
                  onDrop={(acceptedFiles) => {
                    let filesCount = files?.find(
                      (x) => x.id === props.dataItem['id']
                    )?.files.length

                    if (!filesCount) filesCount = 0
                    if (acceptedFiles.length + filesCount <= 100)
                      handleDroppedFiles(acceptedFiles, props.dataItem['id'])
                    else
                      enqueueSnackbar(
                        'The file limit is 100 files.',
                        notifyError
                      )
                  }}
                />
              ) : (
                formatDateAndTimeCountry(
                  props.dataItem[field],
                  profileClient?.Country || process.env.REACT_APP_COUNTRY
                )
              )}
            </td>
          )
        }
        return <></>
      },
    }
  }
  const filesToUploadColumn = (): any => {
    return {
      field: 'filesToUpload',
      title: 'Files to Upload',
      width: 130,
      show: true,
      render: (props: any) => {
        const field = props.field == null ? '' : props.field || ''
        if (
          props.dataItem.status &&
          props.dataItem.status.toLowerCase() !== 'close'
        ) {
          return (
            <td>
              {props.dataItem[field] != null || files == null ? (
                ''
              ) : files.findIndex((f) => f.id === props.dataItem['id']) ===
                -1 ? (
                ''
              ) : (
                <Box display="flex" flexDirection="column">
                  {files &&
                    files != null &&
                    files.findIndex((f) => f.id === props.dataItem['id']) !==
                      -1 &&
                    files
                      .filter((ft) => ft.id === props.dataItem['id'])[0]
                      .files.map((file: any, idx: number) => (
                        <>
                          <Box key={idx + file.name}>
                            <IconButton
                              onClick={() => {
                                handleUploadFileRemove(
                                  idx,
                                  props.dataItem['id']
                                )
                              }}
                            >
                              <Icon
                                name="Delete"
                                className="upload-item-icon"
                                fontSize="small"
                              />
                            </IconButton>
                            <Box component="div">
                              <Tr title={file.name}>{file.name}</Tr>
                            </Box>
                          </Box>
                        </>
                      ))}
                </Box>
              )}
            </td>
          )
        }
        return <></>
      },
    }
  }

  const getUploadGroupColumns = (smallSize?: boolean): any[] => {
    return [descriptionColumn(), uploadColumn(smallSize), filesToUploadColumn()]
  }

  const getGridColumns = (): any[] => {
    return getGridColumnsByType().concat([
      {
        field: 'portfolioNumber',
        title: 'PID',
        show: true,
        width: 80,
        filter: 'text',
        render: (props: any) => {
          return (
            <td>
              <Link
                onClick={() => {
                  cookies.set('postsaleformCollection', formCollection)
                  cookies.set('postsaleSellectAccounts', accountsSelected)
                }}
                to={getStandardUri(
                  `${PORTFOLIO_DETAIL}/${props.dataItem['portfolioId']}`
                )}
              >
                {props.dataItem['portfolioNumber']}
              </Link>
            </td>
          )
        },
      },
      {
        field: 'portfolioRowGuid',
        title: 'ECAID',
        show: true,
        width: 250,
        filter: 'text',
      },
    ])
  }
  const getGridColumnsByType = (): any[] => {
    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Bankrupt
    ) {
      return GridColumns.concat([
        {
          field: 'caseNumber',
          title: 'Case Number',
          filter: 'text',
          width: 110,
          show: true,
          editable: false,
        },
        {
          field: 'chapter',
          title: 'Chapter',
          filter: 'numeric',
          width: 110,
          show: true,
          editable: false,
        },
        {
          field: 'fileDate',
          title: 'File Date',
          filter: 'date',
          width: 100,
          editor: 'date',
          show: true,
          editable: false,
          render: (props: any) => {
            return (
              <td title={formatDate(props.dataItem['fileDate'])}>
                {formatDate(props.dataItem['fileDate'])}
              </td>
            )
          },
        },
        {
          field: 'attorneyName',
          filter: 'text',
          title: isUkCountry() ? 'Attourney Name' : 'Attorney Name',
          show: true,
          editable: false,
          width: 110,
        },
        {
          field: 'attorneyPhone',
          filter: 'text',
          title: isUkCountry() ? 'Attourney Phone' : 'Attorney Phone',
          width: 110,
          show: true,
          editable: false,
        },
        {
          field: 'district',
          title: 'District',
          filter: 'text',
          width: 110,
          show: true,
          editable: false,
        },
        ...getUploadGroupColumns(true),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Deceased
    ) {
      return GridColumns.concat([
        {
          field: 'dateOfDeath',
          title: 'Date Of Death',
          filter: 'date',
          show: true,
          editable: false,
          width: 100,
          editor: 'date',
          render: (props: any) => {
            return (
              <td title={formatDate(props.dataItem['dateOfDeath'])}>
                {formatDate(props.dataItem['dateOfDeath'])}
              </td>
            )
          },
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.DirectPay
    ) {
      return GridColumns.concat([
        {
          field: 'paymentDate',
          title: 'Payment Date',
          filter: 'date',
          width: 100,
          editor: 'date',
          show: true,
          editable: false,
          render: (props: any) => {
            return (
              <td title={formatDate(props.dataItem['paymentDate'])}>
                {formatDate(props.dataItem['paymentDate'])}
              </td>
            )
          },
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          filter: 'numeric',
          show: true,
          editable: false,
        },
        {
          field: 'consumerCheckOrReferenceNumber',
          title: isUkCountry()
            ? 'Consumer Cheque'
            : 'Consumer Check/Ref. Number',
          width: 170,
          filter: 'text',
          show: true,
          editable: false,
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Info
    ) {
      return GridColumns.concat([
        {
          field: 'infoRequested',
          title: 'Info Requested',
          filter: 'text',
          width: 110,
          show: true,
          editable: false,
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Legal ||
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Other ||
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.Fraud
    ) {
      return GridColumns.concat([
        {
          field: 'explanation',
          title: 'Explanation',
          filter: 'text',
          width: 120,
          show: true,
          editable: false,
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.PaidPrior
    ) {
      return GridColumns.concat([
        {
          field: 'paidPriorDate',
          filter: 'date',
          title: 'Paid Prior Date',
          width: 100,
          editor: 'date',
          show: true,
          editable: false,
          render: (props: any) => {
            return (
              <td title={formatDate(props.dataItem['paidPriorDate'])}>
                {formatDate(props.dataItem['paidPriorDate'])}
              </td>
            )
          },
        },
        {
          field: 'paidPriorType',
          filter: 'text',
          title: 'Paid Prior Type',
          show: true,
          editable: false,
          width: 100,
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.LienLoss
    ) {
      return GridColumns.concat([
        {
          field: 'lienLossDate',
          title: 'Lien Loss Date',
          filter: 'date',
          width: 100,
          editor: 'date',
          show: true,
          editable: false,
          render: (props: any) => {
            return <td>{formatDate(props.dataItem['lienLossDate'])}</td>
          },
        },
        {
          field: 'explanation',
          title: 'Explanation',
          filter: 'text',
          width: 110,
          show: true,
          editable: false,
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.InaccurateData
    ) {
      return GridColumns.concat([
        {
          field: 'inaccurateDataInfo',
          title: 'Inaccurate Data Info',
          filter: 'text',
          width: 100,
          show: true,
          editable: false,
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.ScraMilitary
    ) {
      return GridColumns.concat([
        {
          field: 'militaryBranch',
          title: 'Military Branch',
          filter: 'text',
          width: 100,
          show: true,
          editable: false,
        },
        {
          field: 'activeDutyStartDate',
          title: 'Active Duty Start Date',
          filter: 'date',
          width: 100,
          editor: 'date',
          show: true,
          editable: false,
          render: (props: any) => {
            return <td>{formatDate(props.dataItem['activeDutyStartDate'])}</td>
          },
        },
        {
          field: 'activeDutyEndDate',
          title: 'Active Duty End Date',
          filter: 'date',
          width: 100,
          editor: 'date',
          show: true,
          editable: false,
          render: (props: any) => {
            return (
              <td>
                {props.dataItem['activeDutyEndDate']
                  ? formatDate(props.dataItem['activeDutyEndDate'])
                  : 'N/A'}
              </td>
            )
          },
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id ===
      RequestTypeEnum.DismissedDischarged
    ) {
      return GridColumns.concat([
        {
          field: 'type',
          title: 'Type (Dismissal/Discharge)',
          filter: 'text',
          width: 100,
          show: true,
          editable: false,
        },
        {
          field: 'dateOfDismissalDischarge',
          title: 'Date Of Dismissal/Discharge',
          width: 100,
          editor: 'date',
          filter: 'date',
          show: true,
          editable: false,
          render: (props: any) => {
            return (
              <td>{formatDate(props.dataItem['dateOfDismissalDischarge'])}</td>
            )
          },
        },
        ...getUploadGroupColumns(),
      ])
    }

    if (
      formCollection.values.requestTypeSelected?.Id === RequestTypeEnum.PifSif
    ) {
      return GridColumns.concat([
        {
          field: 'paymentDate',
          title: 'Payment Date',
          filter: 'date',
          width: 100,
          editor: 'date',
          show: true,
          editable: false,
          render: (props: any) => {
            return (
              <td>
                {props.dataItem['paymentDate']
                  ? formatDate(props.dataItem['paymentDate'])
                  : ''}
              </td>
            )
          },
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          filter: 'numeric',
          width: 150,
          show: true,
          editable: false,
        },
        {
          field: 'consumerCheckOrReferenceNumber',
          filter: 'text',
          title: isUkCountry()
            ? 'Consumer Cheque'
            : 'Consumer Check/Ref. Number',
          width: 170,
          show: true,
          editable: false,
        },
        {
          field: 'locationOrStore',
          title: 'Location/Store',
          filter: 'text',
          width: 150,
          show: true,
          editable: false,
        },
        {
          field: 'consumerAddress',
          title: 'Consumer Address',
          filter: 'text',
          width: 150,
          show: !isInternal,
          editable: false,
        },
        {
          field: 'consumerCity',
          filter: 'text',
          title: 'City',
          width: 150,
          show: true,
          editable: false,
        },
        {
          field: 'consumerState',
          title: isUkCountry() ? 'County' : 'State',
          width: 150,
          show: true,
          filter: 'text',
          editable: false,
        },
        {
          field: 'consumerPostalCode',
          title: 'Postal Code',
          filter: 'text',
          width: 150,
          show: true,
          editable: false,
        },
        {
          field: 'consumerPhoneNumber',
          title: 'Phone Number',
          filter: 'text',
          width: 150,
          show: !isInternal,
          editable: false,
        },
        ...getUploadGroupColumns(),
      ])
    }

    return GridColumns
  }

  const checkDescriptionFiles = () => {
    if (files) {
      for (const { id } of files) {
        if (!description?.hasOwnProperty(id)) {
          return false
        }
      }
    }

    return true
  }

  const handleUpload = async () => {
    var filesHasDescription = checkDescriptionFiles()
    if (!filesHasDescription) {
      enqueueSnackbar('There are rows without description', notifyError)
      return
    }

    setCreateAttachmentLoading(true)
    let allUploadsSuccessful = true

    const uploadPromises = files?.map(async (file: FilesToUpload) => {
      const descriptionItem = description?.[file.id]?.text

      try {
        if (file.files.length > 5) {
          for (let i = 0; i < file.files.length; i += 5) {
            const chunk = file.files.slice(i, i + 5).map((x) => ({
              file: x,
              description: descriptionItem ?? undefined,
            }))

            await saveBulkRequestFilesAttachment({
              requestId: file.id,
              requestFileAttachments: chunk,
            })
          }
        } else {
          await saveBulkRequestFilesAttachment({
            requestId: file.id,
            requestFileAttachments: file.files.map((x) => ({
              file: x,
              description: descriptionItem ?? undefined,
            })),
          })
        }
      } catch (e) {
        allUploadsSuccessful = false
        console.error(`Error uploading file: ${file.id}`, e)
      }
    })

    if (uploadPromises) {
      await Promise.all(uploadPromises)
    }
    if (allUploadsSuccessful) {
      enqueueSnackbar('All files uploaded', notifySuccess)
    } else {
      enqueueSnackbar('Some files failed to upload', notifyError)
    }

    setCreateAttachmentLoading(false)
    handleFilterClick()
    setFiles([])
    setDescription(undefined)
  }

  const clearCookies = () => {
    cookies.remove('postsaleformCollection')
    cookies.remove('postsaleSellectAccounts')
  }
  const handleRequestSelected = (type: RequestType) => {
    formCollection.setFieldValue('requestTypeSelected', type)

    getRequests({
      variables: {
        requestTypeId: type.Id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: formCollection.values.sellerSelected?.id,
        buyerId: formCollection.values.buyerSelected?.id,
        pId: formCollection.values.pidSelected,
        last4SSN: formCollection.values.lastSSN,
        batchAttachment: true,
        accountIds: formCollection.values.accountIds,
        statuses: formCollection.values.statuses,
      },
    })
  }

  const handleDownload = () => {
    onDownload()
  }

  const onDownload = () => {
    if (accountsSelected.length > 0) {
      getZipFileBlobUris.mutate(accountsSelected)
    } else {
      enqueueSnackbar(
        'Please select at least one request to download',
        notifyError
      )
    }
  }

  const handleBuyerSelected = (business: BusinessInfo) => {
    formCollection.setFieldValue('buyerSelected', business)
    if (formCollection.values.sellerSelected) {
      getPortfolios({
        variables: {
          sellerId: formCollection.values.sellerSelected?.id,
          buyerId: business.id,
        },
      })
    }

    getRequests({
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: formCollection.values.sellerSelected?.id,
        buyerId: business.id,
        pId: formCollection.values.pidSelected,
        last4SSN: formCollection.values.lastSSN,
        batchAttachment: true,
        accountIds: formCollection.values.accountIds,
        statuses: formCollection.values.statuses,
      },
    })
  }

  const handleSellerSelected = (business: BusinessInfo) => {
    formCollection.setFieldValue('buyerSelected', undefined)
    formCollection.setFieldValue('pidSelected', undefined)
    formCollection.setFieldValue('accountIds', undefined)
    formCollection.setFieldValue('sellerSelected', business)
    setAccountsSelected([])

    getPortfolios({
      variables: {
        sellerId: business.id,
      },
    })

    getBuyers({
      variables: {
        sellerId: business.id,
      },
    })
    getRequests({
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.Id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: business.id,
        buyerId: formCollection.values.buyerSelected?.id,
        pId: formCollection.values.pidSelected,
        last4SSN: formCollection.values.lastSSN,
        batchAttachment: true,
        accountIds: formCollection.values.accountIds,
        statuses: formCollection.values.statuses,
      },
    })
  }

  const handlePidSelected = (portfolioNumber: number) => {
    formCollection.setFieldValue('pidSelected', portfolioNumber)
    setAccountsSelected([])
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleSelect(event?.currentTarget?.textContent)
  }

  const handleSelect = (e: any) => {
    if (e === 'Select all rows (current page)') {
      if (requestsData?.length > 0) {
        setAccountsSelected(requestsData.map((account: any) => account.id))
      }
    }

    if (e === 'Unselect all rows (current page)') {
      setAccountsSelected([])
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    const postsaleformCollection = cookies.get('postsaleformCollection')

    if (postsaleformCollection) {
      formCollection.setFieldValue(
        'lastSSN',
        postsaleformCollection.values.lastSSN
      )
      formCollection.setFieldValue(
        'requestTypeSelected',
        postsaleformCollection.values.requestTypeSelected
      )
      formCollection.setFieldValue(
        'accountIds',
        postsaleformCollection.values.accountIds
      )
      formCollection.setFieldValue(
        'sellerSelected',
        postsaleformCollection.values.sellerSelected
      )
      formCollection.setFieldValue(
        'buyerSelected',
        postsaleformCollection.values.buyerSelected
      )
      formCollection.setFieldValue(
        'pidSelected',
        postsaleformCollection.values.pidSelected
      )
      formCollection.setFieldValue(
        'statuses',
        postsaleformCollection.values.statuses
      )

      getRequests({
        variables: {
          requestTypeId: postsaleformCollection.values.requestTypeSelected?.Id,
          kendoPagination: JSON.stringify(gridState),
          sellerId: postsaleformCollection.values.sellerSelected?.id,
          buyerId: postsaleformCollection.values.buyerSelected?.id,
          pId: postsaleformCollection.values.pidSelected,
          last4SSN: postsaleformCollection.values.lastSSN,
          batchAttachment: true,
          accountIds: formCollection.values.accountIds,
          statuses: formCollection.values.statuses,
        },
      })

      cookies.remove('postsaleformCollection')
    }
  }, [])
  useEffect(() => {
    const postsaleSellectAccounts = cookies.get('postsaleSellectAccounts')
    if (requestsData?.length > 0 && postsaleSellectAccounts) {
      setAccountsSelected(postsaleSellectAccounts)
      cookies.remove('postsaleSellectAccounts')
    }
  }, [requestsData])

  const onDownloadConfirmedFiles = () => {
    if (zipBlobList && zipBlobList.length > 0) {
      saveFiles(zipBlobList)
      setShowModal(!showModal)
      enqueueSnackbar('Files downloaded successfully', notifySuccess)
    } else {
      enqueueSnackbar(
        'Something went wrong when fecthing the files. Please try again later.',
        notifyError
      )
    }
  }

  const saveFiles = (fileList: any) => {
    fileList?.forEach((zipFileBlob: ZipFilesBlobUrisResponse) => {
      saveAs(zipFileBlob.zipBlobUri, `rid_${zipFileBlob.requestId}`)
    })
  }

  const location = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [lastLocation, setLastLocation] = useState(location)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const closeModal = () => {
    setIsModalOpen(false)
  }
  const history = useHistory()
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, history])

  const handleConfirmNavigationClick = () => {
    closeModal()
    setConfirmedNavigation(true)
  }
  const handleBlockedRoute = (nextLocation: any) => {
    if (!confirmedNavigation) {
      showLeaveModal(nextLocation)
      return false
    }

    return true
  }
  const openModal = () => {
    setIsModalOpen(true)
  }

  const showLeaveModal = (nextLocation: any) => {
    openModal()
    setLastLocation(nextLocation)
  }
  const handleDescriptionChangeForSelectedAccounts = (text: string) => {
    accountsSelected.forEach((account) => {
      handleDescriptionChange(text, account)
    })
  }
  const handleStatusSelected = (statuses: string[]) => {
    setAccountsSelected([])
    formCollection.setFieldValue('statuses', statuses)
  }

  return (
    <>
      <Prompt
        when={files != null && files?.length > 0}
        message={handleBlockedRoute}
      />
      <ConfirmDialog
        open={isModalOpen}
        title="Leave page"
        closeName="Cancel"
        confirmName="Yes"
        onClose={() => {
          closeModal()
        }}
        onConfirm={() => {
          handleConfirmNavigationClick()
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Typography component="div">
            Files will be lost. Do you want to leave this page?
          </Typography>
        </DialogContent>
      </ConfirmDialog>
      <form onSubmit={formCollection.handleSubmit}>
        <BulkUploadSection>
          <Header as={Content}>
            <Grid container direction="row">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h1" style={{ color: '#656565' }}>
                  Batch Attachment
                  {formCollection.values.requestTypeSelected
                    ? ` - Type: ${formCollection.values.requestTypeSelected?.Name}`
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </Header>
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="97%"
            maxHeight="95%"
          >
            <Grid
              container
              direction="column"
              style={{ width: '100%', padding: '15px' }}
            >
              <Grid item style={{ width: '100%', paddingTop: '5px' }}>
                {(loadingAccounts ||
                  loadingSellerList ||
                  loadingBuyerList ||
                  createAttachmentLoading ||
                  loadingPortfolioList ||
                  loadingZipFilesBlob) &&
                  loadingPanel}
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  paddingX={2}
                  paddingBottom={2}
                >
                  <Grid item>
                    <TextField
                      label="Request Type"
                      select
                      id="select-request-type"
                      error={!!error?.requestTypeSelected}
                      helperText={error?.requestTypeSelected}
                      value={
                        formCollection.values.requestTypeSelected?.Name ?? ''
                      }
                      style={{ minWidth: '150px' }}
                    >
                      <MenuItem
                        value=""
                        onClick={() => {
                          formCollection.setFieldValue(
                            'requestTypeSelected',
                            undefined
                          )
                          formCollection.setFieldValue(
                            'sellerSelected',
                            undefined
                          )
                          formCollection.setFieldValue(
                            'buyerSelected',
                            undefined
                          )
                          formCollection.setFieldValue('pidSelected', undefined)
                          formCollection.setFieldValue('accountIds', undefined)
                          setAccountsSelected([])
                        }}
                      >
                        <em>Select a request type</em>
                      </MenuItem>
                      {RequestTypeList(
                        profileClient?.Country || process.env.REACT_APP_COUNTRY
                      ).map((type: RequestType) => {
                        if (
                          isInternal &&
                          type.Id === RequestTypeEnum.InaccurateData
                        )
                          return undefined

                        if (isBuyer && type.Id === RequestTypeEnum.DirectPay)
                          return undefined

                        if (isSeller && type.Id === RequestTypeEnum.DirectPay)
                          return undefined

                        if (type.Id === RequestTypeEnum.PifSif) {
                          if (type.Name === 'PIF') type.Name = 'PIF/SIF'
                          else if (type.Name === 'SIF') return undefined
                        }
                        if (
                          isUkCountry() &&
                          type.Id === RequestTypeEnum.ScraMilitary
                        )
                          return undefined
                        return (
                          <MenuItem
                            key={type.Name}
                            value={type.Name}
                            onClick={() => {
                              handleRequestSelected(type)
                              formCollection.setFieldValue(
                                'sellerSelected',
                                undefined
                              )
                              formCollection.setFieldValue(
                                'buyerSelected',
                                undefined
                              )
                              formCollection.setFieldValue(
                                'pidSelected',
                                undefined
                              )
                              formCollection.setFieldValue(
                                'accountIds',
                                undefined
                              )
                              setAccountsSelected([])
                              clearCookies()
                            }}
                          >
                            {type.Name}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Seller"
                      select
                      id="select-seller"
                      error={!!error?.sellerSelected}
                      helperText={error?.sellerSelected}
                      value={formCollection.values.sellerSelected?.name ?? ''}
                      fullWidth
                      style={{ minWidth: '250px' }}
                    >
                      <MenuItem
                        value=""
                        onClick={() => {
                          formCollection.setFieldValue(
                            'sellerSelected',
                            undefined
                          )
                          formCollection.setFieldValue(
                            'buyerSelected',
                            undefined
                          )
                          formCollection.setFieldValue('pidSelected', undefined)
                          formCollection.setFieldValue('accountIds', undefined)
                          setAccountsSelected([])
                        }}
                      >
                        <em>Select seller</em>
                      </MenuItem>
                      {sellerList?.map((business) => {
                        return (
                          <MenuItem
                            key={business.id}
                            value={business.name}
                            onClick={() => {
                              handleSellerSelected(business)
                              clearCookies()
                            }}
                          >
                            {business.name}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>

                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Buyer"
                      select
                      error={!!error?.buyerSelected}
                      helperText={error?.buyerSelected}
                      id="select-buyer"
                      value={formCollection.values.buyerSelected?.name ?? ''}
                      fullWidth
                      style={{ minWidth: '250px' }}
                    >
                      <MenuItem
                        value=""
                        onClick={() => {
                          setAccountsSelected([])
                        }}
                      >
                        <em>Select buyer</em>
                      </MenuItem>
                      {buyersList?.businesses.map((business: BusinessInfo) => {
                        return (
                          <MenuItem
                            key={business.id}
                            value={business.name}
                            onClick={() => {
                              handleBuyerSelected(business)
                              clearCookies()
                            }}
                          >
                            {business.name}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>

                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="PID"
                      select
                      id="select-pid"
                      value={formCollection.values.pidSelected ?? ''}
                      fullWidth
                      style={{ minWidth: '90px' }}
                    >
                      <MenuItem
                        value=""
                        onClick={() => {
                          setAccountsSelected([])
                        }}
                      >
                        <em>Select PID</em>
                      </MenuItem>
                      {portfolioList?.portfoliosNumber.map(
                        (portfolioNumber: number) => {
                          return (
                            <MenuItem
                              key={portfolioNumber}
                              value={portfolioNumber}
                              onClick={() => {
                                handlePidSelected(portfolioNumber)
                                clearCookies()
                              }}
                            >
                              {portfolioNumber}
                            </MenuItem>
                          )
                        }
                      )}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <MultipleSelectionDropDown
                      label="Status"
                      data={PostSaleStatusList}
                      disable={false}
                      handleSelectionChange={handleStatusSelected}
                      selectionState={formCollection.values.statuses ?? []}
                    />
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  {!isUkCountry() && (
                    <>
                      <Grid item>
                        <TextField
                          label="Last 4 SSN"
                          id="select-SSN"
                          value={formCollection.values.lastSSN ?? undefined}
                          fullWidth
                          style={{ minWidth: '100px' }}
                          disabled={
                            formCollection.values.pidSelected === undefined &&
                            formCollection.values.buyerSelected === undefined &&
                            formCollection.values.sellerSelected === undefined
                          }
                          onChange={(e: any) => {
                            const numbers = /^(\d+)$/
                            if (
                              e.target.value.match(numbers) ||
                              e.target.value === ''
                            ) {
                              formCollection.setFieldValue(
                                'lastSSN',
                                e.target.value
                              )
                            }
                          }}
                          inputProps={{
                            maxLength: 4,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Divider orientation="vertical" />
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <TextField
                      label="Account IDs"
                      id="select-LoanIds"
                      value={formCollection.values.accountIds ?? undefined}
                      fullWidth
                      style={{ minWidth: '350px' }}
                      onChange={(e: any) => {
                        formCollection.setFieldValue(
                          'accountIds',
                          e.target.value
                        )
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    style={{ padding: 5, display: 'flex' }}
                  >
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Button
                        data-cy="bulk-selection-options-button"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="outlined"
                        color="primary"
                        onClick={handleClick}
                      >
                        Bulk Selection Options{' '}
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                          Select all rows (current page)
                        </MenuItem>
                        <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                          Unselect all rows (current page)
                        </MenuItem>
                      </Menu>
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Button
                        data-cy="download-post-sale-attachment-button"
                        title="Download all files"
                        variant="contained"
                        color="primary"
                        disabled={
                          !accountsSelected || accountsSelected?.length === 0
                        }
                        onClick={handleDownload}
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <ExportExcelButton
                        data={formatDataToExport(
                          requestsData.filter((request) =>
                            accountsSelected.includes(request.id)
                          ),
                          getGridColumns(),
                          profileClient
                        )}
                        fileName={`postsale-download_${now
                          .toLocaleString()
                          .replace(/[/:]/g, '-')
                          .replace(', ', '_')}`}
                        id="export-post-sale-attachment-button"
                        disabled={
                          !accountsSelected || accountsSelected?.length === 0
                        }
                      >
                        Export to Excel
                      </ExportExcelButton>
                    </Grid>
                    <Grid item>
                      <Autocomplete
                        style={{ zIndex: 3 }}
                        freeSolo
                        fullWidth
                        onChange={(event: any) => {
                          const selectedValue =
                            event.target.textContent || event.target.value
                          setDescriptionSelected(selectedValue)
                        }}
                        onClose={(event: any) => {
                          const selectedValue =
                            event.target.textContent || event.target.value
                          setDescriptionSelected(selectedValue)
                        }}
                        value={descriptionSelected}
                        options={descriptionDropdownOptions}
                        renderInput={(params: any) => (
                          <TextField
                            style={{ minWidth: '250px' }}
                            {...params}
                            placeholder="Select or enter description"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          handleDescriptionChangeForSelectedAccounts(
                            descriptionSelected
                          )
                        }
                        disabled={accountsSelected.length === 0}
                      >
                        Apply to selected requests
                      </Button>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        paddingRight: '25px',
                        gap: '10px',
                      }}
                    >
                      <Button
                        data-cy="filter-button"
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{
                          minWidth: '75px',
                        }}
                        disabled={
                          formCollection.values.requestTypeSelected ===
                          undefined
                        }
                      >
                        Filter
                      </Button>
                      <Button
                        data-cy="download-post-sale-attachment-button"
                        title="Submit"
                        variant="contained"
                        color="primary"
                        disabled={!files || files?.length === 0}
                        onClick={handleUpload}
                      >
                        Upload
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <DataTable
                  data={requestsData}
                  scrollable="scrollable"
                  sortable
                  useFilterMenu
                  skip={gridState.skip}
                  take={gridState.take}
                  pageSize={gridState.take}
                  filter={gridState.filter}
                  sort={gridState.sort}
                  pageable={{ pageSizes: [25, 50, 100, 1000, 10000] }}
                  total={totalDataSize}
                  onDataStateChange={(e) => {
                    setGridState(e.dataState)
                    getRequests({
                      variables: {
                        requestTypeId:
                          formCollection.values.requestTypeSelected?.Id,
                        kendoPagination: JSON.stringify(e.dataState),
                        sellerId: formCollection.values.sellerSelected?.id,
                        buyerId: formCollection.values.buyerSelected?.id,
                        pId: formCollection.values.pidSelected,
                        last4SSN: formCollection.values.lastSSN,
                        batchAttachment: true,
                        accountIds: formCollection.values.accountIds,
                        statuses: formCollection.values.statuses,
                      },
                    })
                  }}
                  gridColumns={getGridColumns()}
                />
              </Grid>
            </Grid>
          </Box>
          <Content>
            <Dialog maxWidth={false} open={showModal}>
              <ModalHeader
                py={3}
                px={5}
                m={0}
                bgcolor="green"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                color="white"
              >
                <Typography variant="h5" style={{ color: 'white' }}>
                  Attachment files
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => setShowModal(!showModal)}
                >
                  <Icon name="Close" color="white" />
                </IconButton>
              </ModalHeader>
              <DialogContent id="bidContent">
                <Box>
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      gap: '35px',
                    }}
                  >
                    <Typography>
                      {' '}
                      There were&nbsp;
                      {zipBlobList ? zipBlobList?.length : '0'}
                      &nbsp;zip file. Do you wish to proceed with the download?
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        gap: '3px',
                        width: '50%',
                        justifyContent: 'space-around',
                      }}
                    >
                      <Button
                        data-cy="cancel-post-sale-attachment-button"
                        title="Cancel"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowModal(!showModal)}
                      >
                        No
                      </Button>
                      <Button
                        data-cy="confirm-download-post-sale-attachment-button"
                        title="Download"
                        variant="contained"
                        color="primary"
                        onClick={onDownloadConfirmedFiles}
                      >
                        Yes
                      </Button>
                    </Box>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: '0.675rem' }}
                    >
                      *NOTE: You may need to configure your web browser to allow
                      pop-up windows to download the files.
                    </Typography>
                  </Grid>
                </Box>
              </DialogContent>
            </Dialog>
          </Content>
        </BulkUploadSection>
      </form>
    </>
  )
}

export default BatchAttachmentMain
