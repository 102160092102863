import { ChartOptions } from 'chart.js'
import { dynamicColors } from './common'
import { ChartBcoDataResponse } from 'src/graphql/models/Complaint'
import { Colors } from 'everchain-uilibrary'

export const getLineOptions = (): ChartOptions<'line'> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const label = tooltipItem?.formattedValue || ''

            if (label < 0) {
              return 'N/A - Due to low volume of accounts purchased the account complaint ratio is not calculated.'
            }
            const formattedValue = parseFloat(label).toFixed(2)
            return formattedValue === '-0.00' ? '0.00' : formattedValue
          },
        },
      },
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    scales: {
      yAxes: {
        beginAtZero: true,
        max: 1,
        min: 0,
        ticks: {
          stepSize: 0.2,
        },
      },
    },
  }
}
export const getComplianceRatiosDataReduce = (
  data: ChartBcoDataResponse[] = []
) => {
  const defaultData: any = {
    labels: [],
    datasets: [],
  }

  if (data && data.length) {
    return data.reduce((acc: any, curr: any, index) => {
      const datasets = [...acc.datasets]
      const labels = [...acc.labels]

      curr.data.forEach((item: any) => {
        if (!labels.includes(item.key)) {
          labels.push(item.key)
        }
      })

      datasets[index] = {
        ...datasets[index],
        data: curr.data.map((item: any) => {
          return item.value <= 1 ? item.value : 1
        }),
        label: curr.title,
        fill: false,
        pointStyle: 'circle',
        backgroundColor: getColorBorder(curr.title), // Assuming getColorBorder is defined somewhere
        borderColor: getColorBorder(curr.title),
      }
      return { labels, datasets }
    }, defaultData)
  }
  return defaultData
}
const getColorBorder = (item: any) => {
  if (item === 'Buyer Complaint Ratio' || item === 'Agency Complaint Ratio') {
    return Colors.blue
  }
  if (item === 'EverChain Network Complaint Ratio') return Colors.primary

  if (item === 'My Complaint Ratio') return Colors.success

  return dynamicColors({
    min: 0,
    max: 245,
    opacity: 0.8,
  })
}
