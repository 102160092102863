import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { PortfolioInfoDetail } from 'src/graphql/models/PortfolioDetail'
import DTAccordion from 'src/components/Accordion'
import { getCountryCurrency, isUkCountry } from 'src/utils/common'
import { PoundCircleFilledStyled } from '../styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateSellerFee } from 'src/data/features/post/portfolio/portfolio'

interface AdminUpdateSellerFeeProps {
  portfolio: PortfolioInfoDetail | undefined
  portfolioCountry: string | undefined
}

const AdminUpdateSellerFee: React.FC<AdminUpdateSellerFeeProps> = ({
  portfolio,
  portfolioCountry,
}: AdminUpdateSellerFeeProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const [sellerFeeAmount, setSellerFeeAmount] = useState<number>(
    portfolio?.sellerFeeAmount ?? 0
  )
  const [sellerFeePercent, setSellerFeePercent] = useState<number>(
    portfolio?.sellerFeePercent ? portfolio?.sellerFeePercent * 100 : 0
  )
  const purchasePriceAmount = portfolio?.purchasePriceAmount ?? 1

  const percentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSellerFeePercent(event.target.value as number)
    const valueChange = event.target.value as number
    const finalValue = ((valueChange / 100) * purchasePriceAmount).toFixed(2)
    setSellerFeeAmount(parseFloat(finalValue))
  }

  const amountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSellerFeeAmount(event.target.value as number)
    const valueChange = event.target.value as number
    const finalValue = ((valueChange / purchasePriceAmount) * 100).toFixed(2)
    setSellerFeePercent(parseFloat(finalValue))
  }
  const reactQueryClient = useQueryClient()

  const updateFee = useMutation({
    mutationFn: (updateFeeData: {}) => {
      return updateSellerFee(updateFeeData)
    },
    onSuccess: () => {
      enqueueSnackbar('Service fee updated', notifySuccess)
      reactQueryClient.refetchQueries({
        queryKey: ['getPortfolioFilesLink'],
      })
      reactQueryClient.refetchQueries({
        queryKey: ['GetAllPortfolioSteps'],
      })
    },
    onError: () => {
      enqueueSnackbar('Error on update fee', notifyError)
    },
  })

  const onUpdateSeller = () => {
    updateFee.mutate({
      portfolioId: portfolio?.id,
      feeAmount: Number(sellerFeeAmount),
      feePercent: Number(sellerFeePercent / 100),
    })
  }

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  return (
    <DTAccordion
      id="seller-fee-update-accordion"
      title="Service Fee Updates"
      icon={
        isPortfolioUk ? (
          <PoundCircleFilledStyled />
        ) : (
          <Icon name="MonetizationOn" />
        )
      }
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <strong>
                    Fee Percent
                    <br />
                    <br />
                  </strong>
                  <Typography variant="inherit" color={textSecondary.color}>
                    This will override the existing Fee Percent and ignore any
                    mininum fees set in Cient Setup.
                    <br />A new invoice will be regenerated and emailed.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <strong>
                    Fee Amount
                    <br />
                    <br />
                  </strong>
                  <Typography variant="inherit" color={textSecondary.color}>
                    This will override the existing Fee Amount and ignore any
                    mininum fees set in Cient Setup.
                    <br />A new invoice will be regenerated and emailed.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <TextField
                    id="sellerfeePercent"
                    name="sellerFeePercent"
                    onChange={percentChange}
                    value={sellerFeePercent}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <TextField
                    style={{ fontSize: '6px' }}
                    id="sellerfeeAmount"
                    name="sellerFeeAmount"
                    onChange={amountChange}
                    value={sellerFeeAmount}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {getCountryCurrency(portfolio?.portfolioCountry)}
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ border: 'none' }}>
              <Button
                id="btSeller"
                onClick={onUpdateSeller}
                color="primary"
                variant="outlined"
                autoFocus
                startIcon={<Icon name="Save" fontSize="small" />}
              >
                Save
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DTAccordion>
  )
}

export default AdminUpdateSellerFee
