import {
  ACCOUNT_API_URL,
  BACKEND_URL,
} from 'src/infra/api/axios-wrapper/httpClient'

const portfolioPrefix = 'portfolio.'
const accountPrefix = 'account.'

export const PortfolioExecPsaCommand = `${BACKEND_URL}/${portfolioPrefix}ExecPsaCommand`
export const PortfolioManageAccountsByRow = `${BACKEND_URL}/${portfolioPrefix}managePortfolioAccountsByRow`
export const PortfolioManageAccountsByLender = `${BACKEND_URL}/${portfolioPrefix}managePortfolioAccountsByLender`
export const PortfolioSavePortfolioTemplate = `${BACKEND_URL}/${portfolioPrefix}savePortfolioTemplate`
export const PortfolioValidateTemplateHeaders = `${BACKEND_URL}/${portfolioPrefix}validatePortfolioTemplateHeaders`
export const PortfolioConfirmFinalFunding = `${BACKEND_URL}/${portfolioPrefix}ConfirmFinalFunding`
export const PortfolioExcludeOutOfStatAccounts = `${BACKEND_URL}/${portfolioPrefix}ExcludeOutOfStatAccounts`
export const PortfolioUploadPortfolio = `${BACKEND_URL}/${portfolioPrefix}uploadPortfolio`
export const PortfolioGetPortfolioBuyersBySellerId = `${BACKEND_URL}/${portfolioPrefix}getPortfolioBuyersBySellerId`
export const PortfolioGetPortfoliosByBuyerAndSellerIds = `${BACKEND_URL}/${portfolioPrefix}getPortfoliosByBuyerAndSellerIds`
export const PortfolioSplit = `${BACKEND_URL}/${portfolioPrefix}splitPortfolio`
export const PortfolioAcceptBid = `${BACKEND_URL}/${portfolioPrefix}acceptBid`
export const AcceptPortfolioDisclosure = `${BACKEND_URL}/${portfolioPrefix}acceptPortfolioDisclosure`
export const PortfolioRewindPsaFinalVersion = `${BACKEND_URL}/${portfolioPrefix}rewindPsaFinalVersion`
export const PortfolioRespondList = `${BACKEND_URL}/${portfolioPrefix}respondPortfolioList`
export const PortfolioGeneratePortfolioDSS = `${BACKEND_URL}/${portfolioPrefix}generatePortfolioDSS`
export const PostSaleGetCanCreateUnspecifiedPostSale = `${ACCOUNT_API_URL}/${accountPrefix}Validate`
export const PortfolioSendInvoice = `${BACKEND_URL}/${portfolioPrefix}SendInvoice`
export const ScrubPortfolio = `${BACKEND_URL}/${portfolioPrefix}ScrubPortfolio`
export const ScorePortfolio = `${BACKEND_URL}/${portfolioPrefix}ScorePortfolio`
export const SaveNumberOfDaysToProvideMediaFileUrl = `${BACKEND_URL}/${portfolioPrefix}saveNumberOfDaysToProvideMediaFile`
export const PortfolioRequestUnmaskPortfolioData = `${BACKEND_URL}/${portfolioPrefix}requestUnmaskPortfolioData`
export const PortfolioRequestNDAUnmaskedData = `${BACKEND_URL}/${portfolioPrefix}requestNDAUnmaskedData`
export const PortfolioApproveUnmaskedDataRequest = `${BACKEND_URL}/${portfolioPrefix}approveUnmaskedDataRequest`
export const PortfolioDenyUnmaskedDataRequest = `${BACKEND_URL}/${portfolioPrefix}denyUnmaskedDataRequest`
export const PortfolioSavePortfolioMediaFile = `${BACKEND_URL}/${portfolioPrefix}savePortfolioMediaFile`
export const PortfolioApproveNDADraftUnmaskedData = `${BACKEND_URL}/${portfolioPrefix}approveNDADraftUnmaskedData`
export const PortfolioGetMediaManifestBlobUrl = `${BACKEND_URL}/${portfolioPrefix}getMediaManifestBlobUrl`
export const PortfolioUploadNDAFile = `${BACKEND_URL}/${portfolioPrefix}uploadNDAFile`
export const PortfolioCreateSplitStaging = `${BACKEND_URL}/${portfolioPrefix}createPortfolioSplitStaging`
export const PortfolioSimulateSplit = `${BACKEND_URL}/${portfolioPrefix}simulateSplitPortfolio`
export const PortfolioRewindNDAUnmaskedData = `${BACKEND_URL}/${portfolioPrefix}rewindNDAUnmaskedData`
export const PortfolioGetPortfolioMediaFileHistory = `${BACKEND_URL}/${portfolioPrefix}getPortfolioMediaFileHistory`
export const PortfolioUpdateSellerFee = `${BACKEND_URL}/${portfolioPrefix}updateSellerFee`
