/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useState } from 'react'

import {
  Box,
  CircularProgress,
  DataTable,
  Flex,
  Icon,
  IconButton,
  ModalDialog,
  renderDate,
  Typography,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getApprovedNDABySellerAndBuyer } from 'src/data/features/get/portfolio/portfolio'
import { ApprovedNDABySellerAndBuyerResponse } from 'src/data/features/get/portfolio/types'
import { NDADocumentUri } from 'src/graphql/models/PortfolioDetail'
import { GET_DOCUMENT_NDA_URI } from 'src/graphql/operations/queries/portfolio'
import { useLazyQuery } from '@apollo/client'

interface ApprovedNDABySellerAndBuyerModalProps {
  workflowId: number
  openModal: boolean
  setOpenModal: (open: boolean) => void
}

const ApprovedNDABySellerAndBuyerModal: React.FC<
  ApprovedNDABySellerAndBuyerModalProps
> = ({
  workflowId,
  openModal,
  setOpenModal,
}: ApprovedNDABySellerAndBuyerModalProps) => {
  const [getUriWorkflowId, setGetUriWorkflowId] = useState<number | undefined>(
    undefined
  )

  const { data: requestData, isFetching: loadingRequests } = useCustomQuery<
    ApprovedNDABySellerAndBuyerResponse[]
  >(
    ['getApprovedNDABySellerAndBuyer', workflowId],
    async () => getApprovedNDABySellerAndBuyer(workflowId),
    { enabled: !!workflowId, cacheTime: 0 }
  )

  const [getUri] = useLazyQuery<NDADocumentUri>(GET_DOCUMENT_NDA_URI, {
    onCompleted: (data) => {
      setGetUriWorkflowId(undefined)
      const uri = data?.getNDADocumentUri ?? ''
      if (uri === '') return
      window.location.href = uri
    },
  })

  const gridColumns: any[] = [
    {
      field: 'approver',
      title: 'Approver',
      show: true,
    },
    {
      field: 'closedDate',
      title: 'Closed Date',
      show: true,
      render: renderDate,
    },
    {
      title: 'Download NDA',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>
            <Flex justifyContent="center">
              {getUriWorkflowId === props.dataItem?.workflowId ? (
                <Box pt="2px" pb={2} pr={1}>
                  <CircularProgress width="20px" />
                </Box>
              ) : (
                <IconButton
                  onClick={() => {
                    setGetUriWorkflowId(props.dataItem?.workflowId)
                    getUri({
                      variables: {
                        workflowId: props.dataItem?.workflowId,
                      },
                    })
                  }}
                >
                  <Icon name="Download" />
                </IconButton>
              )}
            </Flex>
          </td>
        )
      },
    },
  ]

  return (
    <ModalDialog
      isOpen={openModal}
      header="Old Approved NDA"
      onClose={() => {
        setOpenModal(false)
      }}
      hideOkButton
      width="50%"
    >
      <Typography>
        This is the list of the most recent approved NDAs (up to 10) with this
        buyer across other portfolios.
      </Typography>
      <Box pt={2}>
        <DataTable
          data={requestData
            ?.sort((a, b) => +new Date(b.closedDate) - +new Date(a.closedDate))
            .slice(0, 10)}
          isLoading={loadingRequests}
          gridColumns={gridColumns}
        />
      </Box>
    </ModalDialog>
  )
}

export default ApprovedNDABySellerAndBuyerModal
