export const APP_NAME = 'Market'

export const TENANT_TYPE = 'http://debttrader.com/claims/tenant_type'
export const DISPLAY_NAME = 'http://debttrader.com/claims/display_name'
export const PERMISSION_INFO = 'http://debttrader.com/claims/permission_info'
export const BUSINESS_INFO = 'http://debttrader.com/claims/business_info'
export const IS_RESELLER = 'http://debttrader.com/claims/is_reseller'
export const CLIENT_INFO = 'http://debttrader.com/claims/client_info'
export const EMAIL = 'http://debttrader.com/claims/user_email'
export const DSS_ENABLED = 'http://debttrader.com/claims/DSSEnabled'
export const USER_ID = 'http://debttrader.com/claims/user_id'

export const INTERNAL = 'internal'
export const EXTERNAL = 'external'
export const BUYER = 'Buyer'
export const SELLER = 'Seller'
export const AGENCY = 'Agency'
export const SERVICER = 'Servicer'

export enum CardType {
  LOST = 'lost',
  BID_SUBMITTED = 'bit-submitted',
  LISTED = 'listed',
  CLOSING = 'closing',
  PURCHASED = 'purchased',
  ACTIVE_FLOWS = 'active-flows',
}

export const PORTFOLIO_PAGE_FILTER = 'PortfolioPageFilter'
export const FUNDING_PAGE_FILTER = 'FundingPageFilter'
export const REQUEST_PAGE_FILTER = 'RequestPageFilter'

export enum AccountPortfolioType {
  ChargeOffUSA = 1,
  Bankruptcy = 2,
  Commercial = 3,
}

export enum PortfolioStatusType {
  ReadyForReview = 'ready for review',
  Listed = 'listed',
  Awarded = 'awarded',
  PendingPSA = 'pending psa',
  PendingBuyerFunding = 'pending buyer funding',
  PendingSellerFunding = 'pending seller funding',
  PendingFinalConfirmation = 'pending final confirmation',
  AwaitingSellerFee = 'awaiting seller fee',
  Funded = 'funded',
  Purged = 'purged',
  WaitingForScrubResults = 'waiting for scrub results',
  SubmittingForScrubResults = 'submitting scrub results',
  Closed = 'closed',
}

export enum ForwardFlowsCardType {
  ACTIVE_FLOWS = 'active-forward-flows',
  EXPIRING_FORWARD_FLOWS = 'expiring-forward-flows',
  EXPIRED_FORWARD_FLOWS = 'expired-forward-flows',
}
export enum ForwardFlowsCardUrl {
  ACTIVE_FLOWS = 'active-flows',
  EXPIRING_FLOWS = 'expiring-flows',
  EXPIRED_FLOWS = 'expired-flows',
}
export enum ComplianceCardUrl {
  ACTIVE_ITEMS = 'action-items',
  OPEN = 'open',
  CLOSED = 'closed',
  OVERDUE = 'overdue',
}
export enum DashboardCardUrl {
  COMPLAINTS_OPEN = 'complaints-open',
  COMPLAINTS_CLOSED = 'complaints-closed',
  BCO_VALUE = 'bco-value',
}
export const MARKETPLACE_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/000'

export const CONTROL_PANEL_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/001'

export const CMS_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/002'

export const SCRUB_MONITORING_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/003'

export const RECOVER_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/004'

export const RMS_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/999'

export enum PermissionCodeAccess {
  MarketPlace = '000',
  MarketPlace_Basic = '000.000',
  MarketPlace_Basic_UploadPortfolio = '000.000.000',
  MarketPlace_Basic_SubmitPortfolioScrub = '000.000.001',
  MarketPlace_Basic_ListPortfolio = '000.000.002',
  MarketPlace_Basic_DeletePortfolio = '000.000.003',
  MarketPlace_Basic_AcceptBid = '000.000.004',
  MarketPlace_Basic_ConfirmPortfolioFunding = '000.000.005',
  MarketPlace_Basic_ApproveUnmaskData = '000.000.006',
  MarketPlace_Basic_PlaceBid = '000.000.007',
  MarketPlace_Basic_SubmitFundingInformation = '000.000.008',
  MarketPlace_Basic_RequestUnmaskData = '000.000.009',
  MarketPlace_Basic_DownloadPortfolioFilesPII = '000.000.010',
  MarketPlace_Basic_DownloadPortfolioFilesNonPII = '000.000.011',
  MarketPlace_Basic_AccessPortfolioDeck = '000.000.012',
  MarketPlace_Basic_AccessDigitalSellerSurvey = '000.000.013',
  MarketPlace_Basic_Upload_Media_Files = '000.000.014',
  MarketPlace_Basic_Download_Media_Files = '000.000.015',
  MarketPlace_Basic_Answer_Digital_Seller_Survey = '000.000.016',
  MarketPlace_ForwardFlow = '000.001',
  MarketPlace_ForwardFlow_ConfirmForwardFlow = '000.001.000',
  MarketPlace_BuyerComplianceOverview = '000.002',
  MarketPlace_BuyerComplianceOverview_OpenBuyerComplianceOverview = '000.002.000',
  MarketPlace_Workflows = '000.003	',
  MarketPlace_Workflows_CompleteBOSWorkflow = '000.003.001',
  MarketPlace_Workflows_CompletePSAWorkflow = '000.003.000',
  MarketPlace_Scoring = '000.004',
  MarketPlace_Scoring_RequestScoring = '000.004.000',
  MarketPlace_PostSale = '000.005',
  MarketPlace_PostSale_CreateRequest = '000.005.000',
  MarketPlace_PostSale_AcceptRejectRequest = '000.005.001',
  MarketPlace_PostSale_AttachFile = '000.005.002',
  MarketPlace_PostSale_AddComment = '000.005.003',
  MarketPlace_PostSale_CreateFunding = '000.005.004',
  MarketPlace_PostSale_ConfirmFunding = '000.005.005',
  MarketPlace_PostSale_Admin = '000.005.006',
  MarketPlace_PostSale_BatchAttachment = '000.005.007',
  MarketPlace_PostSale_AccountPlacement = '000.005.008',
  MarketPlace_Complaints = '000.006',
  MarketPlace_Complaints_CreateComplaint = '000.006.000',
  MarketPlace_Complaints_RespondComplaint = '000.006.001',
  MarketPlace_Internal = '000.007',
  MarketPlace_Internal_DeletePortfolio = '000.007.000',
  MarketPlace_Internal_ReGenerateFiles = '000.007.001',
  MarketPlace_Internal_UpdateBids = '000.007.002',
  MarketPlace_Internal_OpenClosePortfolioBidWindow = '000.007.003',
  MarketPlace_Internal_UpdatePortfolioDisclosure = '000.007.004',
  MarketPlace_Internal_UpdatePortfolioFees = '000.007.005',
  MarketPlace_Internal_ResendDownloadInvoices = '000.007.006',
  MarketPlace_Internal_UpdatePortfolioSellerSurvey = '000.007.007',
  MarketPlace_Internal_ComfirmFunds = '000.007.008',
  MarketPlace_Internal_UpdatePortfolioDates = '000.007.009',
  MarketPlace_Internal_DisplayBidAmount = '000.007.010',
  MarketPlace_Internal_UploadTemplates = '000.007.011',
  Marketplace_Internal_ReserveMet = '000.007.012',
  Marketplace_Internal_UpdateRenewForwardFlow = '000.007.013',
  Marketplace_Internal_AddDownload = '000.007.014',
  Marketplace_Internal_Buyer_Portfolio_Access_Update = '000.007.015',
  Marketplace_Internal_Approve_Listing_Portfolio = '000.007.016',
  Marketplace_Internal_Buyer_Portfolio_Access_View = '000.007.017',
}

export enum AppsPermissionCodeAccess {
  Control_Panel = '001',
  Compliance_Management_System = '002',
  Recover = '004',
}

export enum ControlPanelCodeAccess {
  Control_Panel = '001',
  ControlPanel_Permissions_Impersonate_User = '001.000.002',
  Control_Panel_Basic_Info = '001.002.000',
}

export enum RecoverCodeAccess {
  Recover = '004',
  Recover_Basic = '004.000',
}

export enum ComplianceCodeAccess {
  Compliance = '002',
  Compliance_Basic = '002.000',
  ComplianceManagementSystem_Basic_External = '002.000.001',
  ComplianceManagementSystem_Basic_Internal = '002.000.002',
}

export const MilitaryBranchList = [
  'Air National Guard',
  'Air Force Active Duty',
  'Air Force Temporary',
  'Air Force Reserve',
  'Army National Guard',
  'Army Active Duty',
  'Army Temporary',
  'Army Reserve',
  'Army Unknown',
  'Coast Guard Active Duty',
  'Coast Guard Reserve',
  'Public Health Services',
  'Marines Corps Active Duty',
  'Marine Corps Temporary',
  'Marine Corps Reserve',
  'Marine Corps Unknown',
  'Navy Active Duty',
  'Navy Reserve',
  'Navy Unknown',
  'Navy Temporary',
  'National Oceanic & Atmospheric Administration',
]

export const InsolvencyTypes = [
  { name: 'Bankruptcy', value: 1 },
  { name: 'IVA', value: 2 },
  { name: 'DRO', value: 3 },
  { name: 'Sequestration', value: 4 },
  { name: 'Trust Deed', value: 5 },
  { name: 'DAS', value: 6 },
]

export const Reasons = [{ name: 'No Payment', value: 1 }]

export enum PostSaleRequestStatusEnum {
  PENDING_SELLER_RESPONSE = 'PENDING_SELLER_RESPONSE',
  PENDING_BUYER_RESPONSE = 'PENDING_BUYER_RESPONSE',
  PENDING_SELLER_REVIEW = 'PENDING_SELLER_REVIEW',
  PENDING_BUYER_REVIEW = 'PENDING_BUYER_REVIEW',
  PENDING_SELLER_FUNDING = 'PENDING_SELLER_FUNDING',
  PENDING_BUYER_FUNDING_CONFIRMATION = 'PENDING_BUYER_FUNDING_CONFIRMATION',
  PENDING_BUYER_CONFIRMATION = 'PENDING_BUYER_CONFIRMATION',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  PENDING_RESPONSE = 'PENDING_RESPONSE',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  UNRESOLVED = 'UNRESOLVED',
  EFFORTS_EXAUSTED = 'EFFORTS_EXAUSTED',
}
export const PURGED_PORTFOLIOS_INTERVAL = 30000

export const rePhoneNumber =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/

export enum BulkRespondOperation {
  AcceptReject = 'accept_reject',
  DataSubmission = 'data_submission',
  Close = 'close',
}

export enum SurveyType {
  DigitalSellerSurvey = 'Digital Seller Survey',
}

export const PortfolioContainer = 'portfolios'
export const AccountCardContainer = 'accountcards'

export enum DropzoneErrorCodes {
  INVALID_TYPE_FILE = 'file-invalid-type',
  FILE_TOO_BIG = 'file-too-large',
}

export enum FundingCommentType {
  Comment,
  EffortExaustedComment,
  FileUpload,
}

export enum RequestEventEnum {
  Escalated,
  DeEscalated,
  Withdrawn,
  Comment,
  EffortExaustedComment,
  Confirmed,
  Rejected,
  UserAssignedToRequest,
  FileUpload,
  DataRequestedSubmitted,
  FileStatusChanged,
  StatusChanged,
  Created,
}

export const CAN_IMPERSONATE = 'http://debttrader.com/claims/permission_info/CI'
export const EMAIL_VERIFIED = 'email_verified'
export const SECURITY_STAMP = 'AspNet.Identity.SecurityStamp'
export const AUTH_TIME = 'auth_time'
export const IS_IMPERSONATING =
  'http://debttrader.com/claims/permission_info/II'
export const PREFERED_USERNAME = 'preferred_username'
export const PROFILE_NAME = 'name'
