/* eslint-disable react/jsx-indent */
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { commonMutation } from 'src/graphql/operations/mutations'
import { GET_MENU_CONTROL } from 'src/graphql/operations/queries/Common'

import {
  LOAD_PORTFOLIO,
  FORWARD_FLOW,
  POSTSALE,
  FUNDING,
  CREATE_REQUEST,
  BULK_RESPONSE_REQUEST,
  DASHBOARD,
  KNOWLEDGE_BASE,
  HELP_REQUEST,
  BATCH_ATTACHMENT_REQUEST,
  PORTFOLIO,
  POST_SALE_REPORT,
  ACCOUNT_PLACEMENT,
  DIGITAL_SELLER_SURVEY,
  PORTFOLIO_REPORT,
  BULK_WITHDRAW,
  BULK_ASSIGN,
  COMPLAINT_DASHBOARD,
  COMPLIANCE_LIST,
  FORWARD_FLOW_LIST,
} from 'src/routes'
import {
  APP_NAME,
  AppsPermissionCodeAccess,
  BUYER,
  ComplianceCodeAccess,
  DSS_ENABLED,
  ForwardFlowsCardUrl,
  INTERNAL,
  PermissionCodeAccess,
  RecoverCodeAccess,
} from 'src/utils/constants'
import { routeType } from 'src/utils/routeByType'
import { AbilityContext, Can } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useQuery } from '@apollo/client'
import { Cookies } from 'react-cookie'
import {
  Icon,
  SideMenu,
  SideMenuItem,
  SideMenuSubItem,
} from 'everchain-uilibrary'
import ContactDialog from 'src/components/Dialogs/ContactDialog'
import { getStandardUri, permissionReduce } from 'src/utils/common'

const Menus = () => {
  const { data: menusControlData } = useQuery<any>(GET_MENU_CONTROL)
  const [openContactInfo, setOpenContactInfo] = useState<boolean>(false)

  const ability = useContext(AbilityContext)
  const cookies = new Cookies()
  const menusControlConfig = menusControlData?.menuControl.menuControl
  const history = useHistory()
  const showItemsFuture = false
  const [pageUrl, setPageUrl] = useState(history.location.pathname)
  const { upadateMenuControl } = commonMutation
  const { userPermissions, user, managePermissions, accountSettingsEnabled } =
    useContext(AuthContext)
  const dssEnabled = user?.profile[DSS_ENABLED]?.toLowerCase()
    ? JSON.parse(user?.profile[DSS_ENABLED]?.toLowerCase())
    : false

  const managePermissionReduce = permissionReduce(managePermissions)

  const location = useLocation()

  useEffect(() => {
    setPageUrl(location.pathname)
  }, [location])

  const handleMenuDispatch = (type: string): void => {
    upadateMenuControl({
      menuControl: {
        portfolioOpen: type === 'portfolioOpen',
        postSaleOpen: type === 'postSaleOpen',
        accountPlacementOpen: type === 'accountPlacementOpen',
        complianceOpen: type === 'complianceOpen',
        faqOpen: type === 'faqOpen',
        otherAppsOpen: type === 'otherAppsOpen',
      },
    })
  }
  const getTextBcoMenu = (): string => {
    if (userPermissions.type === 'Seller') return 'My Buyers'
    if (userPermissions.type === INTERNAL) return 'BCO'
    return 'My Sellers'
  }

  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'

  const isURL = (paths: string[]) => {
    let result = false
    for (const p of paths) {
      if (pageUrl.indexOf(p) !== -1) {
        result = true
        break
      }
    }
    return result
  }

  const portfolioUrls = [
    routeType,
    `${FORWARD_FLOW_LIST}/${ForwardFlowsCardUrl.ACTIVE_FLOWS}`,
    LOAD_PORTFOLIO,
    DIGITAL_SELLER_SURVEY,
    PORTFOLIO_REPORT,
  ]

  const postSaleUrls = [
    `${POSTSALE}/urgent`,
    CREATE_REQUEST,
    BULK_RESPONSE_REQUEST,
    BATCH_ATTACHMENT_REQUEST,
    `${FUNDING}/unpaid-funding`,
    `${FUNDING}/create`,
    POST_SALE_REPORT,
    BULK_WITHDRAW,
    BULK_ASSIGN,
  ]

  const accountPlacementUrls = [`${ACCOUNT_PLACEMENT}`]

  const complianceUrls = [
    `${COMPLIANCE_LIST}/action-items`,
    `${COMPLIANCE_LIST}/bco`,
    `${COMPLIANCE_LIST}/my-bcr`,
    `${COMPLIANCE_LIST}/open`,
    `${COMPLIANCE_LIST}/closed`,
  ]

  const containsUrl = (url: string, urlBase: string) => {
    if (url.split('/')[1] === urlBase.replace('/', '')) return true

    return false
  }

  const handleInternalRedirect = (uri: string) => {
    history.push(getStandardUri(uri))
  }

  const fontSizeIcon = 'small'

  return (
    <>
      <SideMenu>
        <SideMenuItem
          id="menu-dashboard"
          title="Dashboard"
          onClick={() => handleInternalRedirect(DASHBOARD)}
          icon={<Icon name="Dashboard" fontSize={fontSizeIcon} />}
          defaultOpen={pageUrl.indexOf(DASHBOARD) !== -1}
        />
        <Can do={RecoverCodeAccess.Recover_Basic} on="any">
          <SideMenuItem
            id="menu-recover"
            title="Recover"
            onClick={() =>
              window.open(process.env.REACT_APP_RECOVER_SITE, '_blank')
            }
            icon={<Icon name="Ballot" fontSize={fontSizeIcon} />}
          />
        </Can>
        <Can do={PermissionCodeAccess.MarketPlace_Basic} on="any">
          <SideMenuItem
            id="menu-marketplace"
            title={APP_NAME}
            onClick={() => {
              if (!isURL(portfolioUrls)) {
                handleInternalRedirect(portfolioUrls[0])
              }
            }}
            icon={<Icon name="BusinessCenter" fontSize={fontSizeIcon} />}
            defaultOpen={
              containsUrl(pageUrl, PORTFOLIO) ||
              containsUrl(pageUrl, FORWARD_FLOW) ||
              containsUrl(pageUrl, LOAD_PORTFOLIO) ||
              containsUrl(pageUrl, DIGITAL_SELLER_SURVEY) ||
              containsUrl(pageUrl, PORTFOLIO_REPORT)
            }
          >
            <SideMenuSubItem
              id="sub-menu-my-portfolios"
              title="My Portfolios"
              onClick={() => handleInternalRedirect(portfolioUrls[0])}
              defaultOpen={containsUrl(pageUrl, PORTFOLIO)}
              icon={<Icon name="Store" fontSize={fontSizeIcon} />}
            />
            <Can do={PermissionCodeAccess.MarketPlace_ForwardFlow} on="any">
              <SideMenuSubItem
                id="sub-menu-foward-flows"
                title="Forward Flows"
                onClick={() => handleInternalRedirect(portfolioUrls[1])}
                defaultOpen={containsUrl(pageUrl, FORWARD_FLOW)}
                icon={<Icon name="FastForward" fontSize={fontSizeIcon} />}
              />
            </Can>
            {((ability.can(
              PermissionCodeAccess.MarketPlace_Internal_UploadTemplates,
              'any'
            ) &&
              userPermissions.type === INTERNAL) ||
              (ability.can(
                PermissionCodeAccess.MarketPlace_Basic_UploadPortfolio,
                'any'
              ) &&
                (userPermissions.type === 'Seller' ||
                  userPermissions.isReseller))) && (
              <SideMenuSubItem
                id="sub-menu-load-portfolio"
                title="Load Portfolio"
                onClick={() => handleInternalRedirect(portfolioUrls[2])}
                defaultOpen={containsUrl(pageUrl, LOAD_PORTFOLIO)}
                icon={<Icon name="Publish" fontSize={fontSizeIcon} />}
              />
            )}
            {((ability.can(
              PermissionCodeAccess.MarketPlace_Basic_AccessDigitalSellerSurvey,
              'any'
            ) &&
              userPermissions.type === INTERNAL) ||
              (userPermissions.type !== INTERNAL &&
                ability.can(
                  PermissionCodeAccess.MarketPlace_Basic_AccessDigitalSellerSurvey,
                  'any'
                ) &&
                dssEnabled === true)) && (
              <SideMenuSubItem
                id="sub-menu-digital-seller-survey"
                title="Digital Seller Survey"
                onClick={() => handleInternalRedirect(portfolioUrls[3])}
                defaultOpen={containsUrl(pageUrl, DIGITAL_SELLER_SURVEY)}
                icon={<Icon name="SpeakerNotes" fontSize={fontSizeIcon} />}
              />
            )}
            <Can do={PermissionCodeAccess.MarketPlace_Basic} on="any">
              <SideMenuSubItem
                id="sub-menu-marketplace-report"
                title="Report"
                onClick={() => handleInternalRedirect(portfolioUrls[4])}
                defaultOpen={containsUrl(pageUrl, PORTFOLIO_REPORT)}
                icon={<Icon name="FindInPage" fontSize={fontSizeIcon} />}
              />
            </Can>
          </SideMenuItem>
        </Can>
        <Can do={PermissionCodeAccess.MarketPlace_PostSale} on="any">
          <SideMenuItem
            id="menu-accounts"
            title="Manage"
            onClick={() => {
              if (!isURL(postSaleUrls)) {
                handleInternalRedirect(postSaleUrls[0])
              }
            }}
            icon={<Icon name="Restore" fontSize={fontSizeIcon} />}
            defaultOpen={
              containsUrl(pageUrl, POSTSALE) || containsUrl(pageUrl, FUNDING)
            }
          >
            <SideMenuSubItem
              id="sub-menu-post-sale-request"
              title="Request"
              onClick={() => handleInternalRedirect(postSaleUrls[0])}
              defaultOpen={pageUrl.indexOf(postSaleUrls[0]) !== -1}
              icon={<Icon name="SyncAlt" fontSize={fontSizeIcon} />}
            />
            {(ability.can(
              PermissionCodeAccess.MarketPlace_PostSale_CreateRequest,
              'any'
            ) ||
              ability.can(
                PermissionCodeAccess.MarketPlace_PostSale_BatchAttachment,
                'any'
              ) ||
              ability.can(
                PermissionCodeAccess.MarketPlace_PostSale_AcceptRejectRequest,
                'any'
              )) && (
              <SideMenuSubItem
                id="sub-menu-group-create-bulk"
                title="Create Bulk"
                icon={<Icon name="Add" fontSize={fontSizeIcon} />}
              >
                <Can
                  do={PermissionCodeAccess.MarketPlace_PostSale_CreateRequest}
                  on="any"
                >
                  <SideMenuSubItem
                    id="sub-menu-create-post-sale-requests"
                    title="Request"
                    onClick={() => handleInternalRedirect(postSaleUrls[1])}
                    defaultOpen={pageUrl.indexOf(postSaleUrls[1]) !== -1}
                    icon={<Icon name="SyncAlt" fontSize={fontSizeIcon} />}
                  />
                  <SideMenuSubItem
                    id="sub-menu-bulk-assign"
                    title="Assign"
                    onClick={() => handleInternalRedirect(postSaleUrls[8])}
                    defaultOpen={pageUrl.indexOf(postSaleUrls[8]) !== -1}
                    icon={<Icon name="AssignmentInd" fontSize={fontSizeIcon} />}
                  />
                </Can>
                <Can
                  do={PermissionCodeAccess.MarketPlace_PostSale_BatchAttachment}
                  on="any"
                >
                  <SideMenuSubItem
                    id="sub-menu-batch-attachment"
                    title="Attachment"
                    onClick={() => {
                      handleInternalRedirect(postSaleUrls[3])
                      cookies.remove('postsaleformCollection')
                      cookies.remove('postsaleSellectAccounts')
                    }}
                    defaultOpen={pageUrl.indexOf(postSaleUrls[3]) !== -1}
                    icon={<Icon name="UploadFile" fontSize={fontSizeIcon} />}
                  />
                </Can>
                <Can
                  do={
                    PermissionCodeAccess.MarketPlace_PostSale_AcceptRejectRequest
                  }
                  on="any"
                >
                  <SideMenuSubItem
                    id="sub-menu-bulk-respond"
                    title="Respond"
                    onClick={() => handleInternalRedirect(postSaleUrls[2])}
                    defaultOpen={pageUrl.indexOf(postSaleUrls[2]) !== -1}
                    icon={<Icon name="Loop" fontSize={fontSizeIcon} />}
                  />
                </Can>
                <Can
                  do={PermissionCodeAccess.MarketPlace_PostSale_CreateRequest}
                  on="any"
                >
                  <SideMenuSubItem
                    id="sub-menu-bulk-withdraw"
                    title="Withdraw"
                    onClick={() => handleInternalRedirect(postSaleUrls[7])}
                    defaultOpen={pageUrl.indexOf(postSaleUrls[7]) !== -1}
                    icon={<Icon name="Clear" fontSize={fontSizeIcon} />}
                  />
                </Can>
              </SideMenuSubItem>
            )}
            <SideMenuSubItem
              id="sub-menu-group-funding"
              title="Funding"
              icon={<Icon name="Payment" fontSize={fontSizeIcon} />}
            >
              <SideMenuSubItem
                id="sub-menu-funding-request"
                title="Request"
                onClick={() => handleInternalRedirect(postSaleUrls[4])}
                defaultOpen={pageUrl.indexOf(postSaleUrls[4]) !== -1}
                icon={<Icon name="SyncAlt" fontSize={fontSizeIcon} />}
              />
              {ability.can(
                PermissionCodeAccess.MarketPlace_PostSale_CreateFunding,
                'any'
              ) &&
                (userPermissions.type === INTERNAL ||
                  userPermissions.type === 'Seller' ||
                  userPermissions.isReseller) && (
                  <SideMenuSubItem
                    id="sub-menu-create-funding-request"
                    title="Create"
                    onClick={() => handleInternalRedirect(postSaleUrls[5])}
                    defaultOpen={pageUrl.indexOf(postSaleUrls[5]) !== -1}
                    icon={<Icon name="Add" fontSize={fontSizeIcon} />}
                  />
                )}
            </SideMenuSubItem>
            <SideMenuSubItem
              id="sub-menu-accounts-report"
              title="Report"
              onClick={() => {
                localStorage.removeItem('postSaleReportFilter')
                localStorage.removeItem('postSaleReportStatusSelected')
                handleInternalRedirect(postSaleUrls[6])
              }}
              defaultOpen={pageUrl.indexOf(postSaleUrls[6]) !== -1}
              icon={<Icon name="FindInPage" fontSize={fontSizeIcon} />}
            />
          </SideMenuItem>
        </Can>
        {ability.can(
          PermissionCodeAccess.MarketPlace_PostSale_AccountPlacement,
          'any'
        ) &&
          (userPermissions.type === INTERNAL ||
            (userPermissions.type === BUYER && accountSettingsEnabled)) && (
            <SideMenuItem
              id="menu-accounts-placement"
              title="Account Placement"
              onClick={() => {
                if (!isURL(accountPlacementUrls)) {
                  handleInternalRedirect(accountPlacementUrls[0])
                }
              }}
              icon={<Icon name="Place" fontSize={fontSizeIcon} />}
              defaultOpen={containsUrl(pageUrl, ACCOUNT_PLACEMENT)}
            >
              <SideMenuSubItem
                id="sub-menu-accounts-placement-account-placement"
                title="Account Placement"
                onClick={() => handleInternalRedirect(accountPlacementUrls[0])}
                defaultOpen={containsUrl(pageUrl, ACCOUNT_PLACEMENT)}
                icon={<Icon name="AddLocation" fontSize={fontSizeIcon} />}
              />
            </SideMenuItem>
          )}
        <Can do={PermissionCodeAccess.MarketPlace_Complaints} on="any">
          <SideMenuItem
            id="menu-complaints"
            title="Comply"
            onClick={() => {
              if (!isURL(complianceUrls)) {
                handleInternalRedirect(complianceUrls[0])
              }
            }}
            icon={<Icon name="QuestionAnswer" fontSize={fontSizeIcon} />}
            defaultOpen={containsUrl(pageUrl, COMPLIANCE_LIST)}
          >
            <SideMenuSubItem
              id="sub-menu-complaints-dashboard"
              title="Dashboard"
              onClick={() => handleInternalRedirect(COMPLAINT_DASHBOARD)}
              defaultOpen={pageUrl.indexOf(COMPLAINT_DASHBOARD) !== -1}
              icon={<Icon name="Dashboard" fontSize={fontSizeIcon} />}
            />
            <SideMenuSubItem
              id="sub-menu-complaints-complaints"
              title={
                userPermissions.type === INTERNAL
                  ? 'Complaints'
                  : 'My Complaints'
              }
              onClick={() => handleInternalRedirect(complianceUrls[0])}
              defaultOpen={
                pageUrl.indexOf(complianceUrls[0]) !== -1 ||
                pageUrl.indexOf(complianceUrls[3]) !== -1 ||
                pageUrl.indexOf(complianceUrls[4]) !== -1
              }
              icon={<Icon name="AssignmentTurnedIn" fontSize={fontSizeIcon} />}
            />
            <SideMenuSubItem
              id="sub-menu-complaints-bco"
              title={getTextBcoMenu()}
              onClick={() => handleInternalRedirect(complianceUrls[1])}
              defaultOpen={pageUrl.indexOf(complianceUrls[1]) !== -1}
              icon={<Icon name="People" fontSize={fontSizeIcon} />}
            />
            {showItemsFuture && (
              <SideMenuSubItem
                id="sub-menu-complaints-my-agencies"
                title="My Agencies"
                icon={<Icon name="People" fontSize={fontSizeIcon} />}
              />
            )}
          </SideMenuItem>
        </Can>
        <Can do={ComplianceCodeAccess.Compliance_Basic} on="any">
          <SideMenuItem
            id="menu-network"
            title="Network"
            onClick={() => {
              window.open(process.env.REACT_APP_CMS_SITE, '_blank')
            }}
            icon={<Icon name="WifiTethering" fontSize={fontSizeIcon} />}
          />
        </Can>
        {(isSeller || isBuyer) && (
          <SideMenuItem
            id="menu-contact"
            title="Contact"
            onClick={() => setOpenContactInfo(true)}
            icon={<Icon name="RecordVoiceOver" fontSize={fontSizeIcon} />}
          />
        )}
        {!process.env.REACT_APP_HIDE_FAQ_MENU && (
          <SideMenuItem
            id="menu-faq"
            title="FAQ"
            onClick={() => handleMenuDispatch('faqOpen')}
            icon={<Icon name="LiveHelp" fontSize={fontSizeIcon} />}
            defaultOpen={menusControlConfig?.faqOpen}
          >
            <SideMenuSubItem
              id="sub-menu-faq-knowledge-base"
              title="Knowledge Base"
              onClick={() => window.open(KNOWLEDGE_BASE, '_blank')}
              icon={<Icon name="MenuBook" fontSize={fontSizeIcon} />}
            />
            <SideMenuSubItem
              id="sub-menu-faq-help-request"
              title="Help Request"
              onClick={() => window.open(HELP_REQUEST, '_blank')}
              icon={<Icon name="HelpRounded" fontSize={fontSizeIcon} />}
            />
          </SideMenuItem>
        )}
        {(managePermissionReduce[AppsPermissionCodeAccess.Control_Panel] ||
          managePermissionReduce[
            AppsPermissionCodeAccess.Compliance_Management_System
          ]) && (
          <SideMenuItem
            id="menu-other-apps"
            title="Other Apps"
            onClick={() => handleMenuDispatch('otherAppsOpen')}
            icon={<Icon name="Apps" fontSize={fontSizeIcon} />}
            defaultOpen={menusControlConfig?.otherAppsOpen}
          >
            {managePermissionReduce[AppsPermissionCodeAccess.Control_Panel] && (
              <SideMenuSubItem
                id="sub-menu-other-apps-control-panel"
                title="Control Panel"
                onClick={() =>
                  window.open(process.env.REACT_APP_CONTROLPANEL_SITE, '_blank')
                }
                icon={
                  <Icon
                    name="SettingsApplicationsOutlined"
                    fontSize={fontSizeIcon}
                  />
                }
              />
            )}
          </SideMenuItem>
        )}
      </SideMenu>
      <ContactDialog open={openContactInfo} setOpen={setOpenContactInfo} />
    </>
  )
}

Menus.defaultProps = {
  opendedMenu: false,
}

export default Menus
