import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography,
  Button,
  Grid,
  Tooltip,
  Skeleton,
} from '@mui/material'
import DTAccordion from 'src/components/Accordion'
import { Colors, DatePicker, Icon, textPrimary } from 'everchain-uilibrary'
import { useSnackbar } from 'notistack'
import React, { useState, useContext } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { Can } from 'src/context/Can'
import { PortfolioPurchaseInformation } from 'src/graphql/models/Portfolio'
import { PermissionCodeAccess, PortfolioStatusType } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { TableCellFundingCustom, PoundCircleFilledStyled } from '../styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getFundingInformation,
  getPortfolioPurchaseInfo,
} from 'src/data/features/get/portfolio/portfolio'
import RemittanceInvoiceEmail from './RemittanceInvoiceEmail'
import { confirmBuyerFunding } from 'src/data/features/post/portfolioFunding/portfoliofunding'
import { PortfolioFundingInfo } from 'src/data/features/get/portfolio/types'

interface FundingInformationProps {
  portfolioId: string | undefined
  portfolioStatus: string
  portfolioCountry: string | undefined
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')
const gradientBackgroundColor = '#F2F2F2'
const ConfirmBuyerFunding: React.FC<FundingInformationProps> = ({
  portfolioId,
  portfolioStatus,
  portfolioCountry,
}: FundingInformationProps) => {
  const [disableUI, setDisableUI] = useState(false)
  const [dateFunded, setDateFunded] = useState(new Date())
  const [sellerFundingComment, setSellerFundingComment] = useState('')
  const [sellerFundingTransactionNumber, setSellerFundingTransactionNumber] =
    useState('')
  const [dtFundingTransactionNumber, setDtFundingTransactionNumber] =
    useState('')
  const [isPendingPSA] = useState(
    portfolioStatus === PortfolioStatusType.PendingPSA
  )

  const handleDateChange = (date: any) => {
    setDateFunded(date)
  }

  const handleSellerFundingComment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSellerFundingComment(event.target.value)
  }

  const handleSellerFundingTransactionNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSellerFundingTransactionNumber(event.target.value)
  }

  const handleDtFundingTransactionNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDtFundingTransactionNumber(event.target.value)
  }

  const disableConfirmButtonFunction = (): boolean => {
    if (
      dtFundingTransactionNumber &&
      sellerFundingTransactionNumber &&
      dateFunded &&
      !disableUI
    )
      return false

    return true
  }

  const { enqueueSnackbar } = useSnackbar()

  const onConfirmFunds = () => {
    setDisableUI(true)

    confirmBuyerFunding({
      fundsToDtAmount: purchaseData?.buyerFeeAmount,
      fundsToDtDateUtc: dateFunded,
      fundsToDtTransactionNumber: dtFundingTransactionNumber,
      fundsToSellerAmount:
        purchaseData?.finalPurchasePriceAmount ??
        purchaseData?.purchasePriceAmount,
      fundsToSellerComment: sellerFundingComment,
      fundsToSellerDateUtc: dateFunded,
      fundsToSellerTransactionNumber: sellerFundingTransactionNumber,
      portfolioId,
    })
      .then(() => {
        enqueueSnackbar('Funding has been confirmed', notifySuccess)
      })
      .catch(() => {
        enqueueSnackbar('Error', notifyError)
        setDisableUI(true)
      })
  }

  const {
    data: portfolioFundingToSellerInfo,
    isFetching: loadingPortfolioFundingToSellerInfo,
  } = useCustomQuery<PortfolioFundingInfo>(
    ['getFundingInformationSeller', portfolioId],
    async () => getFundingInformation(portfolioId, 'FundingToSeller'),
    { enabled: !!portfolioId, cacheTime: 0 }
  )

  const fundingToSeller = portfolioFundingToSellerInfo

  const {
    data: portfolioFundingToDebtTraderInfo,
    isFetching: loadingPortfolioFundingToDebtTraderInfo,
  } = useCustomQuery<PortfolioFundingInfo>(
    ['getFundingInformationDebtTrader', portfolioId],
    async () => getFundingInformation(portfolioId, 'FundingToDebttrader'),
    { enabled: !!portfolioId, cacheTime: 0 }
  )

  const fundingToDebtTrader = portfolioFundingToDebtTraderInfo

  const { data: purchaseData, isFetching: loadingPortfolioPurchaseInfo } =
    useCustomQuery<PortfolioPurchaseInformation>(
      ['getPortfolioPurchaseInfo', portfolioId],
      async () => getPortfolioPurchaseInfo(portfolioId),
      { enabled: true, cacheTime: 0 }
    )

  const { userPermissions, profileClient } = useContext(AuthContext)
  const isSeller = userPermissions.type.toLowerCase() === 'seller'

  if (
    loadingPortfolioPurchaseInfo ||
    loadingPortfolioFundingToSellerInfo ||
    loadingPortfolioFundingToDebtTraderInfo ||
    !purchaseData
  ) {
    return <Skeleton variant="rectangular" width="100%" height={50} />
  }

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  return (
    <DTAccordion
      id="buyer-funding-accordion"
      title={isPendingPSA ? 'Buyer Funding Information' : 'Funding to Seller'}
      icon={
        isPortfolioUk ? (
          <PoundCircleFilledStyled />
        ) : (
          <Icon name="MonetizationOn" />
        )
      }
      expanded={true}
    >
      <Grid container spacing={4}>
        {!isPendingPSA && (
          <Grid item xs={12}>
            <Typography variant="body2" color={textPrimary.color}>
              <strong>Next Step: </strong>Unmasked file will be available for
              download after all parties have verified funds.
            </Typography>
          </Grid>
        )}
        {!!purchaseData && (
          <Grid item xs={12}>
            <Typography variant="h6" id="tableTitle" component="div">
              Purchase Info
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCellFundingCustom
                      style={{ color: Colors.primary }}
                      component="th"
                      scope="row"
                    >
                      Face Value
                    </TableCellFundingCustom>
                    <TableCellFundingCustom
                      style={{ color: Colors.primary }}
                      align="right"
                    >
                      {purchaseData.faceValue}
                    </TableCellFundingCustom>
                  </TableRow>
                  <TableRow
                    style={{ backgroundColor: gradientBackgroundColor }}
                  >
                    <TableCellFundingCustom component="th" scope="row">
                      Purchase Price ({purchaseData.purchasePricePercent})
                    </TableCellFundingCustom>
                    <TableCellFundingCustom align="right">
                      {purchaseData.purchasePriceAmountDisplay}
                    </TableCellFundingCustom>
                  </TableRow>
                  {purchaseData.isBk && (
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        Total TOC ({purchaseData.totalPocAccounts} x{' '}
                        {purchaseData.pocValue})
                      </TableCellFundingCustom>
                      <TableCellFundingCustom
                        style={{ color: 'red' }}
                        align="right"
                      >
                        - {purchaseData.totalTocAmount}
                      </TableCellFundingCustom>
                    </TableRow>
                  )}
                  {purchaseData.isBk && (
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        Final Purchase Price (
                        {purchaseData.finalPurchasePricePercent})
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {purchaseData.finalPurchasePriceAmountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                  )}
                  {purchaseData.buyerFeeAmountDisplay && (
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        {`Sales Commission (${purchaseData.buyerFeeDisplay})`}
                        <Tooltip
                          placement="bottom"
                          title="As compensation for the services performed by EverChain, Buyer shall pay EverChain a sales commission. 
    This sales commission is a percentage of the total sale price for each executed sale of a debt portfolio made with a 
    Seller via EverChain as displayed on the portfolio summary page within the EverChain platform."
                        >
                          <Icon
                            name="InfoOutlined"
                            fontSize="inherit"
                            color="black"
                            style={{ paddingLeft: '2px' }}
                          />
                        </Tooltip>
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        + {purchaseData.buyerFeeAmountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                  )}
                  {isPortfolioUk && purchaseData.buyerVATFeeDisplay && (
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        VAT Fee ({purchaseData.buyerVATFeePercent})
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        + {purchaseData.buyerVATFeeDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                  )}
                  {purchaseData.sellerFeeDisplay && (
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        Service Fee To EverChain (
                        {purchaseData.sellerFeeDisplay})
                      </TableCellFundingCustom>
                      <TableCellFundingCustom
                        style={{ color: 'red' }}
                        align="right"
                      >
                        - {purchaseData.sellerFeeAmountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                  )}
                  {purchaseData.buyerPurchaseAmountDisplay && (
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom
                        style={{ borderTop: '1px solid' }}
                        component="th"
                        scope="row"
                      >
                        <strong> Total Purchase Amount</strong>
                      </TableCellFundingCustom>
                      <TableCellFundingCustom
                        style={{ borderTop: '1px solid' }}
                        align="right"
                      >
                        <strong>
                          {purchaseData.buyerPurchaseAmountDisplay}
                        </strong>
                      </TableCellFundingCustom>
                    </TableRow>
                  )}
                  {purchaseData.netToSellerDisplay && (
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom
                        style={{ borderTop: '1px solid' }}
                        component="th"
                        scope="row"
                      >
                        <strong>
                          {isSeller ? 'Net Proceeds To You' : 'Net To Seller'} (
                          ({purchaseData.netToSellerPercentDisplay})
                        </strong>
                      </TableCellFundingCustom>
                      <TableCellFundingCustom
                        style={{ borderTop: '1px solid' }}
                        align="right"
                      >
                        <strong> {purchaseData.netToSellerDisplay}</strong>
                      </TableCellFundingCustom>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {!isPendingPSA && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" id="tableTitle" component="div">
                Funding to Seller
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        Bank
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {fundingToSeller?.bankName}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        Routing Number
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {fundingToSeller?.routingNumber}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        Account Number
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {fundingToSeller?.accountNumber}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        Amount Funded
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {purchaseData?.finalPurchasePriceAmountDisplay ??
                          purchaseData?.purchasePriceAmountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                country={profileClient?.Country}
                margin="normal"
                id="date-funded"
                label="Date Funded"
                value={dateFunded}
                onChange={handleDateChange}
                disabled={disableUI}
                style={{ marginTop: '0px' }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="input-wire-transfer-Seller"
                label="Wire Transfer #"
                onChange={handleSellerFundingTransactionNumber}
                value={sellerFundingTransactionNumber}
                disabled={disableUI}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="input-comment"
                label="Comment"
                onChange={handleSellerFundingComment}
                value={sellerFundingComment}
                disabled={disableUI}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" id="tableTitle" component="div">
                Funding to EverChain (Sales Commission)
                <Tooltip
                  placement="bottom"
                  title="As compensation for the services performed by EverChain, Buyer shall pay EverChain a sales commission. 
    This sales commission is a percentage of the total sale price for each executed sale of a debt portfolio made with a 
    Seller via EverChain as displayed on the portfolio summary page within the EverChain platform."
                >
                  <Icon
                    name="InfoOutlined"
                    style={{ fontSize: 'medium' }}
                    color="black"
                  />
                </Tooltip>
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        EverChain - Bank
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {fundingToDebtTrader?.bankName}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        EverChain - Routing Number
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {fundingToDebtTrader?.routingNumber}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow>
                      <TableCellFundingCustom component="th" scope="row">
                        EverChain - Account Number
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {fundingToDebtTrader?.accountNumber}
                      </TableCellFundingCustom>
                    </TableRow>
                    <TableRow
                      style={{ backgroundColor: gradientBackgroundColor }}
                    >
                      <TableCellFundingCustom component="th" scope="row">
                        Amount Funded
                      </TableCellFundingCustom>
                      <TableCellFundingCustom align="right">
                        {purchaseData?.buyerFeeAmountDisplay}
                      </TableCellFundingCustom>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <RemittanceInvoiceEmail />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="input-wire-transfer-Dt"
                label="Wire Transfer #"
                onChange={handleDtFundingTransactionNumber}
                value={dtFundingTransactionNumber}
                disabled={disableUI}
              />
            </Grid>
            <Grid item xs={12}>
              <Can
                do={
                  PermissionCodeAccess.MarketPlace_Basic_ConfirmPortfolioFunding
                }
                on="any"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    disableConfirmButtonFunction() ||
                    purchaseData.isBuyerPreApproved
                  }
                  onClick={(): void => onConfirmFunds()}
                >
                  Confirm Funds Sent
                </Button>
              </Can>
            </Grid>
          </>
        )}
      </Grid>
    </DTAccordion>
  )
}

export default ConfirmBuyerFunding
