import {
  AccordionSummary,
  MenuItem,
  Menu,
  TableCell,
  Typography,
  Chip,
  AccordionDetails,
  Box,
  Tab,
  Grid,
  Avatar,
  ToggleButton,
} from '@mui/material'
import PoundCircleFilled from '@ant-design/icons/PoundCircleFilled'
import styled from '@emotion/styled'
import { Colors, breakpoints, spacing } from 'everchain-uilibrary'

interface AggregatesFilterItemProps {
  selected?: boolean
}

interface ChipStatusErrorProps {
  type?: 'warning' | 'error'
}

interface CardText {
  primary?: boolean
}

interface DisplayAuctionContainerProps {
  screen?: 'large' | 'small'
}

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${spacing(6)};
`

export const HeaderAction = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .avatar-large {
    width: ${spacing(2)};
    height: ${spacing(2)};
  }
`

export const AvatarLarge = styled(Avatar)`
  width: ${spacing(2)};
  height: ${spacing(2)};
  margin-right: 1rem;
`
export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ArrowStep = styled.div`

.parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.step {
	font-size: 0.83rem;
    font-weight:500;
	text-align: center;
	color: ${Colors.black};
	cursor: default;
	margin: 0 3px;
	padding: 10px 10px 10px 30px;
	float: left;
	position: relative;
	background-color: ${Colors.secondary};
  
}

.step.current {
    color: ${Colors.white};
    background-color: ${Colors.primary};
    &:after {
        border-left: 17px solid ${Colors.primary};	
    }
}  

 &:after{
	content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 17px solid transparent; 
	border-left: 17px solid ${Colors.secondary};
	z-index: 1;

}
&:before{
    content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 17px solid ${Colors.secondary};
	z-index: 1;   
    right: auto;
	left: 0;
	border-left: 17px solid ${Colors.white};	
	z-index: 0;    
}
&:first-child:before {
	border: none;
}
&:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

span {
	position: relative;
    &:before {
        opacity: 0;      
        position: absolute;
        top: -2px;
        left: -20px;
    }
}

}
`

export const PortfolioDetailStep = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .stepName {
    font-size: 0.83rem;
  }
`

export const UploadStepper = styled.div`
  padding: ${`${spacing(2.5)} ${spacing(4)}`};
`

export const UploadStepperHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing(1.5)};
  min-height: 40px;
  transition: all 100ms ease;
`

export const AggregatesFilter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const AggregatesFilterItem = styled.button<AggregatesFilterItemProps>`
  border: none;
  outline: none;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem 0.5rem;
  transition: all 0.6s ease;
  color: ${({ selected }): string =>
    selected ? Colors.primary : Colors.primary};
  background-color: ${({ selected }): string =>
    selected ? Colors.secondary : Colors.white};
  cursor: pointer;
  display: block;
  width: 100%;
`

export const GridStyle = styled(Grid)`
  border-right: 1px solid ${Colors.gray};
  &:last-child {
    border-right: none;
  }
`

export const TooltipTitleStep = styled.span`
  display: block;
  margin-bottom: ${spacing(0.3)};
`
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChipStatusError = styled(Chip)<ChipStatusErrorProps>`
  background-color: ${({ type }) =>
    (type === 'warning' && Colors.warning) ||
    (type === 'error' && Colors.error) ||
    Colors.gray};
`

export const CardTypography = styled(Typography)<CardText>`
  font-size: ${({ primary }): string => (primary ? '0.875rem' : '0.75rem')};
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: ${({ primary }): number => (primary ? 1.57 : 1.66)};
  letter-spacing: ${({ primary }): string =>
    primary ? '0.00714em' : '0.03333em'};
  font-weight: ${({ primary }): number => (primary ? 700 : 400)};
`

export const AccordionDetailsCustom = styled(AccordionDetails)`
  padding: 0;
`

export const TabCustom = styled(Tab)`
  text-transform: none;
`

export const BoxBackgroundLight = styled.div`
  background-color: ${Colors.secondary};
`

export const ModalHeader = styled(Box)`
  background-color: ${Colors.primary};
`

export const TableCellFundingCustom = styled(TableCell)`
  border-bottom: none;
  padding-bottom: 5px;
  padding-top: 5px;
`

export const BidDialogText = styled(Box)`
  color: ${Colors.primary};
  font-size: 1rem;
`

export const CollectionActivitySection = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  padding-top: 5px;
`

export const CollectionActivityLabelBox = styled(Box)`
  background-color: ${Colors.primary};
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 9rem;
`

export const CollectionActivityContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CollectionActivityCardTitle = styled(Typography)`
  .h6 {
    padding-top: 15px;
  }
`

export const WrapperDialog = styled.div`
  .k-dialog-content.k-dialog-content {
    padding-left: 0;
    padding-right: 0;
    .k-toolbar .k-grid-toolbar {
      padding: 10px;
    }
  }
`

export const AccordionSummaryCustom2 = styled(AccordionSummary)`
  justify-content: space-between;
  .MuiAccordionSummary-content.MuiAccordionSummary-content {
    flex-grow: inherit;
  }
  font-size: 1rem;
  margin: 0px;
`

export const ToggleButtonCustom = styled(ToggleButton)`
  background-color: ${Colors.primary};
  border: 1px solid ${Colors.primary};
  color: ${Colors.white};

  &.Mui-selected {
    background-color: ${Colors.white};
    color: ${Colors.primary};
  }
  &:hover {
    color: ${Colors.primary};
  }
  &.Mui-disabled {
    &.Mui-selected {
      background-color: ${Colors.white};
      color: ${Colors.primary};
    }

    color: ${Colors.white};
  }
`
export const AccordionTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
`
export const TypographyDate = styled(Typography)`
  font-size: 17px;
`

export const MenuStyled = styled(Menu)`
  & .MuiPaper-root {
    background-color: ${Colors.primary};
  }
  & ul {
    padding: 0px;
  }
`
export const MenuItemStyled = styled(MenuItem)`
  &:hover {
    background-color: #154c5b;
  }
`

export const PoundCircleFilledStyled = styled(PoundCircleFilled)`
  padding-left: 3px;
  font-size: large;
  padding-top: 1px;
`

export const DisplayAuctionContainer = styled.div<DisplayAuctionContainerProps>`
  display: ${(props) => (props.screen === 'large' ? 'flex' : 'none')};

  ${breakpoints.down('lg')} {
    display: ${(props) => (props.screen === 'large' ? 'none' : 'flex')};
  }
`
