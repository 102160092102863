export const BASE_ROUTE = '/market'

export const HOME = '/'
export const PORTFOLIO = '/portfolio'
export const PORTFOLIO_TEMPLATE = `${PORTFOLIO}/template`
export const LOAD_PORTFOLIO = '/load-portfolio'
export const PORTFOLIO_DETAIL = `${PORTFOLIO}/detail`
export const LOGIN = '/login'
export const FORWARD_FLOW = '/forward-flow'
export const FORWARD_FLOW_LIST = `${FORWARD_FLOW}/list`
export const FORWARD_FLOW_DETAIL = `${FORWARD_FLOW}/detail`
export const FORWARD_FLOW_INFO = `${FORWARD_FLOW}/info`
export const FORWARD_FLOW_CLONE = `${FORWARD_FLOW}/clone`
export const FORWARD_FLOW_INFO_SELLER_TEMPLATE = `${FORWARD_FLOW}/seller-template`
export const BUYER_DETAIL = '/buyer-detail'
export const ACCOUNT_DETAIL = '/account-detail'
export const SELLER_DETAIL = '/seller-detail'
export const AGENCY_DETAIL = '/agency-detail'
export const PORTFOLIO_ANALYTICS = '/portfolio-analytics'
export const SEARCH = '/search'
export const ACCESSDENIED = '/accessdenied'
export const PORTFOLIO_VALIDATION = '/portfolio-validation'

export const POSTSALE = '/manage'
export const POST_SALE_DETAIL = `${POSTSALE}/request`
export const COMPLIANCE = '/comply'
export const COMPLIANCE_LIST = `${COMPLIANCE}/list`
export const COMPLIANCE_INFO = `${COMPLIANCE}/info`
export const COMPLIANCE_NEW = `${COMPLIANCE}/info/new`
export const CREATE_COMPLAINT = `${COMPLIANCE}/create`
export const COMPLAINT_DASHBOARD = `${COMPLIANCE}/dashboard`
export const FUNDING = '/funding'
export const CREATE_REQUEST = `${POSTSALE}/request/create`
export const CREATE_REQUEST_INPUTMETADATA = `${POSTSALE}/request/create/input-metadata`
export const BULK_ASSIGN = `${POSTSALE}/request/bulk-assign`
export const BULK_RESPONSE_REQUEST = `${POSTSALE}/request/bulk-response`
export const BULK_WITHDRAW = `${POSTSALE}/request/withdraw`
export const BATCH_ATTACHMENT_REQUEST = `${POSTSALE}/request/batch-attachment`
export const DASHBOARD = '/dashboard'
export const POST_SALE_REPORT = `${POSTSALE}/report`
export const PORTFOLIO_REPORT = '/portfolio-report'

export const ACCOUNT_PLACEMENT = '/account-placement'
export const ACCOUNT_PLACEMENT_INPUTMETADATA =
  '/account-placement/input-metadata'
export const DIGITAL_SELLER_SURVEY = '/digital-seller-survey'
export const DIGITAL_SELLER_SURVEY_DETAIL = `${DIGITAL_SELLER_SURVEY}/detail`
export const EXTERNAL_DIGITAL_SELLER_SURVEY_DETAIL =
  '/external-digital-seller-survey/detail'
export const SURVEY_BUILDER = '/survey-builder'

export const KNOWLEDGE_BASE = 'https://kb.everchain.com/knowledge'
export const HELP_REQUEST = 'https://kb.everchain.com/knowledge/kb-tickets/new'
