import { Grid, Typography, Box } from '@mui/material'
import React from 'react'
import { TypographyWhite } from 'src/styles/layout'
import { formatDate } from 'src/utils/date'
import { numberCurrencyDollar, numberToPercentage } from 'src/utils/numbers'
import { TypographyDate } from '../styles'
import { Colors } from 'everchain-uilibrary'

interface BuyerPlaceBidDisplayProps {
  portfolioCountry: string | undefined
  hasOneTimeBid: boolean
  oneTimeBidPercent: number
  oneTimeFinalBidPercent: string
  oneTimeBidCloseDate: Date
  oneTimeBidFundingDate: Date
  oneTimePurchaseProceeds: number
  oneTimeBidSalesComission: number
  oneTimeBidTotalPrice: number
  oneTimeBidContingency: string | undefined
  hasForwardFlowBid: boolean
  forwardFlowBidPercent: number
  forwardFlowFinalBidPercent: string
  forwardFlowBidCloseDate: Date
  forwardFlowBidFundingDate: Date
  forwardFlowPurchaseProceeds: number
  forwardFlowBidSalesComission: number
  forwardFlowBidTotalPrice: number
  forwardFlowBidContingency: string
  forwardFlowTerms: string
  forwardFlowTermLabel: string
  forwardFlowMaxFaceValue: number
  forwardFlowMaxFaceLabel: string
  bkBidNumberOfAccounts: number | undefined
  bkTocNumberOfAccounts: number | undefined
  bkTocTotalAmount: number | undefined
}

const BuyerPlaceBidDisplay: React.FC<BuyerPlaceBidDisplayProps> = ({
  portfolioCountry,
  hasOneTimeBid,
  oneTimeBidPercent,
  oneTimeFinalBidPercent,
  oneTimeBidCloseDate,
  oneTimeBidFundingDate,
  oneTimeBidSalesComission,
  oneTimePurchaseProceeds,
  oneTimeBidTotalPrice,
  oneTimeBidContingency,
  hasForwardFlowBid,
  forwardFlowBidPercent,
  forwardFlowFinalBidPercent,
  forwardFlowBidCloseDate,
  forwardFlowBidFundingDate,
  forwardFlowBidSalesComission,
  forwardFlowPurchaseProceeds,
  forwardFlowBidTotalPrice,
  forwardFlowBidContingency,
  forwardFlowTerms,
  forwardFlowTermLabel,
  forwardFlowMaxFaceValue,
  forwardFlowMaxFaceLabel,
  bkBidNumberOfAccounts,
  bkTocNumberOfAccounts,
  bkTocTotalAmount,
}: BuyerPlaceBidDisplayProps) => {
  const gridControl: any = (
    title: string,
    bidPercent: number,
    finalBidPercent: number,
    closeDate: Date,
    fundingDate: Date,
    salesCommission: number,
    purchaseProceeds: number,
    totalPrice: number,
    contingency: string,
    ffTerms: string,
    ffMaxValue: number
  ) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{title}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2">
              Bid Percent
            </Typography>
            <TypographyWhite variant="h6">
              {numberToPercentage(bidPercent, '', 3)}
            </TypographyWhite>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2">
              Close Date
            </Typography>
            <TypographyDate color="inherit" variant="subtitle1">
              {closeDate ? formatDate(closeDate) : 'N/A'}
            </TypographyDate>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2">
              Funding Date
            </Typography>
            <TypographyDate color="inherit" variant="subtitle1">
              {fundingDate ? formatDate(fundingDate) : 'N/A'}
            </TypographyDate>
          </Box>
        </Grid>
        {ffTerms && forwardFlowTermLabel && (
          <Grid item xs={2}>
            <Box
              style={{
                backgroundColor: Colors.primary,
                color: Colors.white,
                height: '100%',
              }}
              p={2}
            >
              <Typography color="inherit" variant="subtitle2">
                {forwardFlowTermLabel}
              </Typography>
              <TypographyWhite variant="h6">{ffTerms}</TypographyWhite>
            </Box>
          </Grid>
        )}
        {ffMaxValue && (
          <Grid item xs={2}>
            <Box
              style={{
                backgroundColor: Colors.primary,
                color: Colors.white,
                height: '100%',
              }}
              p={2}
            >
              <Typography color="inherit" variant="subtitle2">
                {forwardFlowMaxFaceLabel}
              </Typography>
              <TypographyWhite variant="h6">
                {numberCurrencyDollar(ffMaxValue, portfolioCountry)}
              </TypographyWhite>
            </Box>
          </Grid>
        )}
        <Grid item xs={2}>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2">
              Purchase Proceeds
            </Typography>
            <TypographyWhite variant="h6">
              {bkTocTotalAmount && bkTocTotalAmount >= 0.001
                ? numberCurrencyDollar(
                    purchaseProceeds + bkTocTotalAmount,
                    portfolioCountry
                  )
                : numberCurrencyDollar(purchaseProceeds, portfolioCountry)}
            </TypographyWhite>
          </Box>
        </Grid>
        {bkBidNumberOfAccounts && (
          <Grid item xs={2}>
            <Box
              style={{
                backgroundColor: Colors.primary,
                color: Colors.white,
                height: '100%',
              }}
              p={2}
            >
              <Typography color="inherit" variant="subtitle2">
                Total number of Accounts
              </Typography>
              <TypographyWhite variant="h6">
                {bkBidNumberOfAccounts}
              </TypographyWhite>
            </Box>
          </Grid>
        )}
        {bkTocTotalAmount && bkTocNumberOfAccounts && (
          <>
            <Grid item xs={2}>
              <Box
                style={{
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  height: '100%',
                }}
                p={2}
              >
                <Typography color="inherit" variant="subtitle2">
                  Total TOC ({bkTocNumberOfAccounts} x $
                  {numberCurrencyDollar(
                    bkTocTotalAmount / bkTocNumberOfAccounts,
                    portfolioCountry
                  )}{' '}
                  )
                </Typography>
                <TypographyWhite variant="h6">
                  {numberCurrencyDollar(bkTocTotalAmount, portfolioCountry)}
                </TypographyWhite>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                style={{
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  height: '100%',
                }}
                p={2}
              >
                <Typography color="inherit" variant="subtitle2">
                  Final Purchase Proceeds
                </Typography>
                <TypographyWhite variant="h6">
                  {numberCurrencyDollar(purchaseProceeds, portfolioCountry)}
                </TypographyWhite>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                style={{
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  height: '100%',
                }}
                p={2}
              >
                <Typography color="inherit" variant="subtitle2">
                  Purchase Proceeds Percent
                </Typography>
                <TypographyWhite variant="h6">
                  {finalBidPercent}
                </TypographyWhite>
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={2}>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2">
              Sales Commission
            </Typography>
            <TypographyWhite variant="h6">
              {numberCurrencyDollar(salesCommission, portfolioCountry)}
            </TypographyWhite>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            style={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              height: '100%',
            }}
            p={2}
          >
            <Typography color="inherit" variant="subtitle2">
              Total Price
            </Typography>
            <TypographyWhite variant="h6">
              {numberCurrencyDollar(totalPrice, portfolioCountry)}
            </TypographyWhite>
          </Box>
        </Grid>
        {contingency && (
          <Grid item xs={12}>
            <Box
              style={{
                backgroundColor: Colors.primary,
                color: Colors.white,
              }}
              p={2}
            >
              <Typography color="inherit" variant="subtitle2">
                Bid Contingency
              </Typography>
              <Typography color="inherit" variant="body2">
                {contingency}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <>
      {hasOneTimeBid &&
        gridControl(
          'One-Time Bid',
          oneTimeBidPercent,
          oneTimeFinalBidPercent,
          oneTimeBidCloseDate,
          oneTimeBidFundingDate,
          oneTimeBidSalesComission,
          oneTimePurchaseProceeds,
          oneTimeBidTotalPrice,
          oneTimeBidContingency
        )}
      {hasForwardFlowBid &&
        gridControl(
          'Forward Flow Bid',
          forwardFlowBidPercent,
          forwardFlowFinalBidPercent,
          forwardFlowBidCloseDate,
          forwardFlowBidFundingDate,
          forwardFlowBidSalesComission,
          forwardFlowPurchaseProceeds,
          forwardFlowBidTotalPrice,
          forwardFlowBidContingency,
          forwardFlowTerms,
          forwardFlowMaxFaceValue
        )}
    </>
  )
}

export default BuyerPlaceBidDisplay
