/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'
import { Box, Tooltip, Typography, Skeleton } from '@mui/material'

import {
  BUYER_DETAIL,
  PORTFOLIO_DETAIL,
  SELLER_DETAIL,
  AGENCY_DETAIL,
} from 'src/routes'

import { useHistory } from 'react-router-dom'
import { renderDate } from 'src/utils/formatKendoColumns'
import { DataTable, Icon } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'
import { AccountChainOfTitle } from 'src/data/features/get/account/types'

interface ChainOfTitleProps {
  getAccountChainOfTitle?: AccountChainOfTitle[]
  loading: boolean
}

const ChainOfTitle = ({
  getAccountChainOfTitle,
  loading,
}: ChainOfTitleProps) => {
  const [chainOfTitles, setChainOfTitles] = useState<
    AccountChainOfTitle[] | undefined
  >([])

  const expandRowChange = (dataItem: any) => {
    const newItem: AccountChainOfTitle = {
      id: dataItem.id,
      createdDate: dataItem.createdDate,
      ratio: dataItem.ratio,
      title: dataItem.title,
      recordType: dataItem.recordType,
      portfolioId: dataItem.portfolioId,
      portfolioNumber: dataItem.portfolioNumber,
      businessType: dataItem.businessType,
      buyerId: dataItem.buyerId,
      details: dataItem.chainOfAssignments,
      expanded: !dataItem.expanded,
    }
    const indexToRemove =
      chainOfTitles?.findIndex((f) => f.id === dataItem.id) || 0

    const oldData = chainOfTitles?.filter((f) => f.id !== dataItem.id)
    oldData?.splice(indexToRemove, 0, newItem)
    const newData = oldData

    setChainOfTitles(newData)
  }

  const renderExpanded = (props: any) => {
    const { dataItem } = props

    if (dataItem.chainOfAssignments?.length === 0) {
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      return <td className="k-hierarchy-cell" />
    }
    return (
      <td style={{ cursor: 'pointer' }} className="k-hierarchy-cell">
        <Typography onClick={() => expandRowChange(dataItem)}>
          {dataItem.expanded ? '-' : '+'}
        </Typography>
      </td>
    )
  }

  const history = useHistory()

  const dataColumnsDetail = [
    {
      title: 'Record Type',
      field: 'recordType',
      show: true,
    },

    {
      title: 'Business',
      field: 'business',
      show: true,
      render: (props: any) => (
        <td>
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(
                getStandardUri(
                  `${AGENCY_DETAIL}/${props.dataItem['businessId']}`
                )
              )
            }
          >
            {props.dataItem['business']}
          </Typography>
        </td>
      ),
    },
    {
      title: 'Created Date',
      field: 'createdDate',
      render: renderDate,
      show: true,
    },
    {
      title: 'Effective  Date',
      field: 'effectiveDate',
      render: renderDate,
      show: true,
    },
    {
      title: 'Account Complaint Ratio',
      field: 'ratio',
      show: true,
      render: (props: any) => (
        <>
          {props.dataItem['ratio'] === 'N/A' ? (
            <td>
              <Tooltip
                title="Due to low volume of accounts purchased the account complaint ratio is not calculated."
                aria-label={props.title}
              >
                <Typography>{props.dataItem['ratio']}</Typography>
              </Tooltip>
            </td>
          ) : (
            <td>
              <Typography>{props.dataItem['ratio']}</Typography>
            </td>
          )}
        </>
      ),
    },
  ]

  const ChainOfAssignmentsDetails = (props: any) => {
    const { dataItem } = props
    const sortedDetails = [...dataItem.details].sort((a, b) => {
      const dateA = a.effectiveDate ? new Date(a.effectiveDate).getTime() : 0
      const dateB = b.effectiveDate ? new Date(b.effectiveDate).getTime() : 0

      return dateB - dateA
    })

    return (
      <DataTable
        style={{ width: '100%' }}
        data={sortedDetails}
        gridColumns={dataColumnsDetail}
      />
    )
  }

  useEffect(() => {
    setChainOfTitles(getAccountChainOfTitle)
  }, [getAccountChainOfTitle])

  if (loading) {
    return (
      <Box p={2}>
        <Skeleton width="100%" height={50} variant="rectangular" />
      </Box>
    )
  }

  const dataColumns = [
    {
      title: ' ',
      field: 'expanded',
      show: true,
      render: renderExpanded,
      width: 40,
    },
    {
      title: 'Record Type',
      field: 'recordType',
      show: true,
      render: (props: any) =>
        props.dataItem['removedReason'] ? (
          <td>
            <Tooltip title={props.dataItem['removedReason']}>
              <Box
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                {props.dataItem['recordType']}
                <Icon name="Warning" style={{ color: 'orange' }} />
              </Box>
            </Tooltip>
          </td>
        ) : (
          <td>{props.dataItem['recordType']}</td>
        ),
    },
    {
      title: 'Business',
      field: 'title',
      show: true,
      render: (props: any) => (
        <td>
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (props.dataItem['businessType'] === 'Buyer') {
                history.push(
                  getStandardUri(`${BUYER_DETAIL}/${props.dataItem['buyerId']}`)
                )
              } else {
                history.push(
                  getStandardUri(
                    `${SELLER_DETAIL}/${props.dataItem['buyerId']}`
                  )
                )
              }
            }}
          >
            {props.dataItem['title']}
          </Typography>
        </td>
      ),
    },
    {
      title: 'portfolioId',
      field: 'portfolioId',
      show: false,
    },
    {
      title: 'PID',
      field: 'portfolioNumber',
      show: true,
      render: (props: any) => (
        <td>
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(
                getStandardUri(
                  `${PORTFOLIO_DETAIL}/${props.dataItem['portfolioId']}`
                )
              )
            }
          >
            {props.dataItem['portfolioNumber']}
          </Typography>
        </td>
      ),
    },
    {
      title: 'Created Date',
      field: 'createdDate',
      render: renderDate,
      show: true,
    },
    {
      title: 'Account Complaint Ratio',
      field: 'ratio',
      show: true,
      render: (props: any) => (
        <>
          {props.dataItem['ratio'] === 'N/A' ? (
            <td>
              <Tooltip
                title="Due to low volume of accounts purchased the account complaint ratio is not calculated."
                aria-label={props.title}
              >
                <Typography>{props.dataItem['ratio']}</Typography>
              </Tooltip>
            </td>
          ) : (
            <td>
              <Typography>{props.dataItem['ratio']}</Typography>
            </td>
          )}
        </>
      ),
    },
  ]

  return (
    <Box p={2}>
      <DataTable
        style={{
          width: '100%',
        }}
        data={chainOfTitles}
        detail={ChainOfAssignmentsDetails}
        expandField="expanded"
        gridColumns={dataColumns}
      />
    </Box>
  )
}

export default ChainOfTitle
