/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-curly-newline */
import React, { useState, useCallback, useContext, useRef } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Paper, Box, Button, MenuItem, IconButton, Menu } from '@mui/material'
import { useSnackbar } from 'notistack'

import { forwardFlowsMutation } from 'src/graphql/operations/mutations'

import Header from 'src/components/Header'
import { Content } from 'src/styles/layout'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  FORWARD_FLOW_CLONE,
  FORWARD_FLOW_DETAIL,
  FORWARD_FLOW_INFO,
  FORWARD_FLOW_LIST,
} from 'src/routes'

import { INTERNAL } from 'src/configs/AuthService'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import {
  renderLeftNumberLink,
  renderCurrency,
  renderDate,
  renderNumber,
  renderPercent,
} from 'src/utils/formatKendoColumns'
import Cards from './components/Cards'
import Loader from '../../components/Loader/Loader'
import ExportExcelButton from 'src/components/ExportExcel'
import { formatDataToExport, getStandardUri } from 'src/utils/common'
import { getForwardFlowAgreementCardData } from 'src/data/features/get/forwardFlow/forwardFlow'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { useQueryClient } from '@tanstack/react-query'
import { DataTable, DataTableState, Icon } from 'everchain-uilibrary'

interface RowProps {
  [field: string]: any
}

interface ActionsMenu {
  onClick: (row: RowProps) => void
  name: string
  loading?: boolean
}

interface CellTableActionsProps {
  rowProps: any
  actionsMenu: ActionsMenu[]
}

const CellTableActions: React.FC<CellTableActionsProps> = ({
  rowProps,
  actionsMenu,
}: CellTableActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleOpenMenuItem = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClose = (): void => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton
        aria-label="flow actions"
        aria-controls="flow-menu-actions"
        aria-haspopup="true"
        onClick={handleOpenMenuItem}
        size="small"
      >
        <Icon name="MoreVert" />
      </IconButton>
      {actionsMenu?.length && (
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleMenuItemClose}
        >
          {actionsMenu.map((menuItem) => (
            <MenuItem
              key={menuItem.name}
              disabled={menuItem.loading}
              onClick={() => {
                menuItem.onClick(rowProps)
                handleMenuItemClose()
              }}
            >
              {menuItem.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

const notistackSuccess = notistackOptions('success')

const ForwardFlow: React.FC = () => {
  const { status }: any = useParams()
  const grid = useRef<any>(null)
  const [cardSelected, setCardSelected] = useState(status)

  const ActionColumn = (props: any): React.ReactElement => {
    return (
      <td style={{ cursor: 'default' }}>
        <div style={{ textAlign: 'center' }}>
          <CellTableActions rowProps={props} actionsMenu={actions} />
        </div>
      </td>
    )
  }
  const gridColumns: any[] = [
    {
      title: 'ID',
      field: 'id',
      width: '70px',
      render: (props: any) => {
        return renderLeftNumberLink(
          props,
          getStandardUri(`${FORWARD_FLOW_DETAIL}/`)
        )
      },
      show: true,
    },
    {
      field: 'sellerName',
      title: 'Seller Name',
      width: '150px',
      show: true,
    },
    {
      field: 'buyerName',
      title: 'Buyer Name',
      width: '150px',
      show: true,
    },
    {
      field: 'startDate',
      title: 'Start Date',
      width: '100px',
      render: renderDate,
      filter: 'date',
      show: true,
    },
    {
      field: 'endDate',
      title: 'End Date',
      width: '100px',
      render: renderDate,
      filter: 'date',
      show: true,
    },
    {
      field: 'purchasePricePercent',
      title: 'Purchase Price %',
      width: '120px',
      render: renderPercent,
      filter: 'numeric',
      show: true,
    },
    {
      field: 'dealsClosed',
      title: 'Deals Closed',
      width: '100px',
      render: renderNumber,
      filter: 'numeric',
      show: true,
    },
    {
      field: 'dealsRemaining',
      title: 'Deals Remaining',
      width: '120px',
      render: renderNumber,
      show: true,
      filter: 'numeric',
    },
    {
      field: 'averageFacevalue',
      title: 'Avg. FV',
      width: '90px',
      show: true,
      render: (props: any) => {
        return renderCurrency(props, null, countryCode)
      },
      filter: 'numeric',
    },
    {
      field: 'averageBalance',
      title: 'Avg. Balance',
      width: '130px',
      render: (props: any) => {
        return renderCurrency(props, null, countryCode)
      },
      filter: 'numeric',
      show: true,
    },
    {
      field: 'maxFaceValue',
      title: 'Max FV',
      width: '90px',
      show: true,
      render: (props: any) => {
        return renderCurrency(props, null, countryCode)
      },
      filter: 'numeric',
    },
    {
      field: '',
      title: 'Actions',
      width: '60px',
      render: ActionColumn,
      show: true,
    },
  ]

  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: [{ field: 'id', dir: 'desc' }],
  })

  const { userPermissions, profileClient } = useContext(AuthContext)
  const countryCode = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const reactQueryClient = useQueryClient()
  const [forwardFlowAgreement, setForwardFlowAgreement] = useState<any>()

  const isInternal = userPermissions.type === INTERNAL
  const { useDeactivateForwardFlow } = forwardFlowsMutation
  const ability = useContext(AbilityContext)
  const { deactivateForwardFlow, loading: deactivateLoading } =
    useDeactivateForwardFlow({
      onCompleted: (data: any) => {
        if (data.deactivateForwardFlow) {
          enqueueSnackbar(data.deactivateForwardFlow, notistackSuccess)

          reactQueryClient.refetchQueries({
            queryKey: ['getForwardFlowAgreementCards'],
          })

          refetch()
        }
      },
    })

  // eslint-disable-next-line no-unused-vars
  const { isFetching: isLoading, refetch } = useCustomQuery<any>(
    ['getForwardFlowAgreementCardData', gridState, cardSelected],
    async () => {
      const result = await getForwardFlowAgreementCardData(
        cardSelected,
        JSON.stringify(gridState)
      )

      setForwardFlowAgreement(result)
    },
    {
      cacheTime: 0,
    }
  )

  const handleCardsSelected = useCallback(
    (type: any) => {
      setCardSelected(type)
      history.push(getStandardUri(`${FORWARD_FLOW_LIST}/${type}`))
    },
    [history]
  )

  const actions = [
    {
      name: 'View Detail',
      onClick: (tableCell: any) => {
        history.push(
          getStandardUri(`${FORWARD_FLOW_DETAIL}/${tableCell.dataItem.id}`)
        )
      },
      loading: false,
    },
  ]

  if (
    userPermissions.type === INTERNAL &&
    ability.can(PermissionCodeAccess.MarketPlace_ForwardFlow, 'any') &&
    actions.length == 1
  ) {
    actions.push(
      {
        name: 'Deactivate',
        onClick: (tableCell: any) => {
          deactivateForwardFlow({
            variables: {
              forwardFlowId: tableCell.dataItem.id,
            },
          })
        },
        loading: deactivateLoading,
      },
      {
        name: 'Edit',
        onClick: (tableCell: any) => {
          history.push(
            getStandardUri(`${FORWARD_FLOW_INFO}/${tableCell.dataItem.id}`)
          )
        },
        loading: false,
      },
      {
        name: 'Clone',
        onClick: (tableCell: any) => {
          history.push(
            getStandardUri(`${FORWARD_FLOW_CLONE}/${tableCell.dataItem.id}`)
          )
        },
        loading: false,
      }
    )
  }

  const totalOfForwardFlows = forwardFlowAgreement?.totalForwardFlow || 0

  return (
    <>
      <Content>
        <Header title="Forward Flow Manager" />
      </Content>
      <Cards selected={cardSelected} onSelect={handleCardsSelected} />
      <Content style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <ExportExcelButton
              disabled={isLoading}
              data={formatDataToExport(
                forwardFlowAgreement?.forwardFlowData || null,
                gridColumns,
                profileClient
              )}
              fileName={'Forward Flow'}
            />
          </Box>
          {isInternal &&
            ability.can(
              PermissionCodeAccess.MarketPlace_ForwardFlow,
              'any'
            ) && (
              <Box ml={3}>
                <Button
                  disabled={isLoading}
                  type="button"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={getStandardUri(FORWARD_FLOW_INFO)}
                >
                  Create
                </Button>
              </Box>
            )}
        </Box>
      </Content>
      <Content>
        <Paper>
          {isLoading ? (
            <Loader specificHeight="50%" />
          ) : (
            <Box p={1}>
              <DataTable
                ref={grid}
                total={totalOfForwardFlows}
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sortable
                useFilterMenu
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                sort={gridState.sort}
                style={{
                  height: '70%',
                  maxHeight: '55vh',
                }}
                data={forwardFlowAgreement?.forwardFlowData || []}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
                gridColumns={gridColumns}
              />
            </Box>
          )}
        </Paper>
      </Content>
    </>
  )
}

export default ForwardFlow
