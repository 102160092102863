import moment from 'moment-timezone'
import * as XLSX from 'xlsx'

export interface FileParseResult {
  passedValidation: boolean
  errorMessage: string
  data: any[]
}

export interface ColumnMetadata {
  name: string
  type: string
  isRequired: boolean
  length: number
  domain?: string[]
  substituteFields?: string[]
}

export interface DataError {
  rowIndex: number
  errorMessage: string
}

export const ParseXlsxFile = async (
  file: Blob,
  columns: ColumnMetadata[]
): Promise<FileParseResult> => {
  return new Promise((resolve, reject) => {
    const headers = columns.map((x) => x.name)
    const reader = new FileReader()

    let sheetData: any[] = []
    let response: FileParseResult = {
      passedValidation: true,
      errorMessage: '',
      data: [],
    }

    // The event onload needs to be declared first
    // After, we read the file as binarystring
    // It will trigger the onload event
    reader.onload = (e: any) => {
      const data = e.target.result

      // Loading file data
      const workbook = XLSX.read(data, { cellDates: true })

      // Getting first sheet
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      // Converting data to json
      sheetData = XLSX.utils.sheet_to_json(sheet)

      const sheetDataWithHeader = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        raw: true,
      })

      const validArray: any[] = sheetDataWithHeader.filter(
        (d: any) => d.length > 0
      )

      // DATA VALIDATION
      // If there is no data in the file
      if (sheetData.length === 0) {
        response = {
          passedValidation: false,
          errorMessage: 'File contains no data',
          data: [],
        }
        return resolve(response)
      }

      // Validate if contains headers
      const notFound =
        validArray &&
        headers.filter((key) => {
          return !Object.values(validArray[0]).find(
            (x: any) => x.toLowerCase().trim() === key.toLowerCase().trim()
          )
        })

      // Validate if there is any data beside header
      if (sheetDataWithHeader.length === 1) {
        response = {
          passedValidation: false,
          errorMessage: 'File contains no data',
          data: [],
        }
        return resolve(response)
      }

      if (notFound.length > 0) {
        response = {
          passedValidation: false,
          errorMessage: `Header(s) not found ${notFound.join(', ')}`,
          data: [],
        }
        return resolve(response)
      }

      for (let i = 0; i < sheetData.length; i++) {
        sheetData[i].rowIndex = i + 1
      }

      // returning the data
      response.data = sheetData
      return resolve(response)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

export const ParseXlsxFileDynamicColumns = async (
  file: Blob
): Promise<FileParseResult> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    let sheetData: any[] = []
    let response: FileParseResult = {
      passedValidation: true,
      errorMessage: '',
      data: [],
    }

    // The event onload needs to be declared first
    // After, we read the file as binarystring
    // It will trigger the onload event
    reader.onload = (e: any) => {
      const data = e.target.result
      const readOpts = {
        // <--- need these settings in readFile options
        cellText: false,
        cellDates: true,
      }

      const jsonOpts = {
        defval: '',
        blankrows: false,
        raw: false,
        dateNF: 'd"/"m"/"yyyy', // <--- need dateNF in sheet_to_json options (note the escape chars)
      }

      // Loading file data
      const workbook = XLSX.read(data, readOpts)

      // Getting first sheet
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      // Converting data to json
      sheetData = XLSX.utils.sheet_to_json(sheet, jsonOpts)

      // DATA VALIDATION
      // If there is no data in the file
      if (sheetData.length === 0) {
        response = {
          passedValidation: false,
          errorMessage: 'File contains no data',
          data: [],
        }
        return resolve(response)
      }

      // Validate if there is any data beside header
      if (sheetData.length === 1) {
        response = {
          passedValidation: false,
          errorMessage: 'File contains no data',
          data: [],
        }
        return resolve(response)
      }

      for (let i = 0; i < sheetData.length; i++) {
        sheetData[i].rowIndex = i
      }

      // returning the data
      response.data = sheetData
      return resolve(response)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

const UkDateFormats = [
  'DD/MM/YYYY',
  'D/M/YYYY',
  'DD.MM.YYYY',
  'D.M.YYYY',
  'DD-MM-YYYY',
  'D-M-YYYY',
  'YYYY-MM-DD',
]

const DefaultDateFormats = [
  'MM/DD/YYYY',
  'M/DD/YYYY',
  'MM.DD.YYYY',
  'M.D.YYYY',
  'MM-DD-YYYY',
  'M-D-YYYY',
  'YYYY-MM-DD',
]

const getDateFormats = (country: string) => {
  if (country === 'uk') return UkDateFormats

  return DefaultDateFormats
}

const isValidDate = (value: any, country: string) => {
  if (value instanceof Date && !isNaN(Number(value))) return true
  if (!isNaN(Number(value))) return false

  return moment(value, getDateFormats(country)).isValid()
}

const typesValidations: any = {
  date: (value: any, country: string, length: number) => {
    return isValidDate(value, country)
      ? {
          isValid: true,
          isLengthValid: true,
          value: moment(value, getDateFormats(country)).toDate(),
          isDomainValid: true,
        }
      : { isValid: false, isLengthValid: true, value, isDomainValid: true }
  },
  number: (value: any, country: string, length: number) => {
    return !isNaN(Number(value))
      ? {
          isValid: true,
          isLengthValid: true,
          value: Number(value),
          isDomainValid: true,
        }
      : { isValid: false, isLengthValid: true, value, isDomainValid: true }
  },
  string: (value: any, country: string, length: number, domain: string) => {
    if (['null', 'NULL'].includes(value))
      return { isValid: false, isLengthValid: true, value, isDomainValid: true }
    if (value !== undefined && value.length > length)
      return {
        isValid: false,
        isLengthValid: false,
        value: value.toString(),
        isDomainValid: true,
      }
    if (domain && !domain.includes(value.toLowerCase().trim()))
      return {
        isValid: false,
        isLengthValid: true,
        isDomainValid: false,
        value: value.toString(),
      }

    return { isValid: true, value: value.toString() }
  },
  guid: (value: any, country: string, length: number) => {
    return value.match(
      '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
    )
      ? {
          isValid: true,
          isLengthValid: true,
          value,
          isDomainValid: true,
        }
      : { isValid: false, isLengthValid: true, value, isDomainValid: true }
  },
}

const validateRow = (
  row: any,
  columnMetadata: ColumnMetadata[],
  rowIndex: number,
  country: string
) => {
  const errors: DataError[] = []
  const rowConverted: any = row

  columnMetadata.forEach(
    ({
      name,
      type,
      isRequired,
      length,
      domain,
      substituteFields,
    }: ColumnMetadata) => {
      const fileFieldName = Object.keys(row).find((key) => {
        return key.toLowerCase() === name.toLowerCase()
      })

      if (fileFieldName) {
        if (!Object.prototype.hasOwnProperty.call(typesValidations, type)) {
          throw Error(`Type ${type} not registered`)
        }
        const result = typesValidations[type](
          row[fileFieldName],
          country,
          length,
          domain
        )
        if (!result.isValid) {
          errors.push({
            rowIndex,
            errorMessage: `${name}: ${row[fileFieldName]} is not a valid ${type}`,
          })
          if (!result.isLengthValid) {
            errors.push({
              rowIndex,
              errorMessage: `String length exceeds maximum: ${length}`,
            })
          }
          if (!result.isDomainValid) {
            errors.push({
              rowIndex,
              errorMessage: `The column "${name}" only accepts values within the ${domain} domain. Please ensure that the value you enter is within this range.`,
            })
          }
        } else {
          rowConverted[name] = result.value
        }
      } else if (isRequired) {
        const substituteFieldsMessage = getMessageSubstituteFields(
          row,
          substituteFields
        )
        if (!substituteFields || substituteFieldsMessage) {
          errors.push({
            rowIndex,
            errorMessage: `${name}${substituteFieldsMessage} is required`,
          })
        }
      }
    }
  )
  return { rowConverted, errors }
}

const getMessageSubstituteFields = (row: any, substituteFields?: string[]) => {
  let missingFieldsMessage = ''
  substituteFields?.forEach((field, index) => {
    if (!row[field]) {
      if (missingFieldsMessage) {
        if (index === substituteFields.length - 1) {
          missingFieldsMessage += ` and ${field}`
        } else {
          missingFieldsMessage += `, ${field}`
        }
      } else {
        missingFieldsMessage += ` or ${field}`
      }
    }
  })

  return missingFieldsMessage
}

export const DataValidation = (
  data: any[],
  columnMetadata: ColumnMetadata[],
  country: string
) => {
  let errors: DataError[] = []
  const dataConverted: any[] = []
  try {
    data.forEach((row: any, index: number) => {
      const result = validateRow(row, columnMetadata, index + 1, country) // Adding + 1 to not count the header
      dataConverted.push(result.rowConverted)
      errors = errors.concat(result.errors)
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('An error occurred while validating the data', error)
  }

  return { dataConverted, errors }
}
