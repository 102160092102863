import React from 'react'
import { Box, Typography } from '@mui/material'
import { Colors, Icon } from 'everchain-uilibrary'

interface BCOTooltipProps {
  title: string
  bcoTypes: any[]
}

const BCOTooltip = ({ title, bcoTypes }: BCOTooltipProps) => {
  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box>
        {bcoTypes.map((item) => (
          // eslint-disable-next-line react/jsx-key
          <Box
            mb={4}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography>
                {item.icon ? (
                  item.icon === 'check' ? (
                    <Icon name="Check" style={{ color: Colors.success }} />
                  ) : item.icon === 'arrows-swap' ? (
                    <Icon name="SwapHoriz" style={{ color: 'gold' }} />
                  ) : item.icon === 'close' ? (
                    <Icon name="Close" style={{ color: 'red' }} />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}{' '}
              </Typography>
              <Typography variant="subtitle2" color="secondary">
                {item.label}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{item.description}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default BCOTooltip
