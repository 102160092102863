/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import {
  GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID,
  GET_PORTFOLIO_BUYERS,
} from 'src/graphql/operations/queries/postSale'
import { BusinessInfo, BusinessInfoResponse } from 'src/graphql/models/Business'
import {
  BulkUploadAccountsData,
  PortfolioNumberResponse,
} from 'src/graphql/models/PostSale'
import { ACCOUNT_DETAIL, COMPLIANCE_NEW } from 'src/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useFormik } from 'formik'
import { BulkUploadSection, Content, Header } from './styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getMyPortfoliosSellers } from 'src/data/features/get/portfolio/portfolio'
import { getBulkUploadAccounts } from 'src/data/features/get/account/account'
import { AccountRequest } from 'src/data/features/get/account/types'
import { DataTable, DataTableState } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'
// eslint-disable-next-line max-len

const ComplaintBulkUploadMain: React.FC = () => {
  const history = useHistory()
  const { sellerId } = useParams<any>()
  const { buyerId } = useParams<any>()
  const { sellerName } = useParams<any>()
  const { buyerName } = useParams<any>()
  const { pId } = useParams<any>()
  const [error, setError] = useState<any>()
  const { userPermissions, profileBusiness } = useContext(AuthContext)
  const [totalDataSize, setTotalDataSize] = useState<number>(5)

  const [initialValues] = useState<any>({})
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const [accountsRequest, setAccountsRequest] = useState<AccountRequest | null>(
    null
  )

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const { data: sellerList, isFetching: loadingSellerList } = useCustomQuery<
    BusinessInfo[]
  >(['getMyPortfoliosSellers'], async () => getMyPortfoliosSellers(), {
    enabled: true,
    cacheTime: 0,
  })

  const [getBuyers, { data: buyersList, loading: loadingBuyerList }] =
    useLazyQuery<BusinessInfoResponse>(GET_PORTFOLIO_BUYERS, {
      fetchPolicy: 'cache-and-network',
    })

  const [
    getPortfolios,
    { data: portfolioList, loading: loadingPortfolioList },
  ] = useLazyQuery<PortfolioNumberResponse>(
    GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID,
    { fetchPolicy: 'cache-and-network' }
  )

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.sellerSelected) {
      errors.sellerSelected = 'Required'
    }

    return errors
  }

  const formCollection = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      handleFilterClick()
      setSubmitting(false)
    },
  })
  const { data: accountsResponse, isFetching: loadingAccounts } =
    useCustomQuery<BulkUploadAccountsData>(
      ['getBulkUploadAccounts', accountsRequest],
      async () => getBulkUploadAccounts(accountsRequest),
      {
        cacheTime: 0,
        enabled: !!accountsRequest,
      }
    )

  const accountsData = accountsResponse?.accounts || []

  useEffect(() => {
    setTotalDataSize(accountsResponse?.totalOfDataResult ?? 5)
  }, [accountsResponse])

  useEffect(() => {
    if (isBuyer) {
      getBuyers({
        variables: {
          sellerId: '',
        },
      })
    }
  }, [])
  useEffect(() => {
    if (sellerId)
      handleSellerSelected({
        id: sellerId,
        name: sellerName,
        clientId: '',
        country: '',
        status: '',
        type: '',
      })
  }, [sellerId])

  useEffect(() => {
    if (buyerId)
      handleBuyerSelected({
        id: buyerId,
        name: buyerName,
        clientId: '',
        country: '',
        status: '',
        type: '',
      })
  }, [buyerId])

  useEffect(() => {
    if (pId) handlePidSelected(Number(pId))
  }, [pId])
  const GridColumns: any[] = [
    {
      title: 'Action',
      width: 100,
      show: true,
      render: (props: any) => {
        return (
          <td>
            <Button
              color="primary"
              variant="contained"
              onClick={
                () =>
                  history.push(
                    getStandardUri(
                      `${COMPLIANCE_NEW}/${
                        props.dataItem.portfolioRowGuid
                      }/${true}`
                    )
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              Create
            </Button>
          </td>
        )
      },
    },
    {
      field: 'rowIndex',
      title: 'Row Index',
      width: 150,
      show: false,
    },
    {
      field: 'lender',
      title: 'Lender',
      show: true,
      width: 150,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      show: true,
      width: 150,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      show: true,
      width: 150,
    },
    {
      field: 'firstName',
      title: 'First Name',
      show: true,
      width: 150,
    },
    {
      field: 'portfolioRowId',
      title: 'RowId',
      show: false,
      width: 100,
    },
    {
      field: 'portfolioRowGuid',
      title: 'ECAID',
      show: true,
      width: 180,
    },
  ]

  const handleSellerSelected = (business: BusinessInfo) => {
    formCollection.resetForm({
      values: { sellerSelected: business, buyerSelected: undefined },
      touched: formCollection.touched,
    })

    getBuyers({
      variables: {
        sellerId: business.id,
      },
    })
  }

  const handlePidSelected = (portfolioNumber: number) => {
    formCollection.setFieldValue('pidSelected', portfolioNumber)
  }

  const handleBuyerSelected = (business: BusinessInfo) => {
    const { sellerSelected } = formCollection.values
    formCollection.resetForm({
      values: { sellerSelected, buyerSelected: business },
      touched: formCollection.touched,
    })

    if (formCollection.values.sellerSelected) {
      getPortfolios({
        variables: {
          sellerId: formCollection.values.sellerSelected?.id ?? sellerId,
          buyerId: business.id,
        },
      })
    }
  }

  const handleFilterClick = () => {
    setAccountsRequest({
      portfolioNumber: formCollection.values.pidSelected,
      accountIds:
        formCollection.values.accountIds &&
        formCollection.values.accountIds.length > 0
          ? formCollection.values.accountIds.replace(/\s/g, '').split(',')
          : undefined,
      pagination: gridState,
      lastSSN: formCollection.values.lastSSN,
      buyerId: formCollection.values.buyerSelected?.id,
      buyerIds: undefined,
      sellerId: formCollection.values.sellerSelected?.id,
      sellerIds: undefined,
    })
  }

  const { profileClient } = useContext(AuthContext)
  const UserCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'

  useEffect(() => {
    if (buyersList?.businesses && buyersList?.businesses?.length > 0) {
      if (isBuyer) {
        const profile = buyersList.businesses.filter((x) =>
          profileBusiness.filter((y) => String(y.Id) === x.id)
        )
        if (profile.length > 0) handleBuyerSelected(profile[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyersList])

  useEffect(() => {
    if (sellerList && sellerList?.length > 0 && isSeller) {
      const profile = sellerList.filter((x) =>
        profileBusiness.filter((y) => String(y.Id) === x.id)
      )
      if (profile.length > 0) handleSellerSelected(profile[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerList])

  useEffect(() => {
    setError(formCollection.errors)
  }, [error, formCollection.errors])

  useEffect(() => {
    if (
      formCollection.values.sellerSelected ||
      formCollection.values.buyerSelected ||
      formCollection.values.pidSelected
    ) {
      formCollection.submitForm()
    }
  }, [
    formCollection.values.sellerSelected,
    formCollection.values.buyerSelected,
    formCollection.values.pidSelected,
  ])

  return (
    <>
      <form onSubmit={formCollection.handleSubmit}>
        <BulkUploadSection>
          <Header as={Content}>
            <Grid container direction="row">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h1" style={{ color: '#656565' }}>
                  Create a complaint
                </Typography>
              </Grid>
            </Grid>
          </Header>
          <Grid container direction="column" style={{ padding: '15px' }}>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <TextField
                  label="Seller"
                  select
                  id="select-seller"
                  error={!!error?.sellerSelected}
                  helperText={error?.sellerSelected}
                  value={formCollection.values.sellerSelected?.name ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem value={-1}>
                    <em>Select seller</em>
                  </MenuItem>
                  {sellerList?.map((business) => {
                    return (
                      <MenuItem
                        key={business.id}
                        value={business.name}
                        onClick={() => {
                          handleSellerSelected(business)
                        }}
                      >
                        {business.name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="Buyer"
                  select
                  id="select-buyer"
                  error={!!error?.buyerSelected}
                  helperText={error?.buyerSelected}
                  value={formCollection.values.buyerSelected?.name ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem value={-1}>
                    <em>Select buyer</em>
                  </MenuItem>
                  {buyersList?.businesses.map((business: BusinessInfo) => {
                    return (
                      <MenuItem
                        key={business.id}
                        value={business.name}
                        onClick={() => {
                          handleBuyerSelected(business)
                        }}
                      >
                        {business.name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="PID"
                  select
                  id="select-pid"
                  value={formCollection.values.pidSelected ?? ''}
                  fullWidth
                  style={{ minWidth: '100px' }}
                  disabled={
                    formCollection.values.buyerSelected === undefined ||
                    formCollection.values.sellerSelected === undefined
                  }
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('accountIds', undefined)
                    }}
                  >
                    <em>Select PID</em>
                  </MenuItem>
                  {portfolioList?.portfoliosNumber.map(
                    (portfolioNumber: number) => {
                      return (
                        <MenuItem
                          key={portfolioNumber}
                          value={portfolioNumber}
                          onClick={() => {
                            handlePidSelected(portfolioNumber)
                          }}
                        >
                          {portfolioNumber}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              {UserCountry() !== 'UK' && (
                <>
                  <Grid item>
                    <TextField
                      label="Last 4 SSN"
                      id="select-SSN"
                      value={formCollection.values.lastSSN ?? undefined}
                      fullWidth
                      style={{ minWidth: '100px' }}
                      disabled={
                        formCollection.values.sellerSelected === undefined
                      }
                      onChange={(e) => {
                        const numbers = /^(\d+)$/
                        if (
                          e.target.value.match(numbers) ||
                          e.target.value === ''
                        ) {
                          formCollection.setFieldValue(
                            'lastSSN',
                            e.target.value
                          )
                        }
                      }}
                      inputProps={{
                        maxLength: 4,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                </>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  label="Account IDs"
                  id="select-LoanIds"
                  value={formCollection.values.accountIds ?? undefined}
                  fullWidth
                  style={{ minWidth: '250px' }}
                  disabled={formCollection.values.sellerSelected === undefined}
                  onChange={(e) => {
                    formCollection.setFieldValue('accountIds', e.target.value)
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  data-cy="filter-button"
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: '5px' }}>
              {(loadingSellerList ||
                loadingBuyerList ||
                loadingPortfolioList ||
                loadingAccounts) &&
                loadingPanel}
              <DataTable
                style={{
                  width: '100%',
                }}
                data={formCollection.values.sellerSelected ? accountsData : []}
                sortable
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={totalDataSize}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)

                  setAccountsRequest({
                    portfolioNumber: formCollection.values.pidSelected,
                    accountIds:
                      formCollection.values.accountIds &&
                      formCollection.values.accountIds.length > 0
                        ? formCollection.values.accountIds
                            .replace(/\s/g, '')
                            .split(',')
                        : undefined,
                    pagination: e.dataState,
                    lastSSN: formCollection.values.lastSSN,
                    buyerId: formCollection.values.buyerSelected?.id,
                    buyerIds: undefined,
                    sellerId: formCollection.values.sellerSelected?.id,
                    sellerIds: undefined,
                  })
                }}
                gridColumns={GridColumns}
              />
            </Grid>
          </Grid>
        </BulkUploadSection>
      </form>
    </>
  )
}

export default ComplaintBulkUploadMain
