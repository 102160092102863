import { Box, Button, Skeleton } from '@mui/material'
import { Colors, Icon } from 'everchain-uilibrary'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { numberCurrencyDollar, numberToPercentage } from 'src/utils/numbers'

interface ExportTableProps {
  portfolioNumber: string
  csvData: any
  fileName: string
  disabled: boolean
  portfolioCountry?: string | undefined
}

const ExportTable: React.FC<ExportTableProps> = ({
  portfolioNumber,
  csvData,
  fileName,
  disabled,
  portfolioCountry,
}: ExportTableProps) => {
  const [data, setData] = useState([])
  const [color, setColor] = useState(Colors.primary)

  const getHeaderColumn = () => {
    if (fileName) {
      if (fileName === 'Balance Distribution') {
        return 'Balance'
      }
      if (fileName === 'Top 10 State') return 'State'

      return 'Months'
    }
    return ''
  }

  const [firstColumnName] = useState(getHeaderColumn())

  const renameKey = (obj: any[string]) => {
    const newData = obj?.map((x: any) => {
      if (fileName === 'First Delinquency Date') {
        const d = {
          [firstColumnName]: x.label,
          'Aggregate Total Balance': numberCurrencyDollar(
            x.totalBalance,
            portfolioCountry
          ),
          '% Total': numberToPercentage(x.total),
          '% Running': numberToPercentage(x.running),
          'Number Of Accounts': x.numberOfAccount,
          'Avg. Balance': numberCurrencyDollar(
            x.averageBalance,
            portfolioCountry
          ),
          TOB: x.avgTOB,
          'Since Last Pay Date': x.avgLPDt,
          '% TOB': numberToPercentage(x.percentTOB),
          '% Last Pay Date': numberToPercentage(x.percentLPDt),
          '% 1st DQ Date': numberToPercentage(x.percentDQDt),
        }

        return d
      }

      const d = {
        [firstColumnName]: x.label,
        'Aggregate Total Balance': numberCurrencyDollar(
          x.totalBalance,
          portfolioCountry
        ),
        '% Total': numberToPercentage(x.total),
        '% Running': numberToPercentage(x.running),
        'Number Of Accounts': x.numberOfAccount,
        'Avg. Balance': numberCurrencyDollar(
          x.averageBalance,
          portfolioCountry
        ),
        TOB: x.avgTOB,
        'Since Last Pay Date': x.avgLPDt,
        '% TOB': numberToPercentage(x.percentTOB),
        '% Last Pay Date': numberToPercentage(x.percentLPDt),
        '% C/O Date': numberToPercentage(x.percentCODt),
      }

      return d
    })

    return newData
  }

  useEffect(() => {
    const newData = renameKey(csvData)
    setData(newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData])

  useEffect(() => {
    if (disabled) setColor('#bdbdbd')
    else setColor(Colors.primary)
  }, [disabled])

  if (!data)
    return (
      <Box mb={4}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  return (
    <Button
      startIcon={<Icon name="GetApp" />}
      color="secondary"
      disabled={disabled}
    >
      <CSVLink
        data={data}
        filename={`${portfolioNumber}-${fileName}`}
        style={{ textDecoration: 'none', color }}
      >
        Export To Excel
      </CSVLink>
    </Button>
  )
}

ExportTable.defaultProps = {
  portfolioCountry: undefined,
}
export default ExportTable
