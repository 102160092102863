import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Typography, Paper, Skeleton } from '@mui/material'
import { Link, Redirect, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import {
  ForwardFlowAgreementDetail,
  ForwardFlowAgreementPortfolios,
  ForwardFlowEventTypeHistory,
} from 'src/graphql/models/ForwardFlows'
import {
  GET_FORWARD_FLOW_AGREMENT_DETAIL,
  GET_FORWARD_FLOW_AGREMENT_HISTORY,
  GET_FORWARD_FLOW_AGREMENT_PORTFOLIOS,
} from 'src/graphql/operations/queries/portfolio'
import {
  FORWARD_FLOW_CLONE,
  FORWARD_FLOW_INFO,
  FORWARD_FLOW_LIST,
  PORTFOLIO_DETAIL,
} from 'src/routes'
import { numberCurrencyDollar, numberToPercentage } from 'src/utils/numbers'

import Header from 'src/components/Header'
import { Content } from 'src/styles/layout'
import { formatDate, getLocalDatetimer } from 'src/utils/date'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  ForwardFlowsCardUrl,
  INTERNAL,
  PermissionCodeAccess,
} from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  renderCapitalize,
  renderClickableNumberLink,
  renderCurrency,
  renderDate,
} from 'src/utils/formatKendoColumns'
import {
  AggregatesContent,
  AggregatesList,
  AggregatesListItem,
  AggregatesListItemText,
  BoxGrid,
} from './styles'
import ForwardFlowExpiringForm from './components/ForwardFlowExpiringForm'
import { DataTable, DataTableState } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

function ForwardFlowDetail() {
  const { profileClient } = useContext(AuthContext)
  const [totalOfForwardFlowsEvents, setTotalOfForwardFlowsEvents] = useState(0)
  const [totalOfForwardFlowsPortfolios, setTotalOfForwardFlowsPortfolios] =
    useState(0)
  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }
  const [gridStatePortfolio, setGridStatePortfolio] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const [gridStateEvents, setGridStateEvents] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const overflowFormat = (name: string, value: any) => {
    if (name === 'startDate' || name === 'endDate') {
      return value ? formatDate(new Date(value), '', userCountry()) : 'N/A'
    }
    if (name === 'maxFaceValue') {
      return numberCurrencyDollar(value, userCountry()) || 'N/A'
    }

    if (name === 'purchasePricePercent') {
      return numberToPercentage(value)
    }
    return value || 'N/A'
  }

  const portfolioColumns: any[] = [
    {
      title: 'Portfolio Number',
      field: 'portfolioNumber',
      render: (props: any) =>
        renderClickableNumberLink(
          props,
          getStandardUri(`${PORTFOLIO_DETAIL}/${props.dataItem['id']}`),
          null,
          'left'
        ),
      show: true,
    },
    {
      title: 'Status',
      field: 'status',
      render: (props: any) => renderCapitalize(props),
      show: true,
    },
    {
      title: 'Purchase Price',
      field: 'purchasePriceAmount',
      render: (props: any) => renderCurrency(props, null, userCountry()),
      show: true,
    },
    {
      title: 'Sale Date',
      field: 'soldUtc',
      render: (props: any) => renderDate(props),
      show: true,
    },
  ]

  const forwardFlowsColumns: any[] = [
    {
      title: 'Portfolio Number',
      field: 'portfolioNumber',
      render: (props: any) =>
        props.dataItem[props.field] === 0 ? (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <td {...props} style={{ textAlign: 'left' }} />
        ) : (
          renderClickableNumberLink(
            props,
            getStandardUri(`${PORTFOLIO_DETAIL}/${props.dataItem['id']}`),
            null,
            'left'
          )
        ),
      show: true,
      width: 350,
    },
    {
      title: 'Event Date',
      field: 'eventUtc',
      render: (props: any) => renderDate(props),
      show: true,
      width: 350,
    },
    {
      title: 'Event Type',
      field: 'eventType',
      render: (props: any) => renderCapitalize(props),
      show: true,
    },
    {
      title: 'More Information',
      field: 'otherData',
      show: true,
    },
  ]

  const [overflowData, setOverflowData] = useState<any>([])

  const { forwardFlowId } = useParams<any>()

  const {
    data: forwardFlowAgreementData,
    loading: loadingForwardFlowAgreement,
    error: forwardFlowError,
  } = useQuery<ForwardFlowAgreementDetail>(GET_FORWARD_FLOW_AGREMENT_DETAIL, {
    variables: {
      forwardFlowId: Number(forwardFlowId),
    },
    skip: !forwardFlowId,
  })

  const {
    data: forwardFlowAgreementHistoryData,
    loading: loadingForwardFlowHistory,
    fetchMore: fetchMoreHistory,
  } = useQuery<ForwardFlowEventTypeHistory>(GET_FORWARD_FLOW_AGREMENT_HISTORY, {
    variables: {
      forwardFlowId: Number(forwardFlowId),
      kendoPagination: JSON.stringify(gridStateEvents),
    },
    skip: !forwardFlowId,
  })

  const {
    data: forwardFlowAgreementPortfolioData,
    loading: loadingForwardFlowPortfolios,
    fetchMore: fetchMorePortfolios,
  } = useQuery<ForwardFlowAgreementPortfolios>(
    GET_FORWARD_FLOW_AGREMENT_PORTFOLIOS,
    {
      variables: {
        forwardFlowId: Number(forwardFlowId),
        kendoPagination: JSON.stringify(gridStatePortfolio),
      },
      skip: !forwardFlowId,
    }
  )

  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if (forwardFlowError && forwardFlowError.message.includes('not found')) {
      enqueueSnackbar('Forward Flow not found.', notifyError)
    } else if (forwardFlowError && forwardFlowError.message) {
      enqueueSnackbar('Error during loading Forward Flow.', notifyError)
    }
  }, [enqueueSnackbar, forwardFlowError, notifyError])

  const { userPermissions } = useContext(AuthContext)
  const ability = useContext(AbilityContext)
  const [dateDiff, setDateDiff] = useState<any>(null)
  const [showExpiringSection, setShowExpiringSection] = useState(false)

  useEffect(() => {
    if (
      !loadingForwardFlowAgreement &&
      forwardFlowAgreementData?.getForwardFlowDetailData
    ) {
      if (
        !loadingForwardFlowAgreement &&
        forwardFlowAgreementData?.getForwardFlowDetailData
      ) {
        const diff = getLocalDatetimer(
          forwardFlowAgreementData?.getForwardFlowDetailData.endDate
        )
        setDateDiff(diff)
      }

      setOverflowData(forwardFlowAgreementData?.getForwardFlowDetailData)
    }
  }, [loadingForwardFlowAgreement, forwardFlowAgreementData])

  useEffect(() => {
    if (
      !loadingForwardFlowAgreement &&
      forwardFlowAgreementData?.getForwardFlowDetailData
    ) {
      const currencyDate = new Date()

      if (
        currencyDate <=
          new Date(
            forwardFlowAgreementData?.getForwardFlowDetailData.endDate
          ) &&
        forwardFlowAgreementData?.getForwardFlowDetailData
          .sellerTerminationNotificationDays >= dateDiff?.days
      ) {
        setShowExpiringSection(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateDiff])

  useEffect(() => {
    setTotalOfForwardFlowsEvents(
      forwardFlowAgreementHistoryData?.getForwardFlowAgreementHistoryData
        ?.totalEvents || 0
    )
  }, [forwardFlowAgreementHistoryData])

  useEffect(() => {
    setTotalOfForwardFlowsPortfolios(
      forwardFlowAgreementPortfolioData?.getForwardFlowAgreementPortfoliosData
        ?.totalPortfolios || 0
    )
  }, [forwardFlowAgreementPortfolioData])

  if (forwardFlowError && forwardFlowError.message) {
    return (
      <Redirect
        to={getStandardUri(
          `${FORWARD_FLOW_LIST}/${ForwardFlowsCardUrl.ACTIVE_FLOWS}`
        )}
      />
    )
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  return (
    <>
      {(loadingForwardFlowAgreement ||
        loadingForwardFlowHistory ||
        loadingForwardFlowPortfolios) &&
        loadingPanel}
      <div>
        <Content>
          <Header
            title="Forward Flow"
            subtitle={
              <Typography component="div">
                <Typography component="strong" variant="subtitle2">
                  ID:
                </Typography>
                {'  '}
                {`${
                  forwardFlowAgreementData?.getForwardFlowDetailData.id || 'N/A'
                } `}
                <Typography component="strong" variant="subtitle2">
                  Seller:
                </Typography>
                {'  '}
                {`${
                  forwardFlowAgreementData?.getForwardFlowDetailData
                    .sellerName || 'N/A'
                } `}
                <Typography component="strong" variant="subtitle2">
                  Buyer:
                </Typography>{' '}
                {`${
                  forwardFlowAgreementData?.getForwardFlowDetailData
                    .buyerName || 'N/A'
                } `}
              </Typography>
            }
            actions={
              userPermissions.type === INTERNAL &&
              ability.can(
                PermissionCodeAccess.MarketPlace_ForwardFlow,
                'any'
              ) ? (
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" mr={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component={Link}
                      to={getStandardUri(
                        `${FORWARD_FLOW_INFO}/${forwardFlowId}`
                      )}
                    >
                      Edit
                    </Button>
                  </Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    component={Link}
                    size="large"
                    to={{
                      pathname: getStandardUri(
                        `${FORWARD_FLOW_CLONE}/${forwardFlowId}`
                      ),
                      state: {
                        isClone: true,
                      },
                    }}
                  >
                    Clone
                  </Button>
                </Box>
              ) : (
                ''
              )
            }
          />
        </Content>
        <Content>
          <Paper>
            <Box p={3}>
              <Typography>Overview</Typography>
              <BoxGrid mt={2}>
                {loadingForwardFlowAgreement &&
                  !overflowData &&
                  [0, 1, 2].map((item) => (
                    <Box p={2} key={item} textAlign="center">
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={50}
                      />
                    </Box>
                  ))}
                {!loadingForwardFlowAgreement && !!overflowData && (
                  <Paper elevation={1}>
                    <AggregatesContent>
                      <AggregatesList>
                        <AggregatesListItem key="startDate">
                          <AggregatesListItemText
                            data-cy="start-date"
                            h1
                            primary
                            uppercase
                            background="#f3fafe"
                          >
                            Start Date
                          </AggregatesListItemText>
                          <AggregatesListItemText data-cy="startDateValue" h1>
                            {overflowFormat(
                              'startDate',
                              overflowData['startDate']
                            )}
                          </AggregatesListItemText>
                        </AggregatesListItem>
                        <AggregatesListItem key="endDate">
                          <AggregatesListItemText
                            data-cy="endDate"
                            h1
                            primary
                            uppercase
                            background="#f3fafe"
                          >
                            End Date
                          </AggregatesListItemText>
                          <AggregatesListItemText data-cy="endDateValue" h1>
                            {overflowFormat('endDate', overflowData['endDate'])}
                          </AggregatesListItemText>
                        </AggregatesListItem>
                        <AggregatesListItem key="maxFace">
                          <AggregatesListItemText
                            data-cy="maxFace"
                            h1
                            primary
                            uppercase
                            background="#f3fafe"
                          >
                            Max Face Value
                          </AggregatesListItemText>
                          <AggregatesListItemText data-cy="maxFaceValue" h1>
                            {overflowFormat(
                              'maxFaceValue',
                              overflowData['maxFaceValue']
                            )}
                          </AggregatesListItemText>
                        </AggregatesListItem>
                        <AggregatesListItem key="purchasePricePercent">
                          <AggregatesListItemText
                            data-cy="purchasePricePercent"
                            h1
                            primary
                            uppercase
                            background="#f3fafe"
                          >
                            Purchase Rate
                          </AggregatesListItemText>
                          <AggregatesListItemText
                            data-cy="purchasePricePercentValue"
                            h1
                          >
                            {overflowFormat(
                              'purchasePricePercent',
                              overflowData['purchasePricePercent']
                            )}
                          </AggregatesListItemText>
                        </AggregatesListItem>
                        <AggregatesListItem key="buyerTerminationNotificationDays">
                          <AggregatesListItemText
                            data-cy="buyerTerminationNotificationDays"
                            h1
                            primary
                            uppercase
                            background="#f3fafe"
                          >
                            Buyer Termination Days
                          </AggregatesListItemText>
                          <AggregatesListItemText
                            data-cy="buyerTerminationNotificationDaysValue"
                            h1
                          >
                            {overflowFormat(
                              'buyerTerminationNotificationDays',
                              overflowData['buyerTerminationNotificationDays']
                            )}
                          </AggregatesListItemText>
                        </AggregatesListItem>
                        <AggregatesListItem key="sellerUploadDate">
                          <AggregatesListItemText
                            data-cy="sellerUploadDate"
                            h1
                            primary
                            uppercase
                            background="#f3fafe"
                          >
                            Seller Upload Date
                          </AggregatesListItemText>
                          <AggregatesListItemText
                            data-cy="sellerUploadDateValue"
                            h1
                          >
                            {overflowFormat(
                              'sellerUploadDate',
                              overflowData['sellerUploadDate']
                            )}
                          </AggregatesListItemText>
                        </AggregatesListItem>
                        <AggregatesListItem key="buyerFundingTerms">
                          <AggregatesListItemText
                            data-cy="buyerFundingTerms"
                            h1
                            primary
                            uppercase
                            background="#f3fafe"
                          >
                            Buyer Funding Terms
                          </AggregatesListItemText>
                          <AggregatesListItemText
                            data-cy="buyerFundingTermsValue"
                            h1
                          >
                            {overflowFormat(
                              'buyerFundingTerms',
                              overflowData['buyerFundingTerms']
                            )}
                          </AggregatesListItemText>
                        </AggregatesListItem>
                      </AggregatesList>
                    </AggregatesContent>
                  </Paper>
                )}
              </BoxGrid>
            </Box>
          </Paper>
        </Content>
        {showExpiringSection && (
          <Content>
            <Paper>
              <Box p={3}>
                <Typography>Expiring Forward Flow</Typography>
              </Box>
              <ForwardFlowExpiringForm
                forwardFlowDetail={forwardFlowAgreementData || undefined}
                daysToExpire={dateDiff?.days}
              />
            </Paper>
          </Content>
        )}
        <Content>
          <Paper>
            <Box p={3}>
              <Typography>Portfolios</Typography>
            </Box>
            <DataTable
              style={{
                width: '100%',
              }}
              data={
                loadingForwardFlowPortfolios
                  ? []
                  : forwardFlowAgreementPortfolioData
                      ?.getForwardFlowAgreementPortfoliosData?.portfolios
              }
              sortable
              useFilterMenu
              skip={gridStatePortfolio.skip}
              take={gridStatePortfolio.take}
              pageSize={gridStatePortfolio.take}
              filter={gridStatePortfolio.filter}
              sort={gridStatePortfolio.sort}
              pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
              total={totalOfForwardFlowsPortfolios || 0}
              onDataStateChange={(e) => {
                setGridStatePortfolio(e.dataState)
                if (fetchMorePortfolios) {
                  fetchMorePortfolios({
                    variables: {
                      kendoPagination: JSON.stringify(gridStatePortfolio),
                    },
                  })
                }
              }}
              gridColumns={portfolioColumns}
            />
          </Paper>
        </Content>
        <Content>
          <Paper>
            <Box p={3}>
              <Typography>Forward Flow History</Typography>
            </Box>
            <DataTable
              data={
                loadingForwardFlowHistory
                  ? []
                  : forwardFlowAgreementHistoryData
                      ?.getForwardFlowAgreementHistoryData?.history
              }
              sortable
              useFilterMenu
              skip={gridStateEvents.skip}
              take={gridStateEvents.take}
              pageSize={gridStateEvents.take}
              filter={gridStateEvents.filter}
              sort={gridStateEvents.sort}
              pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
              total={totalOfForwardFlowsEvents || 0}
              onDataStateChange={(e) => {
                setGridStateEvents(e.dataState)
                if (fetchMoreHistory) {
                  fetchMoreHistory({
                    variables: {
                      kendoPagination: JSON.stringify(gridStateEvents),
                    },
                  })
                }
              }}
              gridColumns={forwardFlowsColumns}
            />
          </Paper>
        </Content>
      </div>
    </>
  )
}

export default ForwardFlowDetail
