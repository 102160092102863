import { gql } from '@apollo/client'

export const GET_REQUEST_EFFORT_EXAUSTED_COMMENTS = gql`
  query GetRequestComments($requestId: Long!) {
    data: getPostSaleRequestById(requestId: $requestId) {
      id
      effortExausted
      effortExaustedComments {
        id
        requestId
        eventType
        createdByBusinessId
        createdByBusinessName
        createdByBusinessType
        text
        created
        createdByUserName
      }
    }
  }
`

export const GET_REQUEST_FILTERS = gql`
  query GetRequestFilters($userType: UserType!) {
    requestFiltersOptions: getRequestFilterOptions(userType: $userType) {
      id: filterName
      displayName: filterName
      items: values {
        id
        displayName: value
        value
      }
    }
  }
`
export const GET_MY_BUYERS_AND_SELLERS = gql`
  query GetMyBuyersAndSellers {
    businesses: getMyBuyersAndSellers {
      id
      country
      clientId
      name
      status
      type
    }
  }
`
export const GET_PORTFOLIO_BUYERS_BY_BUYER_ID = gql`
  query GetPortfolioBuyersByBuyerId {
    businesses: getPortfolioBuyersByBuyerId {
      id
      country
      clientId
      name
      status
      type
    }
  }
`
export const GET_PORTFOLIO_BUYERS = gql`
  query GetPortfolioBuyers($sellerId: String) {
    businesses: getPortfolioBuyers(sellerId: $sellerId) {
      id
      country
      clientId
      name
      status
      type
    }
  }
`

export const GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID = gql`
  query GetPortfoliosByBuyerAndSellerId($sellerId: String, $buyerId: String) {
    portfoliosNumber: getPortfoliosByBuyerAndSellerId(
      sellerId: $sellerId
      buyerId: $buyerId
    )
  }
`

export const GET_BULK_RESPONSE_ACCOUNTS = gql`
  query GetBulkResponseAccounts(
    $requestTypeId: Int!
    $kendoPagination: String
    $sellerId: String
    $buyerId: String
    $pId: Long
    $last4SSN: String
    $batchAttachment: Boolean
    $statuses: [String]
    $accountIds: String
  ) {
    requests: getBulkResponseAccounts(
      requestTypeId: $requestTypeId
      kendoPagination: $kendoPagination
      sellerId: $sellerId
      buyerId: $buyerId
      pId: $pId
      last4SSN: $last4SSN
      batchAttachment: $batchAttachment
      statuses: $statuses
      accountIds: $accountIds
    ) {
      total
      requestDetailsResponse {
        id
        debtAccountId
        portfolioNumber
        portfolioId
        lender
        lenderLoanId
        lastName
        firstName
        caseNumber
        chapter
        fileDate
        attorneyName
        attorneyPhone
        district
        dateOfDeath
        lastPaymentDate
        lastPaymentAmount
        checkOrReferenceNumber
        dismissedDischargedType
        dateOfDismissalDischarge
        explanation
        inaccurateDataInfo
        infoRequested
        paidPriorDate
        paidPriorType
        pifSifType
        paymentDate
        paymentAmount
        paidToDate
        locationOrStore
        consumerAddress
        consumerCity
        consumerState
        consumerPostalCode
        consumerPhoneNumber
        militaryBranch
        activeDutyStartDate
        activeDutyEndDate
        status
        lastUpdated
        numberOfFiles
        lastUploaded
        reason
        closedDate
        portfolioRowGuid
        comments
      }
    }
  }
`

export const GET_REPORT_DATA_BANKRUPT = gql`
  query GetReportDataBankrupt(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataBankrupt(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        attorneyName
        attorneyPhone
        caseNumber
        chapter
        district
        fileDate
        insolvencyType
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_REPORT_DATA_DECEASED = gql`
  query GetReportDataDeceased(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataDeceased(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        dateOfDeath
        notificationSource
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_REPORT_DATA_PAIDPRIOR = gql`
  query GetReportDataPaidPrior(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataPaidPrior(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        paidPriorDate
        paidPriorType
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_REPORT_DATA_PIFSIF = gql`
  query GetReportDataPifSif(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataPifSif(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        pifSifType
        paymentDate
        paymentAmount
        checkOrReferenceNumber
        paidToDate
        locationOrStore
        consumerAddress
        consumerCity
        consumerState
        consumerPostalCode
        consumerPhoneNumber
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_REPORT_DATA_LIENLOSS = gql`
  query GetReportDataLienLoss(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataLienLoss(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        explanation
        lienLossDate
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_REPORT_DATA_FRAUD = gql`
  query GetReportDataFraud(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataFraud(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        explanation
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_REPORT_DATA_INACCURATEDATA = gql`
  query GetReportDataInaccurateData(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataInaccurateData(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        inaccurateDataInfo
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_REPORT_DATA_SCRAMILITARY = gql`
  query GetReportDataScraMilitary(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataScraMilitary(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        militaryBranch
        activeDutyStartDate
        activeDutyEndDate
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_REPORT_DATA_DISMISSEDDISCHARGED = gql`
  query GetReportDataDismissedDischarged(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportDataDismissedDischarged(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        dismissedDischargedType
        dateOfDismissalDischarge
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_APPROVED_AGENCIES = gql`
  query GetApprovedAgencies($clientId: Long!) {
    getApprovedAgencies(clientId: $clientId) {
      approvedAgencyBusinessId
      id
      name
    }
  }
`

export const GET_REPORT_DATA = gql`
  query GetReportData(
    $options: PostSaleReportSearchOptions
    $kendoPagination: String
  ) {
    reportData: getReportData(
      options: $options
      kendoPagination: $kendoPagination
    ) {
      total
      postSaleReportData {
        buyerId
        buyerName
        creationDate
        fundingId
        fundingAmount
        lenderLoanId
        portfolioNumber
        portfolioCountry
        requestId
        requestType
        sellerId
        sellerName
        status
        firstName
        lastName
        rejectComment
        portfolioRowGuid
      }
    }
  }
`

export const GET_PORTFOLIO_SELLERS_BY_BUYER_ID = gql`
  query GetPortfolioSellersByBuyerId($buyerId: String!) {
    businesses: getPortfolioSellersByBuyerId(buyerId: $buyerId) {
      id
      country
      clientId
      name
      status
      type
    }
  }
`

export const GET_BULK_WITHDRAW_ACCOUNTS = gql`
  query GetBulkWithdrawAccounts(
    $requestTypeId: Int!
    $kendoPagination: String
    $sellerId: String
    $buyerId: String
    $pId: Long
    $last4SSN: String
    $accountIds: String
  ) {
    requests: getBulkWithdrawAccounts(
      requestTypeId: $requestTypeId
      kendoPagination: $kendoPagination
      sellerId: $sellerId
      buyerId: $buyerId
      pId: $pId
      last4SSN: $last4SSN
      accountIds: $accountIds
    ) {
      total
      requestDetailsResponse {
        id
        debtAccountId
        portfolioNumber
        portfolioId
        lender
        lenderLoanId
        lastName
        firstName
        caseNumber
        chapter
        fileDate
        attorneyName
        attorneyPhone
        district
        dateOfDeath
        lastPaymentDate
        lastPaymentAmount
        checkOrReferenceNumber
        dismissedDischargedType
        dateOfDismissalDischarge
        explanation
        inaccurateDataInfo
        infoRequested
        paidPriorDate
        paidPriorType
        pifSifType
        paymentDate
        paymentAmount
        paidToDate
        locationOrStore
        consumerAddress
        consumerCity
        consumerState
        consumerPostalCode
        consumerPhoneNumber
        militaryBranch
        activeDutyStartDate
        activeDutyEndDate
        status
        lastUpdated
        numberOfFiles
        lastUploaded
        reason
        closedDate
        portfolioRowGuid
      }
    }
  }
`
