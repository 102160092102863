import {
  BACKEND_URL,
  CONTROLPANEL_URL,
} from 'src/infra/api/axios-wrapper/httpClient'

const surveyPrefix = 'survey.'
const clientPrefix = 'client.'

export const SurveyGetSurveyDetail = `${BACKEND_URL}/${surveyPrefix}getSurveyDetail`
export const SurveyGetSurveyById = `${BACKEND_URL}/${surveyPrefix}getSurveyById`
export const SurveyGetSurveyAnswers = `${BACKEND_URL}/${surveyPrefix}getSurveyAnswers`
export const SurveyGetSurveySchema = `${BACKEND_URL}/${surveyPrefix}getSurveySchema`
export const SurveyUploadSurveySchema = `${BACKEND_URL}/${surveyPrefix}uploadSurveySchema`
export const SurveyGetSurveyBusiness = `${BACKEND_URL}/${surveyPrefix}GetSurveyBusiness`
export const SurveyGetSurvey = `${BACKEND_URL}/${surveyPrefix}getSurvey`
export const CanEditSurvey = `${BACKEND_URL}/${surveyPrefix}canEditSurvey`
export const SurveyGetSurveyAssetTypes = `${BACKEND_URL}/${surveyPrefix}getSurveyAssetTypes`
export const SurveyGetExternalSurvey = `${BACKEND_URL}/${surveyPrefix}getExternalSurvey`
export const SurveyCanApproveSurvey = `${BACKEND_URL}/${surveyPrefix}canApproveSurvey`
export const SurveyGetDigitalSellerSurveysAvailable = `${BACKEND_URL}/${surveyPrefix}getDigitalSellerSurveysAvailable`
export const SurveyGetDigitalSellerSurveyLink = `${BACKEND_URL}/${surveyPrefix}getDigitalSellerSurveyLink`
export const GetAllPortfolioTemplateWithDigitalSurvey = `${BACKEND_URL}/${surveyPrefix}GetAllPortfolioTemplateWithDigitalSurvey`
export const ClientGetDSSBusiness = `${CONTROLPANEL_URL}/${clientPrefix}getDSSBusiness`
export const SurveyGetSurveyAttachmentUrl = `${BACKEND_URL}/${surveyPrefix}getSurveyAttachment?fileUrl=`
