import React, { useContext, useEffect, useState } from 'react'
import { Content } from 'src/styles/layout'
import { Typography } from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { USER_ID } from 'src/configs/AuthService'
import { PostSaleHeader } from './styles'

interface HeaderProps {
  onOpenFilter: () => void
}

const Header = ({ onOpenFilter }: HeaderProps) => {
  const { user } = useContext(AuthContext)

  const [hasFilter, setHasFilter] = useState<boolean>(
    user &&
      !!window.localStorage.getItem(
        `postsale_accounts_filter_${user.profile[USER_ID]}`
      )
  )

  useEffect(() => {
    window.addEventListener(
      'postsale_filter_created',
      () => {
        setHasFilter(true)
      },
      false
    )

    window.addEventListener(
      'postsale_filter_reseted',
      () => {
        setHasFilter(false)
      },
      false
    )
  }, [])

  return (
    <PostSaleHeader as={Content}>
      <Typography variant="h1" color="secondary">
        Manage
      </Typography>
      {/*
      11/22/2023 - This was hidden as requested by Michel.

      <HeaderActions>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FilterList />}
          endIcon={
            hasFilter ? (
              <Tooltip title="A filter is being applied">
                <Warning style={{ color: 'orange' }} />
              </Tooltip>
            ) : null
          }
          onClick={onOpenFilter}
        >
          Filters
        </Button>
      </HeaderActions>
      */}
    </PostSaleHeader>
  )
}

export default Header
