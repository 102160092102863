import { Box, Typography, Paper } from '@mui/material'
import { DataTable, renderCurrency, renderDate } from 'everchain-uilibrary'
import React from 'react'
import { ForwardFlowPortfolio } from 'src/graphql/models/ForwardFlows'
import { PORTFOLIO_DETAIL } from 'src/routes'
import { Content } from 'src/styles/layout'
import { getStandardUri } from 'src/utils/common'
import { renderClickableNumberLink } from 'src/utils/formatKendoColumns'

interface TransferListProps {
  forwardFlowPortfolio: ForwardFlowPortfolio[] | undefined
  loading: boolean
}

const portfolioColumns = [
  {
    field: 'portfolioNumber',
    title: 'Portfolio Number',
    show: true,
    render: (props: any) =>
      renderClickableNumberLink(
        props,
        getStandardUri(`${PORTFOLIO_DETAIL}/${props.dataItem['id']}`),
        null,
        'left'
      ),
  },
  {
    field: 'status',
    title: 'Status',
    show: true,
  },
  {
    field: 'purchasePriceAmount',
    title: 'Purchase Price',
    show: true,
    render: renderCurrency,
  },
  {
    field: 'uploadDateUtc',
    title: 'Upload Date',
    show: true,
    render: renderDate,
  },
]

const PortfolioList: React.FC<TransferListProps> = ({
  forwardFlowPortfolio,
  loading,
}) => {
  return (
    <Content>
      <Paper>
        <Box p={3}>
          <Typography>Portfolios</Typography>
        </Box>
        <DataTable
          isLoading={loading}
          data={forwardFlowPortfolio}
          gridColumns={portfolioColumns}
        />
      </Paper>
    </Content>
  )
}

export default PortfolioList
